//import * as reducer from '../reducer';
import reducer from '../reducer';

const projectsReducer = (
  state = {
    projects: [],
    schoolProjects: [],
    courseProjects: [],
    projectRows: [],
    viewingProject: {},
    teachingProjects: [],
    joinedProjects: [],
    deleteIconAction: false,
    deletedIcons: [],
    editLang: {},
    tags: [],
    tagStatus: 'default',
    projectToCopy: null
  }, action)=>{
  switch (action.type) {
    case 'updateProjects':
      return {...state, projects: reducer.updateElements(state.projects, action.payload)};
    case 'updateSchoolProjects':
      return {...state, schoolProjects: reducer.updateElements(state.schoolProjects, action.payload)};
    case 'updateCourseProjects':
      return {...state, courseProjects: action.payload};
    case 'updateJoinedProjects':
        return {...state, joinedProjects: reducer.updateElements(state.joinedProjects, action.payload, true)};
    case 'updateTeachingProjects':
        return {...state, teachingProjects: reducer.updateElements(state.teachingProjects, action.payload, true)};
    case 'viewProject':
      if(!action.payload){ return state; }
      return {...state, viewingProject: action.payload};
    case 'hideProject':
      state.viewingProject.hide = action.payload;
      return {...state, viewingProject: state.viewingProject};
    case 'deleteProjectIconAction':
      return {...state, deleteIconAction: action.payload};
    case 'pushProjectDeletedIcon':
      return {...state, deletedIcons: [...state.deletedIcons, action.payload]};
    case 'removeProjectDeletedIcon':
      const newDeletedIcons = state.deletedIcons;
      const index = newDeletedIcons.indexOf(action.payload);
      if(index > -1){
        newDeletedIcons.splice(index, 1);
        return {...state, deletedIcons: newDeletedIcons}
      }
      return state;
    case 'setProjectDeletedIcon':
      return {...state, deletedIcons: action.payload};
    case 'setProjectLangAudio':
      const newEditLang = state.editLang;
      newEditLang.audioBlob = action.payload.blob;
      return {...state, editLang: newEditLang};
    case 'setProjectTags':
      return {...state, tags: action.payload.tags, tagStatus: action.payload.status};
    case 'setCopyProject':
      return {...state, projectToCopy: action.payload};
    default:
      return state;
  }
}

export default projectsReducer;
