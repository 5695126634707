import React from 'react';
import Row from './Row';

import icon_cross from 'resources/images/buttons/buttonIcons/cross_grey.png';


class CommentRow extends Row {

  constructor(props){
    super(props);
    this.init(props);
    this.state = {
      filename: props.commentBy? props.commentBy.icon : null,
      type: props.commentBy? 'profileIcon': '',
    }
    this.checkUrl();
    this.IsCommentByUser();
  }


  UNSAFE_componentWillReceiveProps(newProps){
    this.init(newProps);
    this.setState({
      filename: newProps.commentBy? newProps.commentBy.icon : null,
      type: newProps.commentBy? 'profileIcon': ''
    })
    this.checkUrl();
  }

  rowInfoComment(){
    const rowStyle = {...this.ui.styles.area, ...{
      width: '100%',
      height: this.bs.height * 0.04,
      alignItems: 'flex-start',
    }}

    const textScale = [this.bs.height* 0.36, this.bs.height* 0.05];
    const fontSize = this.bs.height * 0.025;
    return(
      <div style={rowStyle}>
        {this.textDisplay(this.props.comment.comment, textScale, fontSize, 'flex-start')}
      </div>
    )
  }

  rowCommentContent(title, rowInfo){
    const infoStyle = {...this.bs, ...{
      width: '75%',
      height: this.bs.height * 0.12,
      marginLeft: this.bs.height * 0.02
    }}
    return(
      <div style={infoStyle}>
        {this.rowCommentTitle(title)}
        {rowInfo()}
      </div>
    )
  }

  rowCommentTitle(title){
    if(!title){
      return null;
    }
    const nameStyle = {
      width: '100%',
      height: this.bs.height * 0.06,
      fontWeight: 'bold',
      fontSize: this.bs.height * 0.035,
      textAlign: 'left',
      display: 'flex',
      justifyContent: 'space-between'
    }
    return (
      <div style={nameStyle}>
        <div>
          {title}
        </div>
        <div>
          {this.IsCommentByUser() && this.buttons.langBar(icon_cross, 0.1, [this.bs.height* 0.04, this.bs.height * 0.04], ()=> {this.deleteComment()})}
        </div>
      </div>
    )
  }

  IsCommentByUser(){
    return this.store.user._id === this.props.comment.user ? true : false;
  }

  deleteComment(){
    this.actions.comment.deleteComment(this.props.comment);
    this.actions.content.pushView('viewCards');
    //console.log(this.props.comment);
  }


  render(){
    this.init(this.props);

    this.name = this.props.commentBy? this.props.commentBy.name: '';
    this.comment = this.props.comment? this.rowInfoComment.bind(this): '';

    return this.animatedRow(this.content.bind(this), this.bs.height * 0.15);
  }

  content = (style)=>(
    <div style={{...this.rowStyle(), ...{
      height: style.height,
      opacity: style.opacity,
      cursor: 'default'
    }}}>
      {this.verGap('3%')}
      {this.rowIcon()}
      {this.rowCommentContent(this.name, this.comment)}
    </div>
  )

}

export default CommentRow;
