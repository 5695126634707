import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';
import StatisticsCard from 'components/main/items/ui/StatisticsCard';
import ReactLineChart from 'components/main/items/graph/ReactLineChart';
import { Grid } from '@material-ui/core';

class SchoolStatistics extends SubView {

  constructor(props){
    super(props);
    this.init(props);
  }

  UNSAFE_componentWillReceiveProps(newProps){
    if(!this.stat){
      this.stat = this.store.content.statistics[this.store.schools.viewingSchool._id];
    }
  }

  componentDidMount(){
    this.getSchoolStatistic();
  }

  getSchoolStatistic(){
    if(!this.stat){
      this.actions.schools.getStatistics(this.store.schools.viewingSchool._id);
    }
  }

  statTextDisplay(text){
    return this.textDisplay(text, ['100%',''], '100%', 'center');
  }

  dateToName(date){
    const day = date.getDate();
    const month = date.getMonth() + 1;
    return day + '/' + month;
  }

  monthToName(date){
    const month = date.getMonth();
    switch (month) {
      case 0:
        return 'Jan';
      case 1:
        return 'Feb';
      case 2:
        return 'Mar';
      case 3:
        return 'Api';
      case 4:
        return 'May';
      case 5:
        return 'Jun';
      case 6:
        return 'Jul';
      case 7:
        return 'Aug';
      case 8:
        return 'Sep';
      case 9:
        return 'Oct';
      case 10:
        return 'Nov';
      case 11:
        return 'Dec';
      default:
        return '';
    }
  }

  groupByData(data, property){
      return data.reduce((acc, obj) => {
          const objPropery = obj[property];
          const date = new Date(objPropery);
          const key = date.getFullYear();
          if(!acc[key]){
              acc[key] = {xAxis: [], yAxis: []};
          }
          if(property === 'date'){
            acc[key]['xAxis'].push(this.dateToName(date));
            acc[key]['yAxis'].push(obj['value']); 
          }
          else if(property === 'month'){
            acc[key]['xAxis'].push(this.monthToName(date));
            acc[key]['yAxis'].push(obj['value']); 
          }
          return acc;
      }, {});
  }

  render() {
    this.init(this.props);
    if(!this.stat){
      return(
        <div style={this.subViewStyle()}>
          {this.subTitle(['Loading...','載入中...','载入中...'])}
        </div>
      );
    }

    const containerStyle = {
      marginTop: '1%',
    };
    
    return(
      <div style={{...this.subViewSafariStyle(), ...containerStyle}}>
        <Grid
        container
        spacing={2}
        style={{width: '95%'}}
        >
          <Grid item xs={12} sm={3} lg={3} xl={2}> 
            <StatisticsCard title={this.subTitle(['Teacher','老師','老师'])} value={this.stat.schoolTeachers.length}/>
          </Grid>
          <Grid item xs={12} sm={3} lg={3} xl={2}> 
            <StatisticsCard title={this.subTitle(['Student','學生','学生'])} value={this.stat.schoolStudents.length}/>
          </Grid>
          <Grid item xs={12} sm={3} lg={3} xl={2}> 
            <StatisticsCard title={this.subTitle(['Class','班別','班别'])} value={this.stat.schoolCourses.length}/>
          </Grid>
          <Grid item xs={12} sm={3} lg={3} xl={2}> 
            <StatisticsCard title={this.subTitle(['Unit','單元','单元'])} value={this.stat.schoolSubjects.length}/>
          </Grid>
          <Grid item xs={12} sm={3} lg={3} xl={2}> 
            <StatisticsCard title={this.subTitle(['Project','專題研習','专题研习'])} value={this.stat.schoolProjects.length}/>
          </Grid>
          <Grid item xs={12} sm={3} lg={3} xl={2}> 
            <StatisticsCard title={this.subTitle(['Card (Featured)','卡片(精選)','卡片(精选)'])} value={this.stat.schoolCards.length + ' (' + this.stat.featuredCount + ')'}/>
          </Grid>
          <Grid item xs={12} sm={3} lg={3} xl={2}> 
            <StatisticsCard title={this.subTitle(['Lang','語言欄','语言栏'])} value={this.stat.schoolLangs.length}/>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12}> 
            <ReactLineChart app={this.app} data={this.groupByData(this.stat.cardDateGraphData, 'date')} title={this.subTitle(['Card - Date Graph','卡片 - 日期圖表','卡片 - 日期图表'], '', 'left')}/>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12}> 
            <ReactLineChart app={this.app} data={this.groupByData(this.stat.cardMonthGraphData, 'month')} title={this.subTitle(['Card - Month Graph','卡片 - 月份圖表','卡片 - 月份图表'], '', 'left')}/>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12}> 
            <ReactLineChart app={this.app} data={this.groupByData(this.stat.loginDateGraphData, 'date')} title={this.subTitle(['Login - Date Graph','登入 - 日期圖表','登入 - 日期图表'], '', 'left')}/>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12}> 
            <ReactLineChart app={this.app} data={this.groupByData(this.stat.loginMonthGraphData, 'month')} title={this.subTitle(['Login - Month Graph','登入 - 月份圖表','登入 - 月份图表'], '', 'left')}/>
          </Grid>
        </Grid>
      </div>
    );
  }

}

export default SchoolStatistics;
