import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';

import SubjectRow from 'components/main/items/rows/SubjectRow';

class CourseSubjects extends SubView {

  componentDidMount(){
    this.init(this.props);
    this.getCourseSubjects();
    this.setListScroll('courseSubjectsList');
  }

  getCourseSubjects(){
    const course = this.store.courses.viewingCourse;

    const subjectsToGet = [];
    const subjectsToShow = course.subjects;
    if(subjectsToShow.length === 0 && this.store.user.type === 'teacher' && !this.inSchool){
      this.actions.content.pushHint({type:'noSubject'});
    }

    for(var i=0;i<subjectsToShow.length;i++){
      if(this.func.getById.subject(subjectsToShow[i], this.store) === null){
        subjectsToGet.push(subjectsToShow[i]);
      }
    }

    if(subjectsToGet.length > 0){
      this.actions.subjects.getSubjects(subjectsToGet);
    }
  }

  routeLink(subjectId){
    const prevUrl = window.location.href;
    const currentUrl = prevUrl + '/subject/' + subjectId;
    this.actions.content.pushRouteLink(prevUrl);
    window.history.pushState({}, null, currentUrl);
  }


  async swapSubjects(subjects, index1, index2){
    var rowIndex1 = -1, rowIndex2 = -1;
    for(var i = 0; i < this.store.subjects.subjectRows.length; i++){
      if(subjects[index1] === this.store.subjects.subjectRows[i].subject._id)
        rowIndex1 = i;
      else if(subjects[index2] === this.store.subjects.subjectRows[i].subject._id)
        rowIndex2 = i;
    }
    
    var temp = this.store.subjects.subjectRows[rowIndex1].url;
    this.store.subjects.subjectRows[rowIndex1].url = this.store.subjects.subjectRows[rowIndex2].url;
    this.store.subjects.subjectRows[rowIndex2].url = temp;
    
    temp =  this.store.subjects.subjectRows[rowIndex1].subject;
    this.store.subjects.subjectRows[rowIndex1].subject =  this.store.subjects.subjectRows[rowIndex2].subject;
    this.store.subjects.subjectRows[rowIndex2].subject = temp;
    
    temp =  this.store.subjects.subjectRows[rowIndex1].state.alert;
    this.store.subjects.subjectRows[rowIndex1].state.alert =  this.store.subjects.subjectRows[rowIndex2].state.alert;
    this.store.subjects.subjectRows[rowIndex2].state.alert = temp;

  }

   subjectsList(){
    const subjects = this.store.courses.viewingCourse.subjects;
    if(subjects.length === 0){
      return this.subTitle(['No subjects','此班別未有議題','此班别未有议题'])
    }
    if(subjects.length > 1)
      subjects.sort((a, b) => {
      var aSubject = this.func.getById.subject(a, this.store);
      var bSubject = this.func.getById.subject(b, this.store);
      if(aSubject !== null && bSubject !== null)
        return aSubject.createdAt.toString().substring(20, 23) - bSubject.createdAt.toString().substring(20, 23);
      else
        return true;
    });
    return subjects.slice(0).reverse().map((subjectId, i)=>{
      const subject = this.func.getById.subject(subjectId, this.store);
      if(!subject){ return null; }
      if(this.store.user.type === 'student' && subject.hide){ return null;}
      return <SubjectRow onClick={()=>{this.actions.subjects.viewSubject(subject); this.actions.content.pushView('subject'); this.routeLink(subject._id);}} app={this.app} 
      handleUp = {(subjectSelected) => {
        var index = -1;
        for (var i = 0; i < subjects.length; i++) {
          if (subjectSelected._id === subjects[i])
            index = i;
        }

        if (index < subjects.length - 1) {
          this.swapSubjects(subjects, index, index + 1);
          this.actions.subjects.swapSubjects(subjects[index], subjects[index + 1]);
          setTimeout(() => {this.actions.subjects.getSubjects(subjects);}, 200);
        }
      }}
      handleDown = {(subjectSelected) => {
        var index = -1;
        for (var i = 0; i < subjects.length; i++) {
          if (subjectSelected._id === subjects[i])
            index = i;
        }

        if (index > 0) {
          this.swapSubjects(subjects, index, index - 1);
          this.actions.subjects.swapSubjects(subjects[index], subjects[index - 1]);
          setTimeout(() => {this.actions.subjects.getSubjects(subjects);}, 200);
        }
    }}
      subject={subject} key={i} index={i} len={subjects.length} button={true}/>
    })
  }

  render() {
    this.init(this.props);
    return(
      <div style={this.subViewStyle()}>
        <div id={'courseSubjectsList'} onScroll={()=>{ this.onScroll('courseSubjectsList'); }} style={{...this.bs, ...this.ui.styles.list}}>
          {this.subjectsList()}
        </div>
      </div>
    )
  }

}

export default CourseSubjects;
