import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';

import ProfileRow from 'components/main/items/rows/ProfileRow';

class CourseStudents extends SubView {

  constructor(props){
    super(props);
    this.init(props);
    this.state = { rowToShow: 30 }
  }

  componentDidMount(){
    this.init(this.props);
    this.getStudentProfiles();
    this.setListScroll('courseStudentsList');
  }

  getStudentProfiles(){
    const course = this.store.courses.viewingCourse;

    const profilesToGet = [];
    const profilesToShow = course.joinedStudents;

    for(var i=0;i<profilesToShow.length;i++){
      if(this.func.getById.profileByUser(profilesToShow[i], this.store) === null){
        profilesToGet.splice(0,0, profilesToShow[i]);
      }
    }
    if(profilesToGet.length > 0){
      this.actions.profiles.getProfiles(profilesToGet);
    }
  }

  /*
  studentsList(){
    var students = this.store.courses.viewingCourse.joinedStudents;
    if(students.length === 0){
      return this.subTitle(['No joined students','此班別未有學生加入','此班别未有学生加入'])
    }
  
    var first = this.store.user._id;
    students.sort((x,y)=>{ return x === first ? -1 : y === first ? 1 : 0; });

    return students.slice(0, this.state.rowToShow).map((userId, i)=>{
      const profile = this.func.getById.profileByUser(userId, this.store);
      if(!profile){ return null; }
      return (
      <ProfileRow
      app={this.app}
      profile={profile}
      key={i}
      onClick={()=>{ this.actions.profiles.viewProfile(profile); this.actions.content.pushView('student');}}/>)
    })
  }
  */


  studentsList(){
    if(!this.props.sortedStudentList){ return this.subTitle(['No joined students','此班別未有學生加入','此班别未有学生加入']); }
    return this.props.sortedStudentList.slice(0, this.state.rowToShow).map((profile, i)=>{
      if(!profile){ return null; }
      return (
      <ProfileRow
      app={this.app}
      profile={profile}
      key={i}
      onClick={()=>{ this.actions.profiles.viewProfile(profile); this.actions.content.pushView('student');}}/>)
    })
  }


  render() {
    this.init(this.props);
    return(
      <div style={this.subViewStyle()}>
        <div id={'courseStudentsList'} onScroll={()=>{ this.onScroll('courseStudentsList',  ()=>{ this.addRowToShow(30);}) }} style={{...this.bs, ...this.ui.styles.list}}>
          {this.studentsList()}
        </div>
      </div>
    )
  }

}

export default CourseStudents;
