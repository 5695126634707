import React from 'react';
import View from 'components/main/pages/home/views/View';
import no_image from 'resources/images/general/no_image.png';

class ProjectList extends View {

  constructor(props){
      super(props);
      this.init(props);
   }

  componentDidMount(){
    this.init(this.props);
    this.setListScroll('subjectProjectsList');
  }

  changeBackground(id){
    let div = document.getElementById("div1" + id);
    div.style.backgroundColor = "grey";
  }

  removeBackground(id){
    let div = document.getElementById("div1" + id);
    div.style.backgroundColor = "white";
  }
  projectsList() {
    var projects = this.store.projects.schoolProjects;
    if (projects.length === 0) {
      return this.subTitle(['No projects', '此議題未有專題研習', '此议题未有专题研习'])
    }
    if(projects.length > 1)
    projects.sort((a, b) => {
      const aDate = new Date(a.createdAt), bDate = new Date (b.createdAt);
      if(bDate !== aDate)
       return bDate - aDate;
      else
        return b.title - a.title;
    });
    return projects.slice(0).map((project, i) => {
        return  <div key={"div1" + project._id} id={"div1" + project._id} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "100%", borderBottom:"1px solid rgb(219, 219, 219)", borderImage: "initial", position: 'relative', cursor: "pointer"}}
        onClick={ async ()=>{  let url = this.store.content.cachedUrl[project.icon];
            if(url == null){
              url = await this.func.url(project.icon, 'projectIcon');
            }
            const blob = await this.url.urlToBlob(url);
            this.actions.main.setPhoto({url: url, blob: blob});
            if(project.allowedComments){
                const allowedComments = project.allowedComments.map((allowedComment, i)=>{return {key: i, title: allowedComment}});
                console.log(allowedComments);
                this.actions.allowedComment.setEditAllowedComments(allowedComments);
            }
            if(project.audioDesc){
                const audioUrl =  await this.func.url(project.audioDesc, 'projectAudioDesc');
                const audioBlob =  await this.url.urlToBlob(audioUrl);
                this.actions.projects.setProjectLangAudio({blob: audioBlob});   
            }
            else
                this.actions.projects.setProjectLangAudio({blob: null});
            console.log("Copied");
            this.actions.projects.copy(project);
            this.actions.content.pullView();
    }} onMouseOver={()=>this.changeBackground(project._id)} onMouseLeave={()=>this.removeBackground(project._id)} >
              {/*this.verGap('2%')*/}
              {this.rowIcon( this.func.outDated(project.endDate), this.store.content.cachedUrl[project.icon])  }
              {this.rowContent(project.title, project.description)}
      </div>
  })
  }

  rowContent(title, description){
    const infoStyle = {...this.bs, ...{
      width: '80%',
      height: this.bs.height * 0.12,
      marginLeft: this.bs.height * 0.02
    }};
    const rowStyle = {...this.ui.styles.area, ...{
      width: '100%',
      height: this.bs.height * 0.06,
      alignItems: 'flex-end'
    }};
    return(
      <div style={infoStyle}>
        {this.rowTitle(title)}
        <div style={rowStyle}>
          {this.textDisplay(description, ['',''], '150%')}
        </div>
      </div>
    )
  }

  rowTitle(title){
    //console.log("title: ", title);
    if(!title){
      return null;
    }
    const nameStyle = {
      width: '100%',
      height: this.bs.height * 0.06,
      fontWeight: 'bold',
      fontSize: this.bs.height * 0.035,
      textAlign: 'left'
    }
    return <div style={nameStyle}>{title}</div>
  }

  rowIcon(passed, defaultUrl){
    const size = this.bs.height * 0.12;
    const iconSize = this.bs.height * 0.11;
    const containerStyle = {...this.ui.styles.border , ...this.ui.styles.container, ...{
      width: size,
      height: size,
      backgroundColor: 'white',
      position: 'relative'
    }}
    const iconStyle = {...this.ui.styles.border, ...{
      maxWidth: iconSize,
      maxHeight: iconSize,
      backgroundColor: 'white'
    }}
    const url = defaultUrl? defaultUrl: this.url.url? this.url.url: no_image;
    return(
      <div style={containerStyle}>
        <img key={url} style={iconStyle} src={url} alt=''/>
      </div>
    )
  }

  render() {
    this.init(this.props);
    return(
        <div style={this.viewStyle()}>
          <div id={'subjectProjectsList'} onScroll={() => { this.onScroll('subjectProjectsList'); }} style={{ ...this.bs, ...this.ui.styles.list }}>
            {this.projectsList()}
          </div>
      </div>
    )
  }

}

export default ProjectList;
