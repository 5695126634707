import React from 'react';
import View from 'components/main/pages/home/views/View';
import ImagePicker from 'components/main/items/ImagePicker';
import MultipleSelect from 'components/main/items/ui/MultipleSelect';
import * as TagTranslator from 'components/main/pages/home/helpers/TagTranslator.js';

class AddCourse extends View {

  constructor(props){
    super(props);
    this.init(props);
    this.course = this.store.courses.viewingCourse;
    this.state = {
      modified: false,
      filename: this.course.icon,
      type: 'courseIcon',
    }
    this.checkUrl();
  }

  componentWillUnmount(){
    this.actions.main.setPhotoAttr(0);
  }

  multipleCheckBox(onChange){
    const tagsChi = ['中文', '英文', '數學', '常識'];
    const tagsSimChi = ['中文', '英文', '数学', '常识'];
    const tagsEng = ['Chinese', 'English', 'Mathematics', 'General Studies'];
    const choices = this.store.main.language === 'chinese'? tagsChi: this.store.main.language === 'simplified_chinese'? tagsSimChi: tagsEng;
    var defaultTag = this.course.tags?this.course.tags:[];
    defaultTag = TagTranslator.convert(defaultTag, 'english', this.store.main.language);
    return(
      <MultipleSelect app={this.app} choices={choices} defaultTag={defaultTag} openStatus={null} onChange={onChange} type={'course'} />
    )
  }

  render() {
    this.init(this.props);
    var defaultDate = new Date();
    defaultDate.setFullYear(defaultDate.getFullYear() + 1);

    return(
      <div style={this.viewStyle()}>
        {this.gap('4%')}

        {this.subTitle(['Icon','照片','照片'])}
        {this.sep()}
        <ImagePicker type={'course'} defaultUrl={this.url.url} app={this.app} />
        {this.sep()}
        {this.gap('2%')}

        {this.subTitle(['Title','班名','班名'])}
        {this.sep()}
        {this.inputs.inputField('title','text', '', this.props.editMode? this.course.title:'' , ()=>{ this.setState({modified: true})})}
        {this.gap('2%')}

        {this.subTitle(['Tag', '標籤', '标籤'])}
        {this.sep()}
        {this.gap('0.5%')}
        {this.multipleCheckBox(()=>{ this.setState({modified: true}) })}
        {this.gap('4%')}

        {this.subTitle(['End date','結束日期','结束日期'])}
        {this.sep()}
        {this.inputs.inputField('endDate','date', ['',''], this.func.dateString(this.props.editMode? new Date(this.course.endDate):defaultDate), ()=>{this.setState({modified: true})} )}
        {this.gap('2%')}

        {this.buttons.rectGreen(['Confirm','確定','确定'], ()=>{this.addCourse()})}
        {this.gap('2%')}
      </div>
    )
  }

  addCourse(){
    const editMode = this.props.editMode;
    const _icon = this.store.main.photoBlob;
    const _title = document.getElementById('title').value;
    const _endDate = document.getElementById('endDate').value;
    const language = this.store.main.language;
    const _tags = language === 'chinese' || language === 'simplified_chinese'? TagTranslator.convert(this.store.courses.tags, language, 'english'): this.store.courses.tags;
    
    if(!editMode && _icon === null){
      return this.failedMessage(['Failed to add! Icon is missing!', '提交失敗! 未有照片!','提交失败! 未有照片!'])
    }
    if(_title.length === 0){
      return this.failedMessage(['Failed to add! Title is missing!', '提交失敗! 未填班名!','提交失败! 未填班名!'])
    }

    if(!editMode){
      this.actions.courses.addCourse({
        teacher: this.store.user.type === 'developer'? this.store.profiles.viewingTeacherProfile.belongTo: this.store.user._id,
        icon: _icon,
        title: _title,
        tags: _tags,
        endDate: _endDate
      }, this.store.user.type, this.store.profiles.viewingTeacherProfile)
    }else if(_icon || this.state.modified){
      this.actions.courses.editCourse({...this.course, ...{
        newIcon: _icon,
        title: _title,
        tags: _tags,
        endDate: _endDate
      }})
    }else{
      return this.failedMessage(['Failed to add! Nothing is modified!', '提交失敗!未作出更改!', '提交失败!未作出更改!'])
    }
  }
}

export default AddCourse;
