import React from 'react';
import Row from './Row';

import SwitchLabel from 'components/main/items/ui/SwitchLabel';


class SettingRow extends Row {

  constructor(props){
    super(props);
    this.init(props);
  }

  convertKeyToName(key){
    switch(key){
      case 'trialAccount':
        return this.func.multiLang('Trial Account', '試用帳號', '试用帐号');
      default:
        return key;
    }
  }

  handleSwitch(key, value){
    const setting = this.store.setting;
    setting[key] = value;
    this.actions.setting.setSetting(setting);
  }

  settingRowContent(data){
    const infoStyle = {...this.bs, ...{
      width: '90%',
      height: this.bs.height * 0.08,
      marginLeft: this.bs.height * 0.02,
    }}
    return(
      <div style={infoStyle}>
        {this.rowParameter(data)}
      </div>
    )
  }

  rowParameter(data){
    const paraStyle = {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }
    const nameStyle = {
      width: '100%',
      fontWeight: 'bold',
      fontSize: this.bs.height * 0.025,
      textAlign: 'left',
    }
    return (
      <div style={paraStyle}>
        <div style={nameStyle}>
          {data? this.convertKeyToName(data.key): null}
        </div>
        <div>
          <SwitchLabel data={data? data.value: false} onSwitch={(checked) => this.handleSwitch(data.key, checked)}/>
        </div>
      </div>
    )
  }

  render(){
    return this.animatedRow(this.content.bind(this), this.bs.height * 0.15)
  }

  content = (style)=>{
    const data = this.props.data;

    return(
      <button key={this.url.url} onClick={this.props.onClick} style={{...this.rowStyle(), ...{
        height: style.height,
        opacity: style.opacity,
        cursor: 'auto'
      }}}>
        {this.verGap('3%')}
        {this.settingRowContent(data)}
      </button>
    )
  }
}

export default SettingRow;
