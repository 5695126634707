import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';
import Files from 'components/main/pages/home/views/home/contents/Files';
import icon_tag from 'resources/images/icons/tag.png';
import icon_setting from 'resources/images/icons/settings.png';

class Setting extends SubView {

  render(){
    this.init(this.props);
    const data = this.getData();
    if(!data){ return null; }

    return(
      <Files app={this.app} data={data}/>
    )
  }

  getData(){
    const subView = this.store.content.subView;
    var data = null;
    if(subView === 'setting'){
      data = [{
        icon: icon_tag,
        title: this.func.multiLang('Add tags to schools', '為學校添加標籤', '为学校添加标签'),
        type: 'addTagsToSchools',
        onClick: ()=>{
          this.actions.content.pushView('addTagsToSchools'); 
          this.actions.schools.getAllSchools();
          this.actions.schoolTags.get();}
      },{
        icon: icon_setting,
        title: this.func.multiLang('Change setting', '變更設定', '变更设定'),
        type: 'changeSetting',
        onClick: ()=>{
          this.actions.content.pushView('changeSetting');
          this.actions.setting.get();
        }
      }]
    }
    return data;
  }

}

export default Setting;
