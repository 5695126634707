import axios from 'axios';
import * as actions from '../actions';
import to from '../to';
var api = actions.api();

export const viewSubject = (subject) =>{
  return {
    type: 'viewSubject',
    payload: subject
  }
}

export const deleteIconAction = (boolean) =>{
  return {
    type: 'deleteSubjectIconAction',
    payload: boolean
  }
}

export const pushDeletedIcon = (icon) =>{
  return {
    type: 'pushSubjectDeletedIcon',
    payload: icon
  }
}

export const removeDeletedIcon = (icon) =>{
  return {
    type: 'removeSubjectDeletedIcon',
    payload: icon
  }
}

export const setDeletedIcon = (icons) =>{
  return {
    type: 'setSubjectDeletedIcon',
    payload: icons
  }
}

export const setTags = (tags, status) =>{
  return {
    type: 'setSubjectTags',
    payload: {tags: tags, status: status}
  }
}

export const copy = (subject) =>{
  return {
    type: 'setCopySubject',
    payload: subject
  }
}

export function deleteDefaultIcons(data){
  return async function(dispatch){
    actions.connecting(dispatch);

    let err, updateRes;

    [err, updateRes] = await to(axios.post(api + '/subject/deleteDefaultIcon', { data: data}))
    if(err){actions.connectionError(dispatch); return;}

    if(updateRes.data.result === 'success'){
      dispatch({type: 'message', payload: ['Delete icons succeed!', '成功刪除照片!', '成功删除照片!']});
      dispatch({type: 'updateSubjects', payload: [updateRes.data.editedSubject]});
      dispatch({type: 'viewSubject', payload: updateRes.data.editedSubject});
      dispatch({type: 'hideModal'});
      //dispatch({type: 'pullView'});
    }else{
      dispatch({type: 'message', payload: ['Delete icons failed! Please try again!', '刪除失敗! 請再試一次!', '删除失败! 请再试一次!']});
    }
  }
}

export function addDefaultIcon(data){
  return async function (dispatch) {
    actions.connecting(dispatch);
    const subject = data.subject;
    const icon = data.icon;
    const iconAttr = data.iconAttr;
    const config = {
       onUploadProgress: progressEvent => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          percentCompleted = percentCompleted > 89? 90: percentCompleted;
          dispatch({type: 'loadingPercent', payload: percentCompleted});
          dispatch({type: 'loadingStatus', payload: 'on'});
       },
       headers: {type: 'subjectDefaultIcon', rotation: iconAttr.rotation}
    };

    var iconFile = new FormData();
    iconFile.append('files', data.icon, 'subjectDefaultIcon.png');
    /*
    if(!data.resubmit){

    }else if(data.newIcon){
      iconFile.append('files', data.newIcon, 'courseDefaultIcon.png');
    }
    */

    let err, uploadRes, updateRes;
    [err, uploadRes] = await to(axios.post(api + '/upload', iconFile, config))
    if(err){actions.connectionError(dispatch); return;}
    dispatch({type: 'loadingPercent', payload: 100});

    const filenames = uploadRes.data.filenames;
    var subjectDefaultIcon = null;
    for(var j=0;j<filenames.length;j++){
      const splted = filenames[j].split('-');
      if(splted[1] === 'subjectDefaultIcon.png'){
        subjectDefaultIcon = filenames[j];
      }
    }

    [err, updateRes] = await to(axios.post(api + '/subject/addDefaultIcon', { data: { subject: subject, defaultIcon: subjectDefaultIcon?subjectDefaultIcon:icon, }}))
    if(err){actions.connectionError(dispatch); return;}

    if(updateRes.data.result === 'success'){
      dispatch({type: 'message', payload: ['Submit card succeed!', '成功提交卡片!', '成功提交卡片!']});
      dispatch({type: 'updateSubjects', payload: [updateRes.data.editedSubject]});
      dispatch({type: 'viewSubject', payload: updateRes.data.editedSubject});
      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
      dispatch({type: 'pullView'});
    }else{
      //console.log(cardRes.data.result);
      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
      dispatch({type: 'message', payload: ['Submit card failed! Please try again!', '提交失敗! 請再試一次!', '提交失败! 请再试一次!']});
    }
  }
}

export function getAllSubjectsOfSchool(school){
  return async function (dispatch){
    let err, res;
    [err, res] = await to(axios.post(api + '/subject/getAllOfSchool', { data: school }));
    if(err){actions.connectionError(dispatch); return;}

    if(res.data.result === 'success'){
      dispatch({type: 'updateSchoolSubjects', payload: res.data.subjects})
      dispatch({type: 'hideModal'});
    }else{
      dispatch({type: 'message', payload: ['Failed to get subjects data!', '無法查閱議題資料!', '无法查阅议题资料!']});
    }
  }
}

export function getAllSubjectsOfUser(profile){
  return async function (dispatch){
    let err, res;
    [err, res] = await to(axios.post(api + '/subject/getAllOfUser', { data: profile }));
    if(err){actions.connectionError(dispatch); return;}

    if(res.data.result === 'success'){
      dispatch({type: 'updateSubjects', payload: res.data.subjects});
      dispatch({type: 'updateProjects', payload: res.data.projects});
      dispatch({type: 'updateStudentProjects', payload: res.data.studentProjects});
      dispatch({type: 'updateProfiles', payload: [res.data.profile]});
      dispatch({type: 'viewProfile', payload: res.data.profile});
    }else{
      dispatch({type: 'message', payload: ['Failed to get subjects data!', '無法查閱議題資料!', '无法查阅议题资料!']});
    }
  }
}

export function getSubjects(subjects){
  //console.log(subjects)
  return async function (dispatch) {
    //actions.connecting(dispatch);
    let err, res;
    [err, res] = await to(axios.post(api + '/subject/getMultiple', { data: subjects }));
    if(err){actions.connectionError(dispatch); return;}

    if(res.data.result === 'success'){
      dispatch({type: 'updateSubjects', payload: res.data.subjects});
    }else{
      dispatch({type: 'message', payload: ['Failed to get subjects data!', '無法查閱議題資料!', '无法查阅议题资料!']});
    }

  }
}

export function editSubject(editedSubject){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, uploadRes, updateRes;

    if(editedSubject.newIcon){
      var iconFile = new FormData();
      iconFile.append('files', editedSubject.newIcon, 'subjectIcon.png');
      [err, uploadRes] = await to(axios.post(api + '/upload', iconFile, { headers: { type: 'subjectIcon'}}))
      if(err){actions.connectionError(dispatch); return;}

      editedSubject['icon'] = uploadRes.data.filenames[0];
    }

    [err, updateRes] = await to(axios.post(api + '/subject/edit', {data: editedSubject}));
    if(err){actions.connectionError(dispatch); return;}

    if(updateRes.data.result === 'success'){
      dispatch({type: 'message', payload: ['Edit subject succeed!', '成功修改議題!', '成功修改议题!']});
      dispatch({type: 'updateSubjects', payload: [updateRes.data.editedSubject]});
      dispatch({type: 'viewSubject', payload: updateRes.data.editedSubject});
      dispatch({type: 'pullView'});
    } else {
      actions.updateFailed(dispatch);
    }
  }
}

export function addSubject(newSubject){
  //console.log(newSubject)
  return async function (dispatch) {
    actions.connecting(dispatch);

    let subjectToCopy;
    let err, uploadRes, addRes;

    ({subjectToCopy, ...newSubject} = newSubject); 
    var iconFile = new FormData();
    iconFile.append('files', newSubject.icon, 'subjectIcon.png');

    [err, uploadRes] = await to(axios.post(api + '/upload', iconFile, { headers: { type: 'subjectIcon'}}));
    if(err){actions.connectionError(dispatch); return;}
    //console.log('File uploaded');
    if(uploadRes.data.result === 'success'){
      newSubject['icon'] = uploadRes.data.filenames[0];
    }else{
      actions.connectionError(dispatch);
      return;
    }

    [err, addRes] = await to(axios.post(api + '/subject/add', { data: { subject: newSubject, subjectToCopy: subjectToCopy } }));
    if(err){actions.connectionError(dispatch); return;}

    //console.log(res.data);
    if(addRes.data.result === 'success'){
      dispatch({type: 'message', payload: ['Add subject succeed!', '成功創建議題!', '成功创建议题!']});
      dispatch({type: 'updateSubjects', payload: [addRes.data.newSubject]});
      dispatch({type: 'updateTeachingSubjects', payload: [addRes.data.newSubject._id]});
      dispatch({type: 'updateCourses', payload: [addRes.data.updatedCourse]});
      dispatch({type: 'viewCourse', payload: addRes.data.updatedCourse});
      dispatch({type: 'pullView'});
      //dispatch({type: 'setPhoto', payload: {blob: null, url: null}});
    }else{
      dispatch({type: 'message', payload: ['Add subject failed! Please try again!', '創建失敗! 請再試一次!', '创建失败! 请再试一次!']});
    }

  }
}

export function swapSubjects(subject1, subject2){
  return async function (dispatch) {
    //actions.connecting(dispatch);
    let err, res;
   
    [err, res] = await to(axios.post(api + '/subject/swap', { data: { subject1: subject1, subject2: subject2 }}));
    
    if(err){actions.connectionError(dispatch); return;}

    if(res.data.result === 'success'){
      //dispatch({type: 'message', payload: ['Sucess'] });
    }else{
      dispatch({type: 'message', payload: ['Failed to get projects data!', '無法查閱專題研習資料!', '无法查阅专题研习资料!']});
    }

  }
}
