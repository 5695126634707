import React from 'react';
import UI from 'components/UI';
import Sound from 'react-sound';
import AudioPlayer, {RHAP_UI} from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import cloudDownload from 'resources/images/buttons/buttonIcons/cloud_download.png';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';

//import icon_play from 'resources/images/buttons/buttonIcons/play_grey.png';
//import icon_stop from 'resources/images/buttons/buttonIcons/stop_grey.png';

class LangRow extends UI {

  constructor(props){
    super(props);
    this.init(props);
    this.state = {
      filename: props.lang.audio,
      type: 'langAudio',
      isPlaying: false
    }
    this.checkUrl();
  }

  UNSAFE_componentWillReceiveProps(newProps){
    this.init(newProps);
    const newFilename = newProps.lang.audio;
    if(this.state.filename !== newFilename){
      this.setState({ filename: newFilename, type: 'langAudio' })
      this.checkUrl();
    }
    if(!this.props.autoPlay && newProps.autoPlay && !this.state.isPlaying){
      this.setState({ isPlaying: true })
    }
  }

  playback(){
    if(this.state.isPlaying){ return; }
    this.setState({
      isPlaying: true
    })
  }

  onPlaybackEnd(){
    this.setState({
      isPlaying: false
    })
    /*
    if(this.props.autoPlay && this.props.onAutoPlayEnd){
      this.props.onAutoPlayEnd();
    }*/
  }

  stopPlayback(){
    if(!this.state.isPlaying){ return; }
    this.onPlaybackEnd();
  }

  langRow(){
    const lang = this.props.lang;

    const rowStyle = {...this.bs, ...{
      width: '100%',
      //height: this.props.single? this.bs.height * 0.37: this.bs.height * 0.185,
      height: !this.props.isGrading? 
              this.props.single? this.bs.height * 0.37: this.bs.height * 0.185: 
              this.props.single? this.bs.height * 0.47: this.bs.height * 0.285,
      backgroundColor: 'white',
      borderBottom: '4px solid ' + this.ui.colors.ultraLightGrey,
      flexShrink: 0
    }}

    return(
      <div style={rowStyle}>
        {/*this.audio(lang)*/}
        {this.langBar(lang)}
        {this.props.lang.audio && !this.url.url? <CircularProgress style={{color: this.store.ui.colors.mlangGreen}}/>:
         this.url.url && this.audioPlayer()}
        {this.langText(lang.text)}
        {this.gap('1%')}
      </div>
    )

  }

  audio(lang){
    if(this.state.isPlaying && this.url.url){
      return(
        <Sound
        url={this.url.url}
        playStatus={Sound.status.PLAYING}
        onFinishedPlaying={this.onPlaybackEnd.bind(this)}/>
      )
    }
    return null;
  }

  downloadAudio(url, name){
    var link = document.createElement('a');
    link.href = url;
    link.download = name ? name : 'true';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  audioPlayer(){
    const containerStyle = {...this.ui.styles.area, ...{
      width: !this.props.isGrading? '97%': '95%',
      height: !this.props.isGrading? this.bs.height * 0.065: this.bs.height * 0.095,
      minHeight: !this.props.isGrading? '50px': '80px',
      flexShrink: 0,
    }}

    const audioPlayerStyle = { 
      height: !this.props.isGrading? this.bs.height * 0.060: this.bs.height * 0.095, 
      minHeight: !this.props.isGrading? '50px': '80px',
    }

    const sizeSmall = [this.bs.height * 0.05, this.bs.height * 0.05];

    return (  
      <div style={containerStyle}>
        {!this.props.isGrading? 
          <AudioPlayer 
            style={audioPlayerStyle}
            atuoPlay={false}
            autoPlayAfterSrcChange={false}
            src={this.url.url} 
            layout="horizontal-reverse"
            customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
          />:
          <AudioPlayer 
            style={audioPlayerStyle}
            atuoPlay={false}
            autoPlayAfterSrcChange={false}
            src={this.url.url} 
            customControlsSection={[RHAP_UI.ADDITIONAL_CONTROLS, RHAP_UI.MAIN_CONTROLS]}
            customAdditionalControls={[
              this.buttons.langBar(cloudDownload , 0.5, sizeSmall,()=>{this.downloadAudio(this.url.url, this.props.lang.audio)})
            ]}
          />
        }
      </div>
    )
  }

  langBar(lang){
    const barStyle = {...this.ui.styles.area, ...{
      width: '100%',
      //height: this.bs.height * 0.065,
      height: this.bs.height * 0.040,
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexShrink: 0,
    }}

    //const sizeSmall = [this.bs.width * 0.04,this.bs.width * 0.04];

    return(
      <div style={barStyle}>
        {this.verGap('1%')}
        {this.textDisplay(this.func.langKeyToLangName(lang.key), ['60%',''], '75%', 'left', this.ui.colors.deepDarkGrey)}
        {/*this.verGap('1%')*/}
        {/*this.url.url && !this.state.isPlaying && this.buttons.langBar(icon_play , 0.25, sizeSmall,()=>{this.playback()})*/}
        {/*this.url.url && this.state.isPlaying && this.buttons.langBar(icon_stop, 0.25, sizeSmall,()=>{this.stopPlayback()})*/}
      </div>
    )
  }

  async restore(text) {
    document.getElementById('testText').innerHTML = text;
  }

  async checkVariants(text) {
    let result =  await axios.post('https://demo-api.mlang.social/variants', { text: text});
    console.log(result);
    if (result)  {
      let cText = document.getElementById('testText').innerHTML;
      if (result.data) {
        for (const variant in result.data) {
          cText = cText.replaceAll(variant, `<span style="background:#FCA;margin:0 5px;">${variant} (${result.data[variant]})</span>`);
        }
      }
      document.getElementById('testText').innerHTML = cText;
    }
  }

  async checkVocab(text) {
    let result =  await axios.post('https://demo-api.mlang.social/vocab', { text: text});
    console.log(result);
    if (result)  {
      let cText = document.getElementById('testText').innerHTML;
      if (result.data.stage1) {
        for (let x = 0; x < result.data.stage1.length; x++) {
          cText = cText.replaceAll(result.data.stage1[x], `<span style="background:#9CF;margin:0 5px;">${result.data.stage1[x]}</span>`);
        }
      }
      if (result.data.stage2) {
        for (let x = 0; x < result.data.stage2.length; x++) {
          cText = cText.replaceAll(result.data.stage2[x], `<span style="background:#CFC;margin:0 5px;">${result.data.stage2[x]}</span>`);
        }
      }
      document.getElementById('testText').innerHTML = cText;
    }
  }

  async checkHans(text) {
    let result =  await axios.post('https://demo-api.mlang.social/hans', { text: text});
    console.log(result);
    if (result)  {
      let cText = document.getElementById('testText').innerHTML;
      if (result.data) {
        for (const sc in result.data) {
          cText = cText.replaceAll(sc, `<span style="background:#FCC;margin:0 5px;">${sc} (${result.data[sc]})</span>`);
        }
      }
      document.getElementById('testText').innerHTML = cText;
    }
  }

  langText(text){
    const style = {...this.ui.styles.button, ...{
      width: '95%',
      height: '',
      fontSize: '200%',
      overflow: 'auto',
      overflowWrap: 'break-word',
      textAlign: 'left',
      whiteSpace: 'pre-wrap'
    }}
    const demoButtons = {
      display: 'flex'
    }
    const demoButton = {
      ...this.ui.styles.button, ... {
        padding: '.5em 1em',
        border: '3px solid',
        background: '#CCC',
        margin: '0 1em'
      }
    }
    const demoButton1 = {
      ...demoButton, ... {
        background: '#FCA',
      }
    }
    const demoButton2 = {
      ...demoButton, ... {
        background: '#9CF',
      }
    }
    const demoButton3 = {
      ...demoButton, ... {
        background: '#FCC',
      }
    }

    return(<>
      <button id='testText' style={style} onClick={()=>{this.actions.main.enlargeText(text)}} dangerouslySetInnerHTML={{__html: text}}>
        {/* {text} */}
      </button>
      <div style={demoButtons}>
        <button style={demoButton} onClick={async()=>{await this.restore(text)}}>---</button>
        <button style={demoButton1} onClick={async()=>{await this.checkVariants(text)}}>異體字</button>
        <button style={demoButton2} onClick={async()=>{await this.checkVocab(text)}}>香港小學學習字詞表</button>
        <button style={demoButton3} onClick={async()=>{await this.checkHans(text)}}>簡體字</button>
      </div>
      </>
  )
  }

  render() {
    this.init(this.props);
    return this.langRow();
  }

}

export default LangRow;
