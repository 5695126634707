import React from 'react';
import Row from './Row';

class MatchGameRow extends Row {

  constructor(props){
    super(props);
    this.init(props);
    const course = this.func.getById.course(this.props.matchGame.course, this.store);
    this.state = {
      filename: course? course.icon: null,
      type: 'courseIcon',
      //alert: this.func.checkAlert.subject(props.subject, this.app)
    }
    this.outDated = this.func.outDated(this.props.matchGame.endDate);
    this.checkUrl();
  }

  UNSAFE_componentWillReceiveProps(newProps){
    this.init(newProps);
    if(newProps.matchGame && !this.state.filename){
      const course = this.func.getById.course(newProps.matchGame.course, this.store);
      this.setState({
        filename: course.icon,
        type: 'courseIcon'
      }, ()=>{ this.checkUrl(); })
    }
  }

  rowInfo(){
    const matchGame = this.props.matchGame;

    const rowStyle = {...this.ui.styles.area, ...{
      width: '100%',
      height: this.bs.height * 0.06,
      alignItems: 'flex-end'
    }}
    return(
      <div style={rowStyle}>
        {this.textDisplay(matchGame.description, ['',''], '150%')}
      </div>
    )
  }

  render(){
    if(this.props.matchGame === null){ return null; }
    return this.animatedRow(this.content.bind(this), this.bs.height * 0.15)
  }

  content = (style)=>(
      <button onClick={this.props.onClick} style={{...this.rowStyle(), ...{
        height: style.height,
        opacity: style.opacity * this.outDated||this.props.matchGame.hide? 0.5:1
      }}}>
        {this.verGap('3%')}
        {this.props.matchGame.hide ? this.hiddenRowIcon(this.props.matchGame.hide) : this.rowIcon(this.outDated)}
        {this.rowContent(this.props.matchGame.title, this.rowInfo.bind(this))}
        {/*this.state.alert && this.alertTag()*/}
      </button>
  )

}

export default MatchGameRow;
