import React, { useEffect } from 'react';
import PlayMemoryGame from './ui/PlayMemoryGame';

function MemoryGamePlay(props) {
  const cards = props.app.store.memoryGames.viewingCards;

  useEffect(() => {
    // Loads when the game starts
  }, [cards]);

  
  const container = {
    width: '95%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
  }

  return (
    <div>
      <div style={container}>
      <PlayMemoryGame
        cards={cards}
        app={props.app}
        that={props.that}
      />
      </div>
    </div>
  )
}


export default MemoryGamePlay;
