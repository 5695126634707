import axios from 'axios';
import * as actions from '../actions';
import to from '../to';
var api = actions.api();

export const viewProject = (project) =>{
  return {
    type: 'viewProject',
    payload: project
  }
}

export const hideProject = (boolean) =>{
  return {
    type: 'hideProject',
    payload: boolean
  }
}

export const deleteIconAction = (boolean) =>{
  return {
    type: 'deleteProjectIconAction',
    payload: boolean
  }
}

export const pushDeletedIcon = (icon) =>{
  return {
    type: 'pushProjectDeletedIcon',
    payload: icon
  }
}

export const removeDeletedIcon = (icon) =>{
  return {
    type: 'removeProjectDeletedIcon',
    payload: icon
  }
}

export const setDeletedIcon = (icons) =>{
  return {
    type: 'setProjectDeletedIcon',
    payload: icons
  }
}

export const setProjectLangAudio = (audio) =>{
  return {
    type: 'setProjectLangAudio',
    payload: audio
  }
}

export const setTags = (tags, status) =>{
  return {
    type: 'setProjectTags',
    payload: {tags: tags, status: status}
  }
}

export const copy = (project) =>{
  return {
    type: 'setCopyProject',
    payload: project
  }
}


export function getAllProjectsOfCourse(course){
  return async function (dispatch){
    let err, res;
    [err, res] = await to(axios.post(api + '/project/getAllOfCourse', { data: course }));
    if(err){actions.connectionError(dispatch); return;}
    //console.log(res.data);
    if(res.data.result === 'success'){
      dispatch({type: 'updateCourseProjects', payload: res.data.projects})
      dispatch({type: 'hideModal'});
    }else{
      dispatch({type: 'message', payload: ['Failed to get subjects data!', '無法查閱議題資料!', '无法查阅议题资料!']});
    }
  }
}


export function deleteDefaultIcons(data){
  return async function(dispatch){
    actions.connecting(dispatch);

    let err, updateRes;

    [err, updateRes] = await to(axios.post(api + '/project/deleteDefaultIcon', { data: data}))
    if(err){actions.connectionError(dispatch); return;}

    if(updateRes.data.result === 'success'){
      dispatch({type: 'message', payload: ['Delete icons succeed!', '成功刪除照片!', '成功删除照片!']});
      dispatch({type: 'updateProjects', payload: [updateRes.data.editedProject]});
      dispatch({type: 'viewProject', payload: updateRes.data.editedProject});
      dispatch({type: 'hideModal'});
      //dispatch({type: 'pullView'});
    }else{
      dispatch({type: 'message', payload: ['Delete icons failed! Please try again!', '刪除失敗! 請再試一次!', '删除失败! 请再试一次!']});
    }
  }
}

export function addDefaultIcon(data){
  return async function (dispatch) {
    actions.connecting(dispatch);
    const project = data.project;
    const icon = data.icon;
    const iconAttr = data.iconAttr;
    const config = {
       onUploadProgress: progressEvent => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          percentCompleted = percentCompleted > 89? 90: percentCompleted;
          dispatch({type: 'loadingPercent', payload: percentCompleted});
          dispatch({type: 'loadingStatus', payload: 'on'});
       },
       headers: {type: 'projectDefaultIcon', rotation: iconAttr.rotation}
    };

    var iconFile = new FormData();
    iconFile.append('files', data.icon, 'projectDefaultIcon.png');
    /*
    if(!data.resubmit){

    }else if(data.newIcon){
      iconFile.append('files', data.newIcon, 'courseDefaultIcon.png');
    }
    */

    let err, uploadRes, updateRes;
    [err, uploadRes] = await to(axios.post(api + '/upload', iconFile, config))
    if(err){actions.connectionError(dispatch); return;}
    dispatch({type: 'loadingPercent', payload: 100});

    const filenames = uploadRes.data.filenames;
    var projectDefaultIcon = null;
    for(var j=0;j<filenames.length;j++){
      const splted = filenames[j].split('-');
      if(splted[1] === 'projectDefaultIcon.png'){
        projectDefaultIcon = filenames[j];
      }
    }

    [err, updateRes] = await to(axios.post(api + '/project/addDefaultIcon', { data: { project: project, defaultIcon: projectDefaultIcon?projectDefaultIcon:icon, }}))
    if(err){actions.connectionError(dispatch); return;}

    if(updateRes.data.result === 'success'){
      dispatch({type: 'message', payload: ['Submit card succeed!', '成功提交卡片!', '成功提交卡片!']});
      dispatch({type: 'updateProjects', payload: [updateRes.data.editedProject]});
      dispatch({type: 'viewProject', payload: updateRes.data.editedProject});
      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
      dispatch({type: 'pullView'});
    }else{
      //console.log(cardRes.data.result);
      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
      dispatch({type: 'message', payload: ['Submit card failed! Please try again!', '提交失敗! 請再試一次!', '提交失败! 请再试一次!']});
    }

  }
}

export function getRanking(projectId){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, res;
    [err, res] = await to(axios.post(api + '/project/getRanking', { data: projectId }));
    if(err){actions.connectionError(dispatch); return;}

    if(res.data.result === 'success'){
      dispatch({type: 'updatePRofiles', payload: res.data.profiles});
      dispatch({type: 'setRanking', payload: {projectId: projectId, ranking: res.data.ranking}});
      dispatch({type: 'hideModal'});
    }else{
      dispatch({type: 'message', payload: ['Failed to get project ranking!', '無法查閱專題研習排行榜!', '无法查阅专题研习排行榜!']});
    }
  }
}


export function getAllProjectsOfSchool(school){
  return async function (dispatch){
    let err, res;
    [err, res] = await to(axios.post(api + '/project/getAllOfSchool', { data: school }));
    if(err){actions.connectionError(dispatch); return;}

    if(res.data.result === 'success'){
      console.log(res.data.projects);
      dispatch({type: 'updateSchoolProjects', payload: res.data.projects})
      dispatch({type: 'hideModal'});
    }else{
      dispatch({type: 'message', payload: ['Failed to get subjects data!', '無法查閱議題資料!', '无法查阅议题资料!']});
    }
  }
}

export function getProjects(projects){
  return async function (dispatch) {
   // actions.connecting(dispatch);
    let err, res;
    [err, res] = await to(axios.post(api + '/project/getMultiple', { data: projects }));
    if(err){actions.connectionError(dispatch); return;}

    if(res.data.result === 'success'){
      dispatch({type: 'updateProjects', payload: res.data.projects});
    }else{
      dispatch({type: 'message', payload: ['Failed to get projects data!', '無法查閱專題研習資料!', '无法查阅专题研习资料!']});
    }

  }
}

export function editProject(editedProject){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, uploadRes, updateRes;
    const config = {
       onUploadProgress: progressEvent => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          percentCompleted = percentCompleted > 89? 90: percentCompleted;
          dispatch({type: 'loadingPercent', payload: percentCompleted});
          dispatch({type: 'loadingStatus', payload: 'on'});
       },
       headers: {type: 'project'}
    };

    var iconFile = new FormData();
    if(editedProject.newIcon){
      iconFile.append('files', editedProject.newIcon, 'projectIcon.png');
    }
    if(editedProject.audioDesc){
      iconFile.append('files', editedProject.audioDesc, 'projectAudioDesc.wav');
    }
    [err, uploadRes] = await to(axios.post(api + '/upload', iconFile, config));
    if(err){actions.connectionError(dispatch); return;}

    const filenames = uploadRes.data.filenames;
    for(var j=0;j<filenames.length;j++){
      const splted = filenames[j].split('-');
      if(splted[1] === 'projectIcon.png'){
        editedProject['icon'] = filenames[j];
      }else{
        editedProject['audioDesc'] = filenames[j];
      }
    }

    [err, updateRes] = await to(axios.post(api + '/project/edit', {data: editedProject}));
    if(err){actions.connectionError(dispatch); return;}
    dispatch({type: 'loadingPercent', payload: 100});

    if(updateRes.data.result === 'success'){
      dispatch({type: 'message', payload: ['Edit project succeed!', '成功修改專題研習!', '成功修改专题研习!']});
      dispatch({type: 'updateProjects', payload: [updateRes.data.editedProject]});
      dispatch({type: 'viewProject', payload: updateRes.data.editedProject});
      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
      dispatch({type: 'pullView'});
    } else {
      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
      actions.updateFailed(dispatch);
    }
  }
}

export function addProject(projectToAdd){
  //console.log(newProject)
  return async function (dispatch) {
    actions.connecting(dispatch);

    let projectToCopy, newProject;
    let err, uploadRes, addRes;

    ({projectToCopy, ...newProject} = projectToAdd); 
    const config = {
       onUploadProgress: progressEvent => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          percentCompleted = percentCompleted > 89? 90: percentCompleted;
          dispatch({type: 'loadingPercent', payload: percentCompleted});
          dispatch({type: 'loadingStatus', payload: 'on'});
       },
       headers: {type: 'project'}
    };

    var iconFile = new FormData();
    iconFile.append('files', newProject.icon, 'projectIcon.png');

    if(newProject.audioDesc){
      iconFile.append('files', newProject.audioDesc, 'projectAudioDesc.wav');
    }

    [err, uploadRes] = await to(axios.post(api + '/upload', iconFile, config));
    if(err){actions.connectionError(dispatch); return;}
    dispatch({type: 'loadingPercent', payload: 100});
    //console.log('File uploaded');

    if(uploadRes.data.result === 'success'){
      const filenames = uploadRes.data.filenames;
      for(var j=0;j<filenames.length;j++){
        const splted = filenames[j].split('-');
        if(splted[1] === 'projectIcon.png'){
          newProject['icon'] = filenames[j];
        }else{
          newProject['audioDesc'] = filenames[j];
        }
      }
    }else{
      actions.connectionError(dispatch);
      return;
    }

    [err, addRes] = await to(axios.post(api + '/project/add', { data: { project: newProject, projectToCopy: projectToCopy } }));
    if(err){actions.connectionError(dispatch); return;}

    //console.log(res.data);
    if(addRes.data.result === 'success'){
      dispatch({type: 'message', payload: ['Add project succeed!', '成功創建專題研習!', '成功创建专题研习!']});
      dispatch({type: 'updateProjects', payload: [addRes.data.newProject]});
      dispatch({type: 'updateTeachingProjects', payload: [addRes.data.newProject._id]});
      dispatch({type: 'updateSubjects', payload: [addRes.data.updatedSubject]});
      dispatch({type: 'viewSubject', payload: addRes.data.updatedSubject});
      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
      dispatch({type: 'pullView'});
      //dispatch({type: 'setPhoto', payload: {blob: null, url: null}});
    }else{
      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
      dispatch({type: 'message', payload: ['Add project failed! Please try again!', '創建失敗! 請再試一次!', '创建失败! 请再试一次!']});
    }
  }
}

export function swapProjects(project1, project2){
  return async function (dispatch) {
    //actions.connecting(dispatch);
    let err, res;
   
    [err, res] = await to(axios.post(api + '/project/swap', { data: { project1: project1, project2: project2 }}));
    
    if(err){actions.connectionError(dispatch); return;}

    if(res.data.result === 'success'){
      //dispatch({type: 'message', payload: ['Sucess'] });
    }else{
      dispatch({type: 'message', payload: ['Failed to get projects data!', '無法查閱專題研習資料!', '无法查阅专题研习资料!']});
    }

  }
}

  



