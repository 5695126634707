import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';
import ReactBarChart from 'components/main/items/graph/ReactBarChart';
import { Grid } from '@material-ui/core';
//import BarChart from 'components/main/items/graph/BarChart';

class PublishStatistics extends SubView {

  constructor(props){
    super(props);
    this.init(props);
    this.state = {
      width: this.store.ui.minWidth + 300 < this.store.ui.windowWidth ? this.store.ui.minWidth + 300 : this.store.ui.windowWidth,
      height: this.store.ui.minHeight - 100 < this.store.ui.windowHeight ? this.store.ui.minHeight - 100 : this.store.ui.windowHeight
    }
  }

  UNSAFE_componentWillReceiveProps(newProps){
    if(!this.stat){ this.stat = this.store.content.statistics[this.store.survey.viewingPublish._id]; }
  }

  componentDidMount(){ this.getPublishStatistic(); }

  getPublishStatistic(){
    if(!this.stat){ this.actions.survey.getPublishStatistics(this.store.survey.viewingPublish._id); }
  }

  statTextDisplay(text){ return this.textDisplay(text, ['95%',''], '100%', 'center'); }

  /*
  questionAnswerStat(question, index){
    if(question.type === 'text'){ return null; }

    const style = {...this.ui.styles.areaY, ...{
      width: this.bs.width,
      height: '',
      justifyContent: 'center',
      flexShrink: 0
    }}
    const title = 'Q.' + (index + 1)
    return (
      <div style={style} key={question._id}>
        {this.subTitle([title,title,title])}
        {this.sep()}
        {this.statTextDisplay(question.title)}

        {this.answerStat(question)}

        {this.gap(this.bs.height * 0.04)}
      </div>
    )
  }
  */
  
  questionAnswerStat(question, index){
    if(question.type === 'text'){ return null; }

    const title = 'Q.' + (index + 1) + ' ' + question.title;
    return (
      <div key={question._id}>
        {this.answerStat(question, title)}
      </div>
    )
  }

  groupByData(data){
    if(!data){ return [];}
    var newData = {xAxis: [], yAxis: []};
    for(var i=0;i<data.length;i++){
      newData['xAxis'].push(data[i].option);
      newData['yAxis'].push(data[i].value);
    }
    return newData;
  }

  answerStat(question, title){
    if(question.type === 'option'){
      const data = this.stat.answerOptionGraphData[question._id];
      return <ReactBarChart app={this.app} data={this.groupByData(data)} title={this.subTitle([title,title,title])} />
    }else if(question.type === 'text'){
      return null;
    }
  }

  render() {
    this.init(this.props);
    if(!this.stat){
      return(
        <div style={this.subViewStyle()}>
          {this.subTitle(['Loading...','載入中...','载入中...'])}
        </div>
      );
    }

    const questionnaire = this.func.getById.questionnaire(this.store.survey.viewingPublish.questionnaire, this.store);

    const containerStyle = {
      marginTop: '1%',
    }

    return(
      <div style={{...this.subViewStyle(), ...containerStyle}}>
        <Grid
        container
        spacing={2}
        style={{width: '95%'}}
        >
          {questionnaire.questions.map((q,i)=>{
            return (
              <Grid item xs={12} sm={12} lg={12} xl={12} key={i}>
                {this.questionAnswerStat(this.func.getById.question(q, this.store), i)}
              </Grid>
            )
          })}
        </Grid>
      </div>
    )
  }

}

export default PublishStatistics;
