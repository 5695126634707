import React from 'react';
import View from 'components/main/pages/home/views/View';
import ImagePicker from 'components/main/items/ImagePicker';

class Profile extends View {

  constructor(props){
    super(props);
    this.init(props);
    this.state = {
      modified: false,
      filename: this.store.profile.icon,
      type: 'profileIcon'
    }
    this.checkUrl();
  }

  componentDidMount(){
    const forceProfile = this.store.content.view === 'forceProfile';
    window.onpopstate  = (e) => {
      this.backToAgreement(forceProfile);
    }
  }

  backToAgreement(forceProfile){
    if(forceProfile){
      this.actions.agreement.setAgreement(null);
    }
  }

  realName(type, surnameTitle, firstnameTitle, defaultLastName, defaultFirstName){
    const container={
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: this.bs.width*0.7,
    }
    const inner={
      display: 'flex',
      flexDirection: 'row',
    }
    const text={
      width: this.bs.width*0.09,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      color: this.ui.colors.mlangGreen,
      fontSize: '100%',
      fontWeight: 'bold',
    }

    return(
      <div style={container}>
        <div style={inner}>
          <div style={text}>{this.func.multiLang(surnameTitle[0], surnameTitle[1], surnameTitle[2])}</div>
          <text style={{fontSize: 35}}> {defaultLastName}</text>
        </div>
        <div style={inner}>
          <div style={text}>{this.func.multiLang(firstnameTitle[0], firstnameTitle[1], firstnameTitle[2])}</div>
          <text style={{fontSize: 35}}> {defaultFirstName}</text>
        </div>
      </div>
    )
  }

  setRealName(type, surnameTitle, firstnameTitle, defaultLastName, defaultFirstName){
    const container={
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: this.bs.width*0.7,
    }
    const inner={
      display: 'flex',
      flexDirection: 'row',
    }
    const text={
      width: this.bs.width*0.09,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      color: this.ui.colors.mlangGreen,
      fontSize: '100%',
      fontWeight: 'bold',
    }

    return(
      <div style={container}>
        <div style={inner}>
          <div style={text}>{this.func.multiLang(surnameTitle[0], surnameTitle[1], surnameTitle[2])}</div>
          <div>{this.inputs.inputField('lastname'+type,'text','', defaultLastName, ()=>{ this.setState({modified: true})}, ['40%','60%'])}</div>
        </div>
        <div style={inner}>
          <div style={text}>{this.func.multiLang(firstnameTitle[0], firstnameTitle[1], firstnameTitle[2])}</div>
          <div> {this.inputs.inputField('firstname'+type,'text','', defaultFirstName, ()=>{ this.setState({modified: true})}, ['70%','60%'])} </div>
        </div>
      </div>
    )
  }

  render() {
    this.init(this.props);
    const profile = this.store.profile;
    const forceProfile = this.store.content.view === 'forceProfile';

    return(
      <div style={this.viewStyle()}>
        <div style={this.viewContentStyle()}>
          {this.gap(this.bs.height * 0.04)}

          {forceProfile && this.subTitle(['Please setup your profile!','請設定你的個人檔案!','请设定你的个人档案!'], this.bs.height * 0.03)}
          {forceProfile && this.sep()}
          {forceProfile && this.gap(this.bs.height * 0.04)}

          {this.subTitle(['Your photo','你的照片','你的照片'])}
          {this.sep()}
          <ImagePicker type={'profile'} defaultUrl={this.url.url} app={this.app} />
          {this.sep()}
          {this.gap(this.bs.height * 0.02)}

          {this.subTitle(['Nickname','暱稱','昵称'])}
          {this.sep()}
          {this.store.user.type === "student" &&  <text style={{fontSize: 35}}> {profile.name} </text>}
          {this.store.user.type !== "student" &&  this.inputs.inputField('name','text','', profile.name, ()=>{ this.setState({modified: true})})}
          {this.gap(this.bs.height * 0.02)}

          {this.subTitle(['Your real name (in chinese)','你的真實姓名 (中文)','你的真實姓名 (中文)'])}
          {this.sep()}
          {this.store.user.type === "student" && this.realName('Chi', ['姓氏', '姓氏', '姓氏'], ['名字', '名字', '名字'], profile.lastNameChi?profile.lastNameChi:'', profile.firstNameChi?profile.firstNameChi:'')}
          {this.store.user.type !== "student" && this.setRealName('Chi', ['姓氏', '姓氏', '姓氏'], ['名字', '名字', '名字'], profile.lastNameChi?profile.lastNameChi:'', profile.firstNameChi?profile.firstNameChi:'')}
          {this.gap(this.bs.height * 0.02)}

          {this.subTitle(['Your real name (in english)','你的真實姓名 (英文)','你的真實姓名 (英文)'])}
          {this.sep()}
          {this.store.user.type === "student" && this.realName('Eng', ['Last Name', 'Last Name', 'Last Name'], ['First Name', 'First Name', 'First Name'], profile.lastNameEng?profile.lastNameEng:'', profile.firstNameEng?profile.firstNameEng:'')}
          {this.store.user.type !== "student" && this.setRealName('Eng', ['Last Name', 'Last Name', 'Last Name'], ['First Name', 'First Name', 'First Name'], profile.lastNameEng?profile.lastNameEng:'', profile.firstNameEng?profile.firstNameEng:'')}
          {this.gap(this.bs.height * 0.02)}

          {this.subTitle(['Self introduction','自我介紹','自我介绍'])}
          {this.sep()}
          {this.gap(this.bs.height * 0.02)}
          {this.inputs.textArea('desc', '', profile.description, ()=>{ this.setState({modified: true})})}
          {this.gap(this.bs.height * 0.04)}

          {!forceProfile && this.lowerPart()}

          {!forceProfile && this.buttons.rectRed(['Confirm change','確定變更','确定变更'], ()=>{this.changing()})}
          {forceProfile && this.buttons.rectGreen(['Confirm','確定','确定'], ()=>{this.changing()})}

          {this.gap(this.bs.height * 0.08)}
        </div>
      </div>
    )
  }

  lowerPart(){
    const profile = this.store.profile;
    return(
      <div style={{...this.viewStyle(), height: this.bs.height * 0.5, flexShrink: 0}}>
        {this.subTitle(['Total submitted cards','卡片總數','卡片总数'])}
        {this.sep()}
        {this.textDisplay(profile.cardCount, ['50%', this.bs.height * 0.06], '150%', 'center')}
        {this.gap(this.bs.height * 0.02)}

        {this.subTitle(['Total featured cards','精選卡片總數','精选卡片总数'])}
        {this.sep()}
        {this.textDisplay(profile.featuredCount, ['50%',this.bs.height * 0.06], '150%', 'center')}
        {this.gap(this.bs.height * 0.02)}

        {this.subTitle(['Enter current password to change','輸入密碼以變更資訊','输入密码以变更资讯'])}
        {this.sep()}
        {this.inputs.inputField('pw','password','','')}
      </div>
    )
  }

  changing(){
 //   const forceProfile = this.store.content.view === 'forceProfile';
    const profile = this.store.profile;
    const newIconBlob = this.store.main.photoBlob;
    const user = this.store.user;
    const newDesc = document.getElementById('desc').value;
    //const status = this.state.modified? 'change': 'add';
    let data;
    if(user.type === "student")
    {
      data = {
        profile: profile,
        newIconBlob: newIconBlob
      };
    }
    else
    {
      const newName = document.getElementById('name').value;
      const newLastNameChi = document.getElementById('lastnameChi').value;
      const newFirstNameChi = document.getElementById('firstnameChi').value;
      const newLastNameEng = document.getElementById('lastnameEng').value;
      const newFirstNameEng = document.getElementById('firstnameEng').value;

      data = {
        profile: profile,
        newName: newName,
        newLastNameChi: newLastNameChi,
        newFirstNameChi: newFirstNameChi,
        newLastNameEng: newLastNameEng,
        newFirstNameEng: newFirstNameEng,
        newDesc: newDesc,
        newIconBlob: newIconBlob
      };
    }

    //console.log(newId)
    if(!newIconBlob && !profile.icon){
      return this.failedMessage(['Failed to edit! Icon is missing!', '變更失敗! 未有照片!', '变更失败! 未有照片!'])
    }
    //if(newName === ''){
  //    return this.failedMessage(['Failed to change! Name is missing!', '變更失敗! 請輸入名稱!', '变更失败! 请输入名称!'])
  //  }
    if(this.store.content.view !== 'forceProfile' && document.getElementById('pw').value !== user.pw){
        return this.failedMessage(['Failed to change! Please enter your password correctly!', '變更失敗! 請輸入正確的密碼!', '变更失败! 请输入正确的密码!'])
    }
    if(!this.state.modified && !newIconBlob){
      return this.failedMessage(['Failed to add! Nothing is modified!', '提交失敗!未作出更改!', '提交失败!未作出更改!'])
    }

   // if(!forceProfile && user.type === 'student' && user.id.includes('gsps') && profile.name !== newName ){
     // return this.failedMessage(['Failed to change! Change name function is currently disabled for student user!', '變更失敗! 學生帳號目前不能改變用戶名稱!', '变更失败! 学生帐号目前不能改变用戶名称'])
  //  }

    /*
    if(!newLastNameChi){
      return this.failedMessage(['Failed to '+ status +'! Last Name (in chinese) is missing!', '提交失敗! 未有姓氏(中文)!', '提交失败! 未有姓氏(中文)!']);
    }
    if(!newLastNameEng){
      return this.failedMessage(['Failed to '+ status +'! Last Name (in english) is missing!', '提交失敗! 未有姓氏(英文)!', '提交失败! 未有姓氏(英文)!']);
    }
    if(!newFirstNameChi){
      return this.failedMessage(['Failed to '+ status +'! First Name (in chinese) is missing!', '提交失敗! 未有名字(中文)!', '提交失败! 未有名字(中文)!']);
    }
    if(!newFirstNameEng){
      return this.failedMessage(['Failed to '+ status +'! First Name (in english) is missing!', '提交失敗! 未有名字(英文)!', '提交失败! 未有名字(英文)!']);
    }
    */

    if(!user.id || user.id === 'DefaultId'){ this.actions.profile.setProfile(data); this.actions.content.pushView('forceAccount'); }
    else { this.actions.profile.changeProfile(data); }

    /*
    this.actions.profile.changeProfile({
      profile: profile,
      newName: newName,
      newDesc: newDesc,
      newIconBlob: newIconBlob
    });
    */

  }

}

export default Profile;
