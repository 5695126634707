import { combineReducers } from "redux";
import main from './control/main';
import switches from './control/switches';
import content from './control/content';
import ui from './control/ui';
import modal from './control/modal';
import notices from './control/notices';

import user from './data/user';
import profile from './data/profile';
import agreement from  './data/agreement';
import schools from './data/schools';
import schoolTags from './data/schoolTags';
import courses from './data/courses';
import subjects from './data/subjects';
import profiles from './data/profiles';
import projects from './data/projects';
import studentProjects from './data/studentProjects';
import groups from './data/groups';

import survey from './data/survey';
import allowedComment from './data/allowedComment';
import comment from './data/comment';

import cards from './data/cards';
import langs from './data/langs';

import matchGames from './data/matchGames';
import memoryGames from './data/memoryGames';

import setting from './data/setting';
import access from './data/access';

const rootReducer = combineReducers({
  main: main,
  switches: switches,
  content: content,
  ui: ui,
  modal: modal,
  notices: notices,

  user: user,
  profile: profile,
  agreement: agreement,
  schools: schools,
  schoolTags: schoolTags,
  courses: courses,
  subjects: subjects,
  profiles: profiles,
  projects: projects,
  studentProjects: studentProjects,
  groups: groups,

  cards: cards,
  langs: langs,

  survey: survey,
  allowedComment: allowedComment,
  comment: comment,

  matchGames: matchGames,
  memoryGames: memoryGames,

  setting: setting,
  access: access
});

export default rootReducer;
