const accessReducer = (
    state = {
    }, action)=>{
    switch (action.type) {
      case 'setAccess':
        return action.payload;
      default:
        return state;
    }
  }
  
  export default accessReducer;
  