import UI from 'components/UI';

class Graph extends UI {

  constructor(props){
    super(props);
    this.init(props);

    //console.log(props.data);

    const xType =
    props.data[0] && props.data[0].date? 'date':
    props.data[0] && props.data[0].month? 'month':
    props.data[0] && props.data[0].student? 'student':
    props.data[0] && props.data[0].option? 'option':
    '';

    this.state = {
      xAxis:{
        type: xType
      },
      dateYear : null,
      monthYear: null,
      students: null,
      options: null
    }
  }

  componentDidMount(){ this.updateAxis(); }

  UNSAFE_componentWillReceiveProps(newProps){
    this.init(newProps);
    this.updateAxis();

  //  console.log(this.props.dateYear);
  }

  updateAxis(){
    this.setAxisData();
  }



  setAxisData(){
    var xAxisData = [];
    var yAxisData = [];
    var years = [];
    var students = [];
    var options = [];
    const type = this.state.xAxis.type;

    if(type === 'date'){
      years = this.getYearList(this.props.data, 'date');
      this.setState({dateYear:years});
      for(var i=0;i<years.length;i++){ yAxisData[i] = []; }
    }
    else if(type === 'month'){
      years = this.getYearList(this.props.data, 'month');
      this.setState({monthYear:years});
      for(var j=0;j<years.length;j++){ yAxisData[j] = []; }
    }
    else if(type === 'student'){
      students = this.getStudentList(this.props.data, this.props.profiles);
      this.setState({students:students});
    }
    else if(type === 'option'){
      options = this.getOptionList(this.props.data);
      this.setState({options:options})
    }
    else return;


    for(var x=0;x<this.props.data.length;x++){
      var date;
      if(type === 'date'){
        date = new Date(this.props.data[x].date);
        xAxisData.push(this.dateToName(date));
        for(var y=0;y<years.length;y++){
          if(years[y] === date.getFullYear()){ yAxisData[y].push(this.props.data[x].value); }
          else { yAxisData[y].push(0); }
        }
      }
      else if(type === 'month'){
        date = new Date(this.props.data[x].month);
        xAxisData.push(this.monthToName(date));
        for(var z=0;z<years.length;z++){
          if(years[z] === date.getFullYear()){ yAxisData[z].push(this.props.data[x].value); }
          else { yAxisData[z].push(0); }
        }
      }
      else if(type === 'student'){
        xAxisData.push(students[x]);
        yAxisData.push(this.props.data[x].value);
      }

      else if(type === 'option'){
        xAxisData.push(options[x]);
        yAxisData.push(this.props.data[x].value);
      }

    }

    this.setState({
      xAxisData: xAxisData,
      yAxisData: yAxisData
    })

  }

  dateToName(date){
    const day = date.getDate();
    const month = date.getMonth() + 1;
    return day + '/' + month;
  }

  monthToName(date){
    const month = date.getMonth();
    switch (month) {
      case 0:
        return 'Jan';
      case 1:
        return 'Feb';
      case 2:
        return 'Mar';
      case 3:
        return 'Api';
      case 4:
        return 'May';
      case 5:
        return 'Jun';
      case 6:
        return 'Jul';
      case 7:
        return 'Aug';
      case 8:
        return 'Sep';
      case 9:
        return 'Oct';
      case 10:
        return 'Nov';
      case 11:
        return 'Dec';
      default:
        return '';
    }
  }

  getYearList(data, type){
    var year = [];
    for(var i=0;i<data.length;i++){
      if(type === 'month'){
        var date = new Date(data[i].month);
        if(!year.includes(date.getFullYear())) {year.push(date.getFullYear());}
      }
      else if(type === 'date'){
        var _date = new Date(data[i].date);
        if(!year.includes(_date.getFullYear())) {year.push(_date.getFullYear());}
      }
    }
    return year;
  }

  getStudentList(data, profiles){
    var student = [];
    for(var i=0;i<data.length;i++){
      var found = false;
      for(var j=0;j<profiles.length && !found;j++){
        if(data[i].student === profiles[j].belongTo){
          student.push(profiles[j].name);
          found = true;
        }
      }
    }
    return student;
  }

  getOptionList(data){
    var option = [];
    for(var i=0;i<data.length;i++){
      if(!option.includes(data[i]).option){
        option.push(data[i].option);
      }
    }
    return option;
  }


}

export default Graph;
