
const commentReducer = (
  state = {
    editComments: [],
    comments: [],
    viewingComments: {},
    createdComments: [],
    createdSubmits: [],
  }, action)=>{
  const newEditComments = state.editComments.slice(0);
  switch (action.type) {
    case 'viewComment':
      return {...state, viewingComments: action.payload};
    case 'setEditComment':
      newEditComments[action.index] = action.payload;
      return {...state, editComments: newEditComments};
    case 'setEditComments':
      return {...state, editComments: action.payload};
    case 'killEditCommentsItem':
      newEditComments[action.payload].killed = true;
      return {...state, editComments: newEditComments};
    case 'removeEditCommentsItem':
      newEditComments.splice(action.payload,1);
      return {...state, editComments: newEditComments};
    case 'pushEditComments':
      return {...state, editComments: [...state.editComments, action.payload]};
    default:
      return state;
  }
}

export default commentReducer;
