import Cell from './Cell';

class FileCell extends Cell {

  constructor(props){
    super(props);
    this.init(props);
    this.state = {
      title: props.title,
      size: [this.bs.height * 0.2, this.bs.height * 0.225],
      margin: this.bs.height * 0.0125,
      icon: props.icon?props.icon:null,
      filename: props.data.icon?props.data.icon:null,
      type: props.type,
      status: 'init',
      alert: false
    }
    this.checkUrl();
  }

}

export default FileCell;
