import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';
import StudentInfoRow from 'components/main/items/rows/StudentInfoRow';

class AddGroup extends SubView {

  constructor(props){
    super(props);
    this.init(props);
    this.groupLeader = null;
    this.bs = this.store.ui.basicStyle;
    this.selectedStudentList = [];
  }

  componentDidMount(){
    this.setListScroll('studentList');
  }
  studentsList(){
    var students = this.store.courses.viewingCourse.joinedStudents;
    for(let i = 0; i < this.store.courses.courses.length; i++){
      if(this.store.courses.viewingCourse.code === this.store.courses.courses[i].code){
        students = this.store.courses.courses[i].joinedStudents;
        const profilesToGet = [];
        const profilesToShow = students;

        for(let j = 0;j < profilesToShow.length; j++){
          if(this.func.getById.profileByUser(profilesToShow[j], this.store) === null){
            profilesToGet.splice(0,0, profilesToShow[j]);
          }
        }
        if(profilesToGet.length > 0){
          this.actions.profiles.getProfiles(profilesToGet);
        }
      }
    }
    var availableStudents = [] ,selectedStudents = [], available = true;
    for(var i = 0; i < students.length; i++){
      const student = this.func.getById.profileByUser(students[i], this.store);
      available = true;
      for(var j = 0; j < this.store.groups.groups.length; j++){
        if(this.store.projects.viewingProject._id === this.store.groups.groups[j].project && this.store.groups.groups[j].members.indexOf(students[i]) > -1){
          available = false;
        }
      }
        if(available)
          availableStudents.push(student);
        
    }

    if(availableStudents.length > 1)
        availableStudents.sort((a, b) => {
        if(a !== null && b !== null)
          return a.name > b.name ? 1 : -1;
        else
          return true;
      });
    return  availableStudents.map((profile, i)=>{
      if(!profile){ return null; }
      return (
      <StudentInfoRow
      app={this.app}
      profile={profile}
      student={profile.belongTo}
      key={i}
      creatingGroup={true}
      handleCheck = {(selectedStudent) =>{
        const selectedCheckbox = document.getElementById("membercheckbox" + selectedStudent);
        if(!selectedCheckbox.checked){
          const checkbox = document.getElementById("leadercheckbox" + selectedStudent);
          checkbox.disabled = this.groupLeader !== null;
          selectedStudents.pop(selectedStudent);
        }
        else{
          const checkbox = document.getElementById("leadercheckbox" + selectedStudent);
          checkbox.disabled = true
          selectedStudents.push(selectedStudent);
        }
        this.selectedStudentList = selectedStudents;
      }
      }
      handleLeaderCheck = {(selectedStudent) =>{
          if(this.groupLeader === null){
            this.groupLeader = selectedStudent;
            const memberCheckbox = document.getElementById("membercheckbox" + this.groupLeader);
            memberCheckbox.disabled = true;
            for(let i = 0; i < this.store.courses.courses.length; i++){
              if(this.store.courses.viewingCourse.code === this.store.courses.courses[i].code){
                for(let j = 0; j < this.store.courses.courses[i].joinedStudents.length; j++){
                  if(selectedStudent !== this.store.courses.courses[i].joinedStudents[j]){
                    const checkbox = document.getElementById("leadercheckbox" + this.store.courses.courses[i].joinedStudents[j]);
                    if(checkbox !== null) checkbox.disabled = true;
                  }
                }
            }
          }
        }
          else{
            const memberCheckbox = document.getElementById("membercheckbox" + this.groupLeader);
            memberCheckbox.disabled = false;
            for(let i = 0; i < this.store.courses.courses.length; i++){
              if(this.store.courses.viewingCourse.code === this.store.courses.courses[i].code){
                for(let j = 0; j < this.store.courses.courses[i].joinedStudents.length; j++){
              if(selectedStudent !== this.store.courses.courses[i].joinedStudents[j]){
                const checkbox = document.getElementById("leadercheckbox" + this.store.courses.courses[i].joinedStudents[j]);
                if(checkbox !== null)  checkbox.disabled = document.getElementById("membercheckbox" + this.store.courses.courses[i].joinedStudents[j]).checked;
              }
                }
              }
            }
            this.groupLeader = null;
          }

      }
    }

      />)
    })
  }

  


  render(){

    this.init(this.props);
    return(
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", overflowY:"auto"}}>
        {this.subTitle(['Create group','創建小組','创建小组'], "2em")}
        {this.sep()}
        <input id={'groupName'} type={'text'} data-date-format={"DD MMMM YYYY"} placeholder={this.func.multiLang('Enter group name','輸入小組名稱','输入小组名称')} style={{ width: this.bs.width * 0.6,
        height: this.bs.height * 0.04,
        fontSize: this.bs.height * 0.03,
        margin: '2%',
        flexShrink: 0,}} />
        {this.sep()}
        {this.subTitle(['Students','學生','学生'], "2em")}
          <div id={'studentList'} onScroll={() => { this.onScroll('studentList');}} style={{...this.bs, ...this.ui.styles.list}}> 
            {this.studentsList()}
          </div> 
          {this.buttons.rectGreen(['Create group','創建小組','创建小组'], ()=>{ this.createGroup(); } )}
      </div>
    )
  }



  async createGroup(){
    const groupName = document.getElementById('groupName').value;
    if(groupName.length < 2){
      return this.failedMessage(['Group name must be at lease 2 characters long!', '小組名稱須至少由兩個字元組成!', '小组名称须至少由两个字元组成!'])
    }
    if(this.groupLeader === null){
      return this.failedMessage(['No group leader!', '沒有組長!', '没有组长!!'])
    }
    if(this.selectedStudentList.length === 0){
      return this.failedMessage(['No group member!', '沒有組員!', '没有组员!!'])
    }
    this.actions.groups.createGroup(this.groupLeader, this.store.projects.viewingProject._id, groupName, true);
    setTimeout(()=>{
      for(var i = 0; i < this.selectedStudentList.length; i++){
        this.joinGroup(this.selectedStudentList[i]);
      }
      this.actions.content.pullView();
    }, 800); 
  }

  joinGroup(student){
    const groupCode = this.store.groups.code;
    console.log(groupCode, " ", this.store.groups);
    if(groupCode !== null)
      this.actions.groups.joinGroup(student, groupCode);
  }

}
export default AddGroup;