import React from 'react';
import UI from 'components/UI';


class MatchGameTextRow extends UI {

  constructor(props){
    super(props);
    this.init(props);
  }

  UNSAFE_componentWillReceiveProps(newProps){
    this.init(newProps);
  }

  langRow(){
    const lang = this.props.lang;

    const rowStyle = {...this.bs, ...{
      width: '100%',
      height: this.props.single? this.bs.height * 0.37: this.bs.height * 0.105,
      backgroundColor: 'white',
      borderBottom: '4px solid ' + this.ui.colors.ultraLightGrey,
      flexShrink: 0
    }}

    return(
      <div style={rowStyle}>
        {this.langText(lang)}
        {this.gap('1%')}
      </div>
    )
  }


  updateAnswers(lang, index){
    var answers = this.store.matchGames.answers;
    var audio = null;
    if(answers[index].audio){ audio = answers[index].audio; }
    const answer = {audio: audio, text: lang._id};
    this.actions.matchGames.updateAnswers(answer, index);
  }

  langText(lang){

    const barStyle = {...this.ui.styles.area, ...{
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexShrink: 0,
    }}

    const style = {...this.ui.styles.button, ...{
      width: '95%',
      height: '100%',
      fontSize: '150%',
      overflow: 'auto',
      overflowWrap: 'break-word',
      textAlign: 'left',
    }}

    const checked = this.store.matchGames.answers[this.props.index].text === lang._id;

    return(
      <div style={barStyle}>
        {this.verGap('1%')}
        {this.inputs.radioButtonLang(lang, checked, ()=>{this.updateAnswers(lang, this.props.index);})}
        {this.verGap('20%')}
        <button style={style} onClick={()=>{this.actions.main.enlargeText(lang.text)}}>
          {lang.text}
        </button>
      </div>
    )
  }


  render() {
    this.init(this.props);
    return this.langRow();
  }

}

export default MatchGameTextRow;
