import React from 'react';
import {Bar} from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(0.5)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const ReactBarChart = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const groupedData = props.data;
  if(groupedData.xAxis <= 0 && groupedData.yAxis <= 0){ return null; }

  const data = {
    datasets: [
      {
        data: groupedData.yAxis,
        backgroundColor: theme.palette.success.main,
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      }
    ],
    labels: groupedData.xAxis
  };

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  return (
    <Card     
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={props.title}
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar
            data={data}
            options={options}
            redraw
          />
        </div>
      </CardContent>
    </Card>
  );
};

ReactBarChart.propTypes = {
  className: PropTypes.string
};

export default ReactBarChart;
