import React from 'react';
import Row from './Row';
//import mlanghku from 'resources/images/icons/mlanghku.png';
import FlipMove from 'react-flip-move';

class SubjectRow extends Row {

  constructor(props){
    super(props);
    this.init(props);
    this.state = {
      filename: this.props.subject? this.props.subject.icon: null,
      type: 'subjectIcon',
      alert: this.func.checkAlert.subject(props.subject, this.app)
    }
    this.subject = this.props.subject;
    this.index = this.props.index;
    this.len = this.props.len
    this.store.subjects.subjectRows.push(this);
    this.handleUp = this.props.handleUp;
    this.handleDown = this.props.handleDown;
    this.button = this.props.button;
    this.checkUrl();
  }

  UNSAFE_componentWillReceiveProps(newProps){
    this.init(newProps);
    if(newProps.subject && !this.state.filename){
      this.setState({
        filename: newProps.subject.icon,
        type: 'subjectIcon'
      }, ()=>{ this.checkUrl(); })
    }
  }

  rowInfo(){
    const subject = this.props.subject;

    const rowStyle = {...this.ui.styles.area, ...{
      width: '100%',
      height: this.bs.height * 0.06,
      alignItems: 'flex-end'
    }}
    return(
      <div style={rowStyle}>
        {this.textDisplay(subject.description, ['',''], '150%')}
      </div>
    )
  }


  changeUpBackground(e){
    e.target.style.background = 'rgba(0, 255, 0, 0.4)';
    let div = document.getElementById("div1" + this.subject._id);
    div.style.backgroundColor = "grey";
  }

  removeUpBackground(e){
    e.target.style.background = "green";
    let div = document.getElementById("div1" + this.subject._id);
    div.style.backgroundColor = "white";
  }

  changeDownBackground(e){
    e.target.style.background = 'rgba(255, 0, 0, 0.4)';
    let div = document.getElementById("div1" + this.subject._id);
    div.style.backgroundColor = "grey";
  }

  removeDownBackground(e){
    e.target.style.background = "red";
    let div = document.getElementById("div1" + this.subject._id);
    div.style.backgroundColor = "white";
  }
  render(){
    if(this.props.subject === null){ return null; }
    return this.animatedRow(this.content.bind(this), this.bs.height * 0.15); 
  }

  content = (style)=>(
    <div id={"div1" + this.subject._id} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "100%", position: 'relative' }}>
      <FlipMove duration={750} ease="linear" 
      enterAnimation={{
        from: {
          transform: "translateX(-100%)",
        },
        to: {}
      }}
      leaveAnimation={{
        from: {

        },
        to: { transform: "translateX(-100%)",
      }
      }}
      typeName={null}>
      <button  key={"btn" + this.subject._id}  onClick={this.props.onClick} style={{...this.rowStyle(), ...{
        height: style.height,
        opacity: style.opacity * this.subject.hide? 0.5: 1,
        width: (this.button && this.store.user.type === "teacher" )? (this.len === 1? "97%" : "88%") : "100%",
      }}}>
        {/*this.verGap('3%')*/}
        {/*this.hiddenRowIcon(this.subject.hide, this.props.subject.mlanghku? mlanghku: null)*/}
        {this.subject.hide ? this.hiddenRowIcon(this.props.subject.hide, this.store.content.cachedUrl[this.props.subject.icon])  : this.rowIcon( this.func.outDated(this.props.subject.endDate), this.store.content.cachedUrl[this.props.subject.icon])  }
        {/*this.rowIcon(false, this.props.subject.mlanghku? mlanghku: null)*/}
        {this.rowContent(this.props.subject.title, this.rowInfo.bind(this))}
        {this.state.alert && this.alertTag()}
      </button>
    </FlipMove>
      <div  style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", borderBottom:"1px solid rgb(219, 219, 219)", borderImage: "initial"}}>
       {this.button && this.store.user.type === "teacher"  && this.index !== 0 && <button type="button"  onMouseOver={(e)=>this.changeUpBackground(e)} onMouseLeave={(e)=>this.removeUpBackground(e)} style={{ backgroundColor: "green", color:"white", borderRadius: style.height * 0.05, cursor: "pointer", height: style.height * 0.3, width: style.height * 0.4 , margin:  style.height * 0.05, fontSize: style.height * 0.2, textAlign: "center", padding: 0}} onClick={() => this.handleUp(this.subject)}>⇧</button>}
       {this.button && this.len !== 1 && this.store.user.type === "teacher"  && (this.index !== this.len - 1) && <button type="button"  onMouseOver={(e)=>this.changeDownBackground(e)} onMouseLeave={(e)=>this.removeDownBackground(e)} style={{ backgroundColor: "red", color:"white",borderRadius: style.height * 0.05, cursor: "pointer", height: style.height * 0.3, width: style.height * 0.4 , margin:  style.height * 0.05, fontSize: style.height * 0.2, textAlign: "center", padding: 0}} onClick={() => this.handleDown(this.subject)}>⇩</button>}
      </div>
    </div>
  )

}

export default SubjectRow;