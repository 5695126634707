import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';
import Image from 'components/main/items/ui/Image';
import MultipleSelect from 'components/main/items/ui/MultipleSelect';
import * as TagTranslator from 'components/main/pages/home/helpers/TagTranslator.js';

class CourseDetail extends SubView {

  showJoinedTeachers(teachers){
    return teachers.map((teacher, i) => {
      const profile = this.func.getById.profileByUser(teacher, this.store);
      return (
        <div key={i}>
          {profile && this.detailText(profile.name)}
        </div>)
    })
  }

  multipleCheckBox(){
    const course = this.store.courses.viewingCourse;
    var defaultTag = course.tags?course.tags:[];
    defaultTag = TagTranslator.convert(defaultTag, 'english', this.store.main.language);
    return(
      <MultipleSelect app={this.app} choices={[]} defaultTag={defaultTag} openStatus={false} onChange={null} type={'course'} disableUnderline={true} />
    )
  }

  render() {
    this.init(this.props);
    const course = this.store.courses.viewingCourse;
    const joinedTeachers = course.joinedTeachers;
    const teacher = this.func.getById.profileByUser(course.teacher, this.store);

    return(
      <div style={this.subViewStyle()}>
        {this.gap('4%')}

        {this.subTitle(['Icon','照片','照片'])}
        {this.sep()}
        {this.gap('2%')}
        <Image app={this.app} filename={course.icon} type={'courseIcon'} size={this.bs.height * 0.22}/>
        {this.gap('2%')}

        {this.subTitle(['Teacher','老師','老师'])}
        {this.sep()}
        {this.detailText(teacher? teacher.name: '')}
        {joinedTeachers && this.showJoinedTeachers(joinedTeachers)}
        {this.gap('3%')}

        {this.subTitle(['Title','班名','班名'])}
        {this.sep()}
        {this.detailText(course.title)}
        {this.gap('3%')}

        {this.subTitle(['Tag', '標籤', '标籤'])}
        {this.sep()}
        {this.multipleCheckBox()}
        {this.gap('2%')}

        {this.subTitle(['Start date','創建於','创建于'])}
        {this.sep()}
        {this.detailText(this.func.dateString(new Date(course.createdAt)))}
        {this.gap('3%')}

        {this.subTitle(['End date','結束日期','结束日期'])}
        {this.sep()}
        {this.detailText(this.func.dateString(new Date(course.endDate)))}
        {this.gap('3%')}

        {this.subTitle(['Code','代碼','代码'])}
        {this.sep()}
        {this.detailText(course.code)}
        {this.gap('8%')}
      </div>
    )
  }

}

export default CourseDetail;
