import React from 'react';
import UI from 'components/UI';
import Sound from 'react-sound';

import icon_play from 'resources/images/buttons/buttonIcons/play_grey.png';
import icon_stop from 'resources/images/buttons/buttonIcons/stop_grey.png';

class MatchGameAudioRow extends UI {

  constructor(props){
    super(props);
    this.init(props);
    this.state = {
      filename: props.lang.audio,
      type: 'langAudio',
      isPlaying: false
    }
    //console.log(this.props.lang);
    //console.log(this.props.index);
    this.checkUrl();
  }

  UNSAFE_componentWillReceiveProps(newProps){
    this.init(newProps);
    const newFilename = newProps.lang.audio;
    if(this.state.filename !== newFilename){
      this.setState({ filename: newFilename, type: 'langAudio' })
      this.checkUrl();
    }
    if(!this.props.autoPlay && newProps.autoPlay && !this.state.isPlaying){
      this.setState({ isPlaying: true })
    }
  }

  playback(){
    if(this.state.isPlaying){ return; }
    this.setState({
      isPlaying: true
    })
  }

  onPlaybackEnd(){
    this.setState({
      isPlaying: false
    })
    if(this.props.autoPlay && this.props.onAutoPlayEnd){
      this.props.onAutoPlayEnd();
    }
  }

  stopPlayback(){
    if(!this.state.isPlaying){ return; }
    this.onPlaybackEnd();
  }

  langRow(){
    const lang = this.props.lang;

    const rowStyle = {...this.bs, ...{
      width: '100%',
      height: this.props.single? this.bs.height * 0.37: this.bs.height * 0.105,
      backgroundColor: 'white',
      borderBottom: '4px solid ' + this.ui.colors.ultraLightGrey,
      flexShrink: 0
    }}

    return(
      <div style={rowStyle}>
        {this.audio(lang)}
        {this.langBar(lang)}
        {this.gap('1%')}
      </div>
    )

  }

  audio(lang){
    if(this.state.isPlaying && this.url.url){
      return(
        <Sound
        url={this.url.url}
        playStatus={Sound.status.PLAYING}
        onFinishedPlaying={this.onPlaybackEnd.bind(this)}/>
      )
    }
    return null;
  }

  langBar(lang){
    const barStyle = {...this.ui.styles.area, ...{
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexShrink: 0
    }}

    const sizeSmall = [this.bs.width * 0.04,this.bs.width * 0.04];

    const checked = this.store.matchGames.answers[this.props.index].audio === lang._id;

    return(
      <div style={barStyle}>
        {this.verGap('1%')}
        {this.inputs.radioButtonLang(lang, checked, ()=>{this.updateAnswers(lang, this.props.index);})}
        {this.verGap('20%')}
        {this.url.url && !this.state.isPlaying && this.buttons.langBar(icon_play , 0.25, sizeSmall,()=>{this.playback()})}
        {this.url.url && this.state.isPlaying && this.buttons.langBar(icon_stop, 0.25, sizeSmall,()=>{this.stopPlayback()})}
      </div>
    )
  }

  updateAnswers(lang, index){
    var answers = this.store.matchGames.answers;
    var text = null;
    if(answers[index].text){ text = answers[index].text; }
    const answer = {audio: lang._id, text: text};
    this.actions.matchGames.updateAnswers(answer, index);
  }


  render() {
    this.init(this.props);
    return this.langRow();
  }

}

export default MatchGameAudioRow;
