import React from 'react';
import View from 'components/main/pages/home/views/View';

import SubNav from 'components/main/items/SubNav';
import MemoryGameStart from './subviews/MemoryGameStart';
import MemoryGamePlay from './subviews/MemoryGamePlay';
import MemoryGameDetail from './subviews/MemoryGameDetail';
import MemoryGameRanking from './subviews/MemoryGameRanking';
import MemoryGameBattleRoom from './subviews/MemoryGameBattleRoom';
import MemoryGameBattleRanking from './subviews/MemoryGameBattleRanking';


class MemoryGame extends View {


  componentDidMount(){
    this.init(this.props);
    const subView = this.store.content.subView;

    if(subView.includes('memoryGameRank')){
      this.actions.content.setSubView('memoryGameRank');
    }
    else{
      this.actions.content.setSubView('memoryGameDetail');
    }
  }

  subView(subView, animatedStyle){
    const app = {...this.app, ...{ animatedStyle: animatedStyle}}
    switch (subView) {

      case 'memoryGameStart':
        return <MemoryGameStart app={app}/>;
      case 'memoryGamePlay':
        return <MemoryGamePlay app={app} that={this}/>;
      case 'memoryGameRank':
        return <MemoryGameRanking app={app}/>;
      case 'memoryGameDetail':
        return <MemoryGameDetail app={app}/>;
      case 'memoryGameBattleRoom':
        return <MemoryGameBattleRoom app={app} that={this}/>;
      case 'memoryGameBattleRank':
        return <MemoryGameBattleRanking app={app}/>;
      default:
        return null;
    }
  }

  memoryGameSubNav(){
    const options = [
      {
        tag:['Game','遊戲','游戏'],
        subView: 'memoryGameStart'
      },
      {
        tag:['Ranking','排行榜','排行榜'],
        subView: 'memoryGameRank'
      },
      {
        tag:['Battle Ranking','對戰排行榜','对战排行榜'],
        subView: 'memoryGameBattleRank'
      },
      {
        tag:['Detail','詳細資訊','详细资讯'],
        subView: 'memoryGameDetail'
      }
    ]

    return <SubNav app={this.app} options={options} />
  }

  render(){
    this.init(this.props);
    const deadView = this.state.deadView;
    const view = this.state.view;
    return(
      <div style={this.viewStyle()}>
        {this.tabBar(['Memory match game', '記憶配對遊戲', '记忆配对游戏'])}
        {this.memoryGameSubNav()}
        {this.sep()}
        {this.animatedSubView(this.subView.bind(this), deadView? deadView: view, deadView? false: true)}
      </div>
    )
  }

}

export default MemoryGame;
