import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';
import Cards from 'components/main/pages/home/views/home/contents/Cards';

class ProjectDeleted extends SubView {

  componentDidMount(){
    this.init(this.props);
    this.getStudentDeletedCards();
  }

  getStudentDeletedCards(){
    this.actions.cards.getDeletedCards(this.store.user, this.store.studentProjects.viewingStudentProject);
  }

  cardCells(){
    const deletedCards = this.store.cards.deletedCards;

    if(deletedCards && deletedCards.length > 0){
      return <Cards app={this.app} cardsId={deletedCards} onAdd={null} recover={true}/>
    }else{
      //console.log('no viewingStudentProject cards!')
    }
  }

  render() {
    this.init(this.props);
    return(
      <div style={this.subViewStyle()}>
        {this.cardCells()}
      </div>
    )
  }

}

export default ProjectDeleted;
