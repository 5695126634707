export function convert(tags, fromLang, toLang){
    var data = [];
    for(var i=0; i<tags.length; i++){
      data.push(translateTag(tags[i], fromLang, toLang));
    }
    return data;
}

export function translateTag(tag, fromLang, toLang){
    var data = tag;
    if((fromLang === 'chinese' || fromLang === 'simplified_chinese') && toLang === 'english'){
      switch(tag){
        case '中文':
          data = 'Chinese';
          break;
        case '英文':
          data = 'English';
          break;
        case '數學':
        case '数学':
          data = 'Mathematics';
          break;
        case '常識':
        case '常识':
          data = 'General Studies';
          break;
        default:
          data = tag;
      }
    }
    else if(fromLang === 'english' && (toLang === 'chinese' || toLang === 'simplified_chinese')){
      switch(tag){
        case 'Chinese':
          data = '中文';
          break;
        case 'English':
          data = '英文';
          break;
        case 'Mathematics':
          data = toLang === 'chinese'? '數學': '数学';
          break;
        case 'General Studies':
          data = toLang === 'chinese'? '常識': '常识';
          break;
        default:
          data = tag;
      }
    }
    return data;
}