//import * as reducer from '../reducer';
import reducer from '../reducer';

const cardsReducer = (
  state = {
    audioBuffer: null,
    cards: [],
    viewAction: 'init',
    viewingCard: {},
    viewingCards: [],
    gradingCards: {},
    gradingCardsEditted: {},
    selectedGradeCard: {},
    teachingCards: [],
    joinedCards: [],
    deleteAction: false,
    recoverAction: false,
    selectedDeleteCards: [],
    selectedRecoverCards: [],
    deletedCards: {},
  }, action)=>{
  var _gradingCards = {...state.gradingCards};
  var _gradingCardsEditted = {...state.gradingCardsEditted};
  const newDeleteCards = state.selectedDeleteCards.slice(0);
  const newRecoverCards = state.selectedRecoverCards.slice(0);
  switch (action.type) {
    case 'updateJoinedCards':
        return {...state, joinedCards: reducer.updateElements(state.joinedCards, action.payload, true)};
    case 'updateTeachingCards':
        return {...state, teachingCards: reducer.updateElements(state.teachingCards, action.payload, true)};
    case 'setAction':
      return {...state, viewAction: action.payload};
    case 'viewCards':
      return {...state, viewingCards: action.payload};
    case 'updateCards':
      return {...state, cards: reducer.updateElements(state.cards, action.payload)};
    case 'selectGradeCard':
      return {...state, selectedGradeCard: action.payload};
    case 'saveGradeCard':
      _gradingCardsEditted[action.payload.studentProjectId] = false;
      return {...state, gradingCardsEditted: _gradingCardsEditted};
    case 'gradeCard':
      _gradingCards[action.payload.studentProjectId] = reducer.updateElements(state.gradingCards[action.payload.studentProjectId], [action.payload.gradeCard]);
      _gradingCardsEditted[action.payload.studentProjectId] = true;
      return {...state, gradingCards: _gradingCards, gradingCardsEditted: _gradingCardsEditted};
    //  _gradingCards[action.payload.studentProjectId][action.payload.index] = action.payload.gradeCard;
    //  return {...state, gradingCards: _gradingCards};
    case 'resetGradeCards':
      delete _gradingCards[action.payload];
      return {...state, gradingCards: _gradingCards};
    case 'gradeCards':
      _gradingCards[action.payload.studentProjectId] = [...action.payload.cards];
      return {...state, gradingCards: _gradingCards};
    case 'viewCard':
      return {...state, viewingCard: action.payload};
    case 'deleteAction':
      return {...state, deleteAction: action.payload};
    case 'recoverAction':
      return {...state, recoverAction: action.payload};
    case 'pushDeleteCards':
      return {...state, selectedDeleteCards:[...state.selectedDeleteCards, action.payload]};
    case 'pushRecoverCards':
      return {...state, selectedRecoverCards:[...state.selectedRecoverCards, action.payload]};
    case 'removeDeleteCard':
      for(var i=0;i<newDeleteCards.length;i++){
       // console.log(newDeleteCards[i]);
        if(action.payload._id === newDeleteCards[i]._id){
          newDeleteCards.splice(i, 1);
          return {...state, selectedDeleteCards: newDeleteCards};
        }
      }
      return {...state};
    case 'removeRecoverCard':
      for(var j=0;j<newRecoverCards.length;j++){
        if(action.payload._id === newRecoverCards[j]._id){
          newRecoverCards.splice(j, 1);
          return {...state, selectedRecoverCards: newRecoverCards};
        }
      }
      return {...state};
    case 'updateDeletedCards':
      return {...state, deletedCards: action.payload};
    case 'updateAudioBuffer': 
      return {...state, audioBuffer: action.payload};
    default:
      return state;
  }
}

export default cardsReducer;
