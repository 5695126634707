import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';
import RankingRow from 'components/main/items/rows/RankingRow';

class ProjectRanking extends SubView {

  constructor(props){
    super(props);
    this.init(props);
    this.getProjectRanking();
  }

  /*
  componentWillReceiveProps(newProps){
    this.init(newProps);
    this.projectId = this.store.projects.viewingProject._id;
    this.ranking = this.store.content.rankings[this.projectId];
  }
  */

  /*
  componentWillUnmount(){
    this.projectId = this.store.projects.viewingProject._id;
    this.store.content.rankings[this.projectId] = null;
  }
  */

  getProjectRanking(){
    this.projectId = this.store.projects.viewingProject._id;
    this.ranking = this.store.content.rankings[this.projectId];
    //console.log(this.store.content.rankings)
    if(!this.ranking){
      this.actions.projects.getRanking(this.projectId);
    }
  }
  rankingList(ranking){
    let cnt = 0;
    return ranking
    .sort((a, b) => b.score - a.score)
    .map((student,i)=>{
      let inCourse = false;
      const course = this.store.courses.viewingCourse
      if(student.student === course.teacher)
        inCourse = true;
      for(let i = 0; i < course.joinedStudents.length; i++)
        if(course.joinedStudents[i] === student.student)
          inCourse = true;
        for(let i = 0; i < course.joinedTeachers.length; i++)
          if(course.joinedTeachers[i] === student.student)
            inCourse = true;
      if(!inCourse)
      {
        cnt++;
        return <></>;
      }
      return <RankingRow key={i - cnt} app={this.app} data={student} index={i - cnt}/>
    })
  }

  render() {
    this.init(this.props);
    this.projectId = this.store.projects.viewingProject._id;
    this.ranking = this.store.content.rankings[this.projectId];

    return(
      <div style={this.subViewStyle()}>
        {this.ranking && this.rankingList(this.ranking)}
      </div>
    )
  }

}

export default ProjectRanking;
