import React, { useState, useEffect } from 'react';
import { useSpring, animated as a } from "react-spring";

import mlangIcon from 'resources/images/icons/mlang_green.png';


const FlipCard = ({
    id,
    game,
    play,
    style,
    flippedCount,
    setFlippedCount,
    flippedIndexes,
    setFlippedIndexes,
    isMountedRef,
    app,
  }) => {
    const [icon, setIcon] = useState(null);
    const [flipped, set] = useState(false);
    const {transform, opacity} = useSpring({
      opacity: flipped ? 1 : 0,
      transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
      config: {mass: 5, tension: 500, friction: 80},
    });

    const [movingBack, setMovingBack] = useState(false);
    const [props, setProps] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 1, tension: 350, friction: 30 } }));

    const calc = (x, y) => [-(y - 150 / 2) / 5, (x - 150 / 2) / 5, 1.1] 
    const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

    const card = {
        position: 'absolute',
        maxWidth: '150px',
        maxHeight: '150px',
        width: '90ch',
        height: '90ch',
        border: '1px solid white',
        borderRadius: '5px',
        boxShadow: '0px 10px 30px -5px rgba(0, 0, 0, 0.3)',
        cursor: play? 'pointer': 'auto',
        willChange: 'transform, opacity',
    }

    const displayBack = {
        backgroundSize: '150px 150px',
        backgroundImage: `url(${mlangIcon})`,
        backgroundPosition: 'center',
        opacity: style.cardBackOpacity
    }

    const displayFront = {
        backgroundSize: 'cover',
        opacity: style.cardFrontOpacity,
        overflow: 'hidden'
    }

    const back = {
        backgroundSize: '150px 150px',
        backgroundImage: `url(${mlangIcon})`,
        backgroundPosition: 'center',
    }

    const front = {
        backgroundSize: 'cover',
    }

    const movingBackStyle = {...card, ...back, ...{
        transform: props.xys.interpolate(trans)
    }};

    const flippingBackStyle = {...card, ...back, ...{
        opacity: opacity.interpolate(o => 1 - o),
        transform,
    }};

    const flippingFrontStyle = {...card, ...front, ...{
        opacity,
        transform: transform.interpolate(t => `${t} rotateX(180deg)`),
        overflow: 'hidden'
    }};
  
    useEffect(() => {
        if (flippedIndexes[2] === true && flippedIndexes.indexOf(id) > -1) {
            setTimeout(() => {
                if(isMountedRef.current){
                    set(state => !state)
                    setFlippedCount(flippedCount + 1)
                    setFlippedIndexes([])
                }
            }, 1000)
        } 
        else if (flippedIndexes[2] === false && id === 0) {
            if(isMountedRef.current){
                setFlippedCount(flippedCount + 1)
                setFlippedIndexes([])
            }
        }
        // eslint-disable-next-line
    }, [flippedIndexes])

    useEffect(() => {
        let isMounted = true;
        async function getIcon(){
            if(game[id].icon){
                const url = await app.functions.url(game[id].icon, 'cardIcon');
                setIcon(url);
            }
        }
        if(isMounted){
            getIcon();
        }
        return () => { isMounted = false; }
        // eslint-disable-next-line
    }, [game])
  
    const onCardClick = () => {
        if (!game[id].flipped && flippedCount % 3 === 0) {
            set(state => !state)
            setFlippedCount(flippedCount + 1)
            const newIndexes = [...flippedIndexes]
            newIndexes.push(id)
            setFlippedIndexes(newIndexes)
        } 
        else if (flippedCount % 3 === 1 && !game[id].flipped && flippedIndexes.indexOf(id) < 0){
            set(state => !state)
            setFlippedCount(flippedCount + 1)
            const newIndexes = [...flippedIndexes]
            newIndexes.push(id)
            setFlippedIndexes(newIndexes)
        }
    }
    
    return (
        <div onClick={() => { 
                if(play){
                    onCardClick(); 
                    if(flipped && icon){ app.actions.main.enlargeImage(icon); } 
                    if(flipped && game[id].lang){ app.actions.main.enlargeText(game[id].lang.text); 
                }
            }}}>
            <a.div 
                style={play? flippingFrontStyle: {...card, ...displayFront}}
            >
            {game[id].icon && icon? <img src={icon} alt={icon} width={'150px'} height={'150px'}/>: null}
            {game[id].lang ? <h3 width={'150px'} height={'150px'}>{game[id].lang.text}</h3>: null}
            </a.div>
            <a.div
                style={play? movingBack && !flipped? movingBackStyle: flippingBackStyle: {...card, ...displayBack}}
                onMouseMove={(e) => { 
                    const bounds = e.target.getBoundingClientRect();
                    const x = e.clientX - bounds.left;
                    const y = e.clientY - bounds.top;
                    setMovingBack(true); setProps({ xys: calc(x, y) }); 
                }}
                onMouseLeave={() => { setMovingBack(false); setProps({ xys: [0, 0, 1]}); }}
            />
        </div>
    )
  }

  export default FlipCard;
