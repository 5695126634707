import React from 'react';
import UI from 'components/UI';

import MatchGameAudioRow from './MatchGameAudioRow';
import MatchGameTextRow from './MatchGameTextRow';

class MatchGameLangs extends UI {

  constructor(props){
    super(props);
    this.state={
      langs:[]
    }
  }

  componentDidMount(){
    this.init(this.props);
    this.getLangs(this.props);
  }

  UNSAFE_componentWillReceiveProps (newProp){
    //this.init(this.props);
    //this.getLangs(newProp);
  }


  getLangs(props){
    const _langsId = props.langChoices;
    const langsId = this.randomLang(_langsId);
    var _langs = [];
    for(var i=0;i<langsId.length;i++){
      _langs.splice(0,0, this.func.getById.lang(langsId[i], this.store))
    }
    this.setState({
      langs: _langs
    })
  }

  audioRows(langs){
    const single = langs.length === 1? true:false;
    return(
      langs ? langs.slice(0).reverse().map((lang, i)=>{
        return <MatchGameAudioRow key={i} app={this.props.app} lang={lang} single={single} index={this.props.index}/>
      }) : null
    )
  }

  textRows(langs){
    return(
      langs ? langs.slice(0).reverse().map((lang, i)=>{
        return <MatchGameTextRow key={i} app={this.props.app} lang={lang} index={this.props.index}/>
      }) : null
    )
  }

  randomLang(langs){
    var _langs = [], numbers = [];
    while(numbers.length < langs.length){
      const rand = Math.floor(Math.random() * langs.length);
      if(!numbers.includes(rand)){
        numbers.push(rand);
        _langs.push(langs[rand]);
      }
    }
    return _langs;
  }

  render() {
    this.init(this.props);

    const langsStyle = {...this.bs, ...this.ui.styles.list, ...{
      width: this.bs.width * 0.65,
      height: this.bs.height * 0.5,
    }}

    return(
      <div style={langsStyle}>
        {this.props.langType === 'audio' ? this.audioRows(this.state.langs) : this.textRows(this.state.langs)}
      </div>
    )
  }

}

export default MatchGameLangs;
