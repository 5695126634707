import React from 'react';
import UI from 'components/UI';
import {Motion, spring}  from 'react-motion';

import PickerOption from './PickerOption';
import FloatingActionButton from 'components/main/items/ui/FloatingActionButton';

import * as SchoolTagHelper from 'components/main/pages/home/helpers/SchoolTagHelper.js';

class PrefabPicker extends UI {

  constructor(props){
    super(props);
    this.init(props);
    this.state = {
      pointedCell: null,
      rowToShow: 10
    }
  }

  UNSAFE_componentWillReceiveProps(newProps){
    this.init(newProps);
    const status = this.store.main.prefabPicker;
    const type = status === 'comments'? 'cards': status;
    const language = this.store.main.language;
    if(type === this.state.type){ return; }
    if(type === 'subjects'){
      const subjectTags = SchoolTagHelper.filter(this.store.schoolTags.schoolTags, this.store.courses.viewingCourse.tags, 'subject');
      this.tags = language === 'english'? [...subjectTags, 'Others']:  [...subjectTags, '其他'];
      this.setState({ type: type });
      this.setListScroll('schoolSubjectsList');
      this.actions.subjects.getAllSubjectsOfSchool(this.store.schools.schools[0]);
      this.actions.main.setChosenTag(this.tags[0]);
    }
    else if(type === 'projects'){
      const projectTags = SchoolTagHelper.filter(this.store.schoolTags.schoolTags, this.store.courses.viewingCourse.tags, 'project');
      this.tags = language === 'english'? [...projectTags, 'Others']: [...projectTags, '其他'];
      this.setState({ type: type });
      this.setListScroll('schoolProjectsList');
      this.actions.projects.getAllProjectsOfSchool(this.store.schools.schools[0]);
      this.actions.main.setChosenTag(this.tags[0]);
    }
  }

  addRowToShow(value){
     this.setState({...this.state, ...{ rowToShow: this.state.rowToShow + value }});
  }

  filterTag(type, tag, status){
    var originalData = this.store[type[0]][type[1]].filter((item)=>{ 
      if(tag === '其他' || tag === 'Others'){
        if(!item.tags || item.tags.indexOf(tag) < 0) return item;
      }
      else{
        if(!item.tags) return null;
        if(item.tags.indexOf(tag) > -1) return item;
      }
      return null;
    });
    return this.removeDuplicates(originalData.slice(0, this.state.rowToShow), status);
  }

  prefabs(){
    const prefabsStyle = {...this.ui.styles.container, ...this.ui.styles.area, ...{
      width: this.bs.width,
      height:this.bs.height * 0.45,
      overflowX: 'scroll',
      justifyContent: 'flex-start',
    }}
    const prefabsStyleY = {...this.ui.styles.container, ...this.bs, ...{
      width: this.bs.width,
      height: '',
      overflowY: 'scroll',
      justifyContent: 'center'
    }}

    const status = this.store.main.prefabPicker;
    const tag = this.store.main.chosenTag;
    if(status === 'off'){ return null;}
    var type = status === 'comments'? 'cards': status;
    const scrollId = type === 'subjects'? 'schoolSubjectsList': type === 'projects'? 'schoolProjectsList': type;
    var data = null;

    if(type === 'subjects'){
      data = this.filterTag([type, 'schoolSubjects'], tag, status);
    }
    else if(type === 'projects'){
      data = this.filterTag([type, 'schoolProjects'], tag, status);
    }
    else { data = this.removeDuplicates(this.store[type][type].slice(0), status); }

    return(
      <div id={scrollId} style={status === 'comments'? prefabsStyleY: prefabsStyle} onScroll={()=>{ this.onScroll(scrollId, ()=>{ this.addRowToShow(5); }, true ); }}>
        {this.verGap('8%')}
        {data.map((item, i)=>{
          return(
            <PickerOption
            key={i} index={i} app={this.app}
            data={item} type={this.getTypeName(status)}
            onPointed={()=>{ this.setState({pointedCell: item}); }} onUnPointed={()=>{ this.setState({pointedCell: null}); }}/>
          )
        })}
        {this.verGap('8%')}
      </div>
    )
  }

  removeDuplicates(data, status){
    for(var i=0;i<data.length;i++){
      for(var j=0;j<data.length;j++){
        if(!data[i]){ continue; }
        if(!data[j]){ continue; }
        if(status === 'comments'){
          if(!data[i].comment){
            data.splice(i, 1);
            i--;
          }else if(data[i].comment.length === 0){
            data.splice(i, 1);
            i--;
          }
        }

        if(status !== 'comments' &&
          i !== j &&
          data[i].title === data[j].title &&
          data[i].description === data[j].description){
          data.splice(i, 1);
          i--;
        }

        if(data[i] && data[i].mlanghku){
          data.splice(i, 1);
          i--;
        }
      }
    }
    return data;
  }

  getTypeName(status){
    switch (status) {
      case 'subjects':
        return 'subjectIcon'
      case 'projects':
        return 'projectIcon'
      case 'comments':
        return 'cardComment'
      default:
        return ''
    }
  }

  pickerText(text){
    const textStyle = {
      fontSize: this.bs.height * 0.055,
      color: 'white',
      height: this.bs.height * 0.065,
      overflow: 'hidden'
    }
    return(
      <div style={textStyle}>
        {text}
      </div>
    )
  }

  setFloatingButton(){
    const container = {
      position: 'absolute',
      bottom: 0,
      right: 0,
    }

    return (
      <div style={container}>
        {<FloatingActionButton app={this.app} tags={this.tags} />}        
      </div>
    )
  }

  render() {
    this.init(this.props);
    const status = this.store.main.prefabPicker;
    //console.log(this.buttons.bs)
    const isOpen = status !== 'off';

    const pickerStyle = {...this.bs, ...this.ui.styles.container, ...{
      position: 'absolute',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,0.95)',
      pointerEvents: isOpen? 'auto': 'none',
    }}

    return(
      <Motion defaultStyle={{opacity: this.atHome? 0: isOpen?0:1.5}}
      style={{opacity: this.atHome? 0: isOpen?spring(1.5):spring(0)}}>
        {style=>(
          <div style={{...pickerStyle, ...{opacity: style.opacity}}}>
            {status !== 'comments' && this.pickerText(this.store.main.chosenTag)}
            {this.buttons.absoluteClose(()=>{this.actions.main.setPrefabPicker('off'); this.setState({type: null, rowToShow: 10}); })}
            {status !== 'comments' && this.state.pointedCell && this.pickerText(this.state.pointedCell.title)}
            {this.prefabs()}
            {status !== 'comments' && this.state.pointedCell && this.pickerText(this.state.pointedCell.description)}
            {status !== 'comments' && this.setFloatingButton()}
          </div>
        )}
      </Motion>
    )
  }

}

export default PrefabPicker;
