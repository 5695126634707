import React from 'react';
import UI from 'components/UI';
import {Motion, spring, presets}  from 'react-motion';
import Sound from 'react-sound';

import Image from 'components/main/items/ui/Image';
import MatchGameLangs from './langs/MatchGameLangs';


class MatchGameCard extends UI {

  constructor(props){
    super(props);
    this.init(props);
    this.state = {
      checkingRead: '',
      filename: props.card.audioComment,
      type: 'audioComment',
      playAudioComment: false,
      onUserComment: false
    }
    this.checkUrl();
  }

  isReading(state){
    return state.includes('in') || state.includes('far') || state === '';
  }

  UNSAFE_componentWillReceiveProps(newProps){
    if(this.isReading(newProps.state) && this.state.checkingRead !== newProps.card._id){
      this.checkStudentRead(newProps.state, newProps.card);
    }
    if(newProps.card.audioComment !== this.state.filename){
      this.setState({ filename: newProps.card.audioComment}, ()=>{ this.checkUrl(); })
    }
  }

  checkStudentRead(state, card){
    if(card.author === this.store.user._id &&
      card.grade !== 'notGraded' &&
      !card.studentRead){
      this.actions.cards.studentReadCard(card._id);
      this.setState({ checkingRead: card._id })
    }
  }

  cardUpper(card){
    const style = {...this.ui.styles.area, ...this.ui.styles.container, ...{
      height: '47%'
    }}
    return(
      <div style={style}>
        {this.verGap(this.bs.height * 0.04)}
        <Image app={this.app} filename={card.icon} type={'cardIcon'} size={this.bs.width * 0.35} height={this.bs.height * 0.3} />
        {this.verGap(this.bs.height * 0.1)}
      </div>
    )
  }


  cardLower(card, langType){
    const style = {...this.ui.styles.area, ...this.ui.styles.container, ...{
      height: '53%',
    }}
    return(
      <div style={style}>
        {<MatchGameLangs app={this.app} card={card} langChoices={this.props.langChoices} index={this.props.index} langType={langType} /> }
      </div>
    )
  }


  audioPlayer(){
    if(!this.state.playAudioComment){ return null; }
    const url = this.store.content.cachedUrl[this.state.filename];
    return (
      <Sound
      url={url? url:''}
      playStatus={Sound.status.PLAYING}
      onFinishedPlaying={this.toggleAuidioComment.bind(this)}/>
    )
  }

  render(){
    this.init(this.props);
    const card = this.props.card;

    const containerStyle = {...this.ui.styles.container, ...{
      width: '100%',
      height: '100%',
      flexShrink: 0,
      position: 'absolute',
      overflow: 'auto'
    }}
    const cardStyle = {...this.ui.styles.border, ...this.bs, ...{
      width: this.bs.height * 0.95,
      height: this.bs.height * 0.8,
      position: 'relative',
      flexShrink: 0,
      borderStyle: 'none',
    }}

    const state = this.props.state;
    const startLeft =
    state === 'inLeft'? -this.bs.width:
    state === 'inRight'? this.bs.width:
    state === 'farToLeft'? this.bs.width:
    state === 'farToRight'? -this.bs.width:
    0;
    const endLeft =
    state === 'outLeft'? -this.bs.width:
    state === 'outRight'? this.bs.width:
    state === 'farToLeft'? -this.bs.width:
    state === 'farToRight'? this.bs.width:
    0;

    const incoming = state.includes('in') || state === '';
    const far = state.includes('far');

    return(
      <Motion key={this.props.card._id + state} defaultStyle={{left: startLeft, opacity: far? 1: incoming? 0:1.1}}
      style={{left: spring(endLeft, {...presets.noWobby, ...{stiffness: far? 50:170}}), opacity: far? 1: incoming? spring(1.1):spring(0)}}>
        {style=>(
          <div style={{...containerStyle, ...{left: style.left, opacity: style.opacity}}}>
            <div style={cardStyle}>
              {this.cardUpper(card)}
              {(this.props.viewingMatchGamePublish.type === 'audio' || this.props.viewingMatchGamePublish.type === 'both') && this.gap('5%')}
              {(this.props.viewingMatchGamePublish.type === 'audio' || this.props.viewingMatchGamePublish.type === 'both') && this.cardLower(card, 'audio')}
              {this.gap('5%')}
              {(this.props.viewingMatchGamePublish.type === 'text' || this.props.viewingMatchGamePublish.type === 'both') && this.cardLower(card, 'text')}
            </div>
            {this.audioPlayer()}
          </div>
        )}
      </Motion>
    )
  }

  toggleAuidioComment(){
    this.setState({ playAudioComment: !this.state.playAudioComment })
  }
}

export default MatchGameCard;
