import React from 'react';
import UI from 'components/UI';

import no_image from 'resources/images/general/no_image.png';
import CanvasGraphic from 'components/main/items/ui/CanvasGraphic';
import * as AnnotationHelper from 'components/main/pages/home/helpers/AnnotationHelper.js';

class Image extends UI {

  constructor(props){
    super(props);
    this.init(props);
    this.state = {
      filename: props.filename,
      type: props.type,
      image: null
    }
    this.checkUrl();
    this.loadImage();
  }

  UNSAFE_componentWillReceiveProps(newProps){
    this.init(newProps);
    const newFilename = newProps.filename;
    if(this.state.filename !== newFilename){
      this.setState({ filename: newFilename }, ()=>{ this.checkUrl(); this.loadImage(); })
    }
    this.checkUrl();
    this.loadImage();
  }

  componentDidMount(){
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this.image){
      this.image.removeEventListener('load', this.handleLoad);
    }
  }

  handleLoad = (e) => {
    const width =  this.props.scale? this.props.scale[0] * 0.60: this.image.width > this.props.size? this.props.size: this.image.width;
    const height = this.props.scale? this.props.scale[1] * 0.75: this.image.height > this.props.height? this.props.height: width;
    this.image.width = width;
    this.image.height = height;

    if(this._isMounted){
      this.setState({
        image: this.image
      });
    }
  }

  loadImage(){
    this.image = new window.Image();
    this.image.src = this.props.photoUrl? this.props.photoUrl: ((this.url.url !== undefined && this.url.url !== null) ? this.url.url : no_image);
    this.image.addEventListener('load', (e) => this.handleLoad(e));
  }


  render(){
    this.init(this.props);
    const url = this.props.photoUrl? this.props.photoUrl: this.url.url;
    //console.log(url);
    const size = this.props.size? this.props.size: '100%';
    const scale = this.props.scale;
    const height = this.props.height? this.props.height: null;
    var newWidth, newHeight, texts = [], lines = [], ellipses = [], stars = [], ticks = [], crosses = [];

    var containerStyle = {...this.ui.styles.container, ...{
      width: this.props.size? size: scale[0],
      height: this.props.height? height: this.props.size? size: scale[1],
      backgroundColor: this.props.backgroundColor? this.props.backgroundColor: 'white',
    }}
    if(!this.props.noBorder){
      containerStyle = {...this.ui.styles.border, ...containerStyle}
    }
    const imgBg = url? null: no_image;
    const imgSize = this.props.size? size * 0.95: '95%';
    const backgroundStyle = {...this.ui.styles.container, ...{
      width: imgSize,
      height: this.props.height? height * 0.95: imgSize,
      backgroundImage: 'url(' + imgBg + ')',
    }}
    
    const buttonStyle = {...this.ui.styles.button, ...{
      width: this.props.size? size: scale[0],
      height: this.props.height? height: this.props.size? size: scale[1],
      position: 'absolute',
      opacity: 0
    }} 
    

    if(this.state.image){ 
      newWidth = this.state.image.width;
      newHeight = this.state.image.height;
      [texts, lines, ellipses, stars, ticks, crosses] = AnnotationHelper.splitAnnotations(newWidth, newHeight, this.props.card? this.props.card.annotations: []);
    }
    console.log(this.props.card);
    return(
      <div key={url} style={containerStyle}>
        <div style={backgroundStyle}>
          {this.props.card && this.state.image &&
            <CanvasGraphic app={this.app} defaultImage={{image: this.state.image, rotation: this.store.main.photoAttr.rotation}} maxWidth={size} maxHeight={height? height:size} texts={texts} lines={lines} ellipses={ellipses} stars={stars} ticks={ticks} crosses={crosses}/>}
          {!this.props.card && 
              <img style={{maxWidth: size, maxHeight: height? height:size, transform: 'rotate('+this.store.main.photoAttr.rotation+'deg)'}} src={url} alt=''/>
          }
          { url !== null && this.buttons.button(buttonStyle, ['',''], '',this.props.noEnlarge? null:()=>{this.onImageClick()})} 
        </div>
      </div>
    )
  }


  onImageClick(){
    const disable = this.props.disable ? this.props.disable : false;
    if(!this.props.photoUrl && !this.url.url){ return; }
    if(disable){ return; }
    this.actions.main.enlargeImage(this.props.photoUrl? this.props.photoUrl: this.url.url, this.props.card);
  }

}

export default Image;
