//import * as reducer from '../reducer';
import reducer from '../reducer';

const memoryGameReducer = (
  state = {
    memoryGamePublishes: [],
    viewingMemoryGamePublish: {},
    memoryGameSubmits: [],
    viewingMemoryGameSubmit: {},
    memoryGameBattleSubmits: [],
    viewingMemoryGameBattleSubmit: {},
    viewingCards: [],
    answers: [],
    rankings: {},
    battleRankings: {},
    battleRoomWaitingUsers: [],
    battleRoomInfo: {},
    battleRoomScore: {},
    mode: 'practice',
  }, action)=>{
  var rankings = state.rankings;
  var battleRankings = state.battleRankings;
  switch (action.type) {
    case 'updateMemoryGamePublishes':
      return {...state, memoryGamePublishes: reducer.updateElements(state.memoryGamePublishes, action.payload)};
    case 'viewMemoryGamePublish':
      return {...state, viewingMemoryGamePublish: action.payload};
    case 'updateMemoryGameSubmits':
      return {...state, memoryGameSubmits: reducer.updateElements(state.memoryGameSubmits, action.payload)};
    case 'viewMemoryGameSubmit':
      return {...state, viewingMemoryGameSubmit: action.payload};
    case 'updateMemoryGameBattleSubmits':
      return {...state, memoryGameBattleSubmits: reducer.updateElements(state.memoryGameBattleSubmits, action.payload)};
    case 'viewMemoryGameBattleSubmit':
      return {...state, viewingMemoryGameBattleSubmit: action.payload};
    case 'viewMemoryGameCards':
      return {...state, viewingCards: action.payload};
    case 'setMemoryGameRanking':
      rankings[action.payload.memoryGameId] = action.payload.ranking;
      return {...state, rankings: rankings};
    case 'setMemoryGameBattleRanking':
      battleRankings[action.payload.memoryGameId] = action.payload.battleRanking;
      return {...state, battleRankings: battleRankings};
    case 'setBattleRoomWaitingUsers':
      return {...state, battleRoomWaitingUsers: action.payload};
    case 'setBattleRoomInfo':
      return {...state, battleRoomInfo: action.payload};
    case 'setBattleRoomScore':
      return {...state, battleRoomScore: action.payload};
    case 'setMemoryGameMode':
      return {...state, mode: action.payload}
    default:
      return state;
  }
}

export default memoryGameReducer;
