import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';
import StudentProjectRow from 'components/main/items/rows/StudentProjectRow';

class StudentProjects extends SubView {

  constructor(props){
    super(props);
    this.state = {
      studentProjects: [],
      rowToShow: 30
    }
  
  }

  componentDidMount(){
    this.init(this.props);
    if(this.state.studentProjects.length === 0){
      //this.initStudentProjects(this.props);
      this.getStudentProfiles();
      this.initStudentProjects(this.props);
    }
    this.setListScroll('studentProjectList');
  }

  /*
  UNSAFE_componentWillReceiveProps(newProps){
    this.init(newProps);
    //if(this.state.studentProjects.length === 0){
      //this.initStudentProjects(newProps);
     this.initStudentProjects(newProps);
    //}
  } */
/*
  initStudentProjects(props){
    var studentProjects = this.store.projects.viewingProject.studentProjects.slice(0);

    for(var i=0;i<studentProjects.length;i++){
      studentProjects[i] = this.func.getById.studentProject(studentProjects[i], this.store);
      //console.log(studentProjects[i])
      if(studentProjects[i] === null){ console.log('no studentProjects'); return; }
      var total = 0;
      var featured = 0;
      var alert = 0;
      const cardsId = studentProjects[i].cards;
      total = cardsId.length;
      for(var j=0;j<cardsId.length;j++){
        const card = this.func.getById.card(cardsId[j], this.store);
        if(card === null){ console.log('no card data'); return; }
        if((!card.grade || card.grade === 'notGraded') && !card.hide){
          alert++;
        }
        if(card.grade && card.grade === 'featured' && !card.hide){
          featured++;
        }
      }
      studentProjects[i] = {...studentProjects[i], total: total, featured: featured, alert: alert}
    }
    this.setState({
      studentProjects: studentProjects
    });
  }
  */
  /*
  missingStudentsList(){
    const students = this.store.courses.viewingCourse.joinedStudents;
    const studentProjects = this.state.studentProjects;
    const submittedStudents = [];
    for(var i=0;i<studentProjects.length;i++){
      if(studentProjects[i].cards.length > 0){ submittedStudents.push(studentProjects[i].student); }
    }
    return students.map((student, i)=>{
      if(submittedStudents.indexOf(student) < 0){
        return this.missingStudentRow( this.func.getById.profileByUser(student, this.store) )
      }else{
        return null;
      }
    })
  }

  missingStudentRow(profile){
    if(!profile){ return null; }
    const style = {...this.ui.styles.container, ...{
      width: this.bs.width,
      height: this.bs.height * 0.04,
      backgroundColor: this.ui.colors.ultraLightGrey,
      fontSize: this.bs.height * 0.025,
      fontWeight: 'bold',
      color: this.ui.colors.grey
    }}
    return (
      <div style={style} key={profile._id}>
        {this.func.multiLang(
          profile.name + ' has not yet submitted any card',
          profile.name + ' 尚未提交任何卡片',
          profile.name + ' 尚未提交任何卡片')}
      </div>
    )
  }
  */
  /*
  studentProjectsList(){
    //if(this.state.studentProjects.length === 0){
      //return this.subTitle(['No submitted cards','沒有任何已提交的卡片','没有任何已提交的卡片'])
    //}
    return this.state.studentProjects.map((studentProject, i)=>{
      return(
        <StudentProjectRow
        app={this.app}
        studentProject={studentProject}
        key={studentProject._id}
        byStudent={true}
        onClick={()=>{ this.actions.studentProjects.viewStudentProject(studentProject); this.actions.content.pushView('gradingCards'); }}/>
      )
    })
  }
  */

  getStudentProfiles(){
    const course = this.store.courses.viewingCourse;
    const profilesToGet = [];
    const profilesToShow = course.joinedStudents;
    for(var i=0;i<profilesToShow.length;i++){
      if(this.func.getById.profileByUser(profilesToShow[i], this.store) === null){
        profilesToGet.splice(0,0, profilesToShow[i]);
      }
    }
    if(profilesToGet.length > 0){
      this.actions.profiles.getProfiles(profilesToGet);
    }
  }

  studentProjectsList(){
    var data = [];
    //this.getStudentProfiles();
    let isStudent = false;
    for(var i=0; i<this.state.studentProjects.length; i++){
      for(let j = 0; j < this.store.courses.viewingCourse.joinedStudents.length; j++){
        if(this.state.studentProjects[i].student === this.store.courses.viewingCourse.joinedStudents[j])
          isStudent = true;
      }
      if(isStudent){
        const profile = this.func.getById.profileByUser(this.state.studentProjects[i].student, this.store);
        if(profile){ data.push({profile: profile, studentProject: this.state.studentProjects[i]});}
      }
    }
    //console.log('before');
    //console.log(data);
    
    data = data.slice(0).sort((a, b) => {
      if(a.profile.lastNameEng && b.profile.lastNameEng){
        if(a.profile.lastNameEng.trim() !== b.profile.lastNameEng.trim()){
          return a.profile.lastNameEng.localeCompare(b.profile.lastNameEng);
        }
        else if(a.profile.firstNameEng && b.profile.firstNameEng){
          if(a.profile.firstNameEng !== b.profile.firstNameEng){
            return a.profile.firstNameEng.localeCompare(b.profile.firstNameEng);
          }
        }
      }
      if(a.profile.lastNameEng && !b.profile.lastNameEng){
        return a.profile.lastNameEng.localeCompare(b.profile.name);
      } 
      if(!a.profile.lastNameEng && b.profile.lastNameEng){
        return a.profile.name.localeCompare(b.profile.lastNameEng);
      }
      if(a.profile.name && b.profile.name)
        return a.profile.name.localeCompare(b.profile.name);
      else
        return true;
    });
    //console.log('after');
    //console.log(data);
    return data.slice(0, this.state.rowToShow).map((item, i) => {
      const profile = item.profile;
      const studentProject = item.studentProject;
      const clickable = studentProject.missing? false: true;
      if(profile.belongTo !== studentProject.student){
        console.log(profile, " ", studentProject);
      }
      return(
        <StudentProjectRow
        app={this.app}
        profile={profile}
        studentProject={studentProject}
        key={i}
        byStudent={true}
        onClick={()=>{ if(clickable){ this.actions.studentProjects.viewStudentProject(studentProject); this.actions.content.pushView('gradingCards'); } }}/>
      )
    })
    /* 
    return this.state.studentProjects.map((studentProject, i)=>{
      const clickable = studentProject.missing? false: true;
      return(
        <StudentProjectRow
        app={this.app}
        studentProject={studentProject}
        key={studentProject._id}
        byStudent={true}
        onClick={()=>{ if(clickable){ this.actions.studentProjects.viewStudentProject(studentProject); this.actions.content.pushView('gradingCards'); } }}/>
      )
    })*/
  }

  initStudentProjects(props){
    const students = this.store.courses.viewingCourse.joinedStudents;
    var studentProjects = this.store.projects.viewingProject.studentProjects.slice(0);
    const submittedStudents = [], missingStudents = [];
    studentProjects = studentProjects.map((studentProject, i) => {
      let studentProjectObj = this.func.getById.studentProject(studentProject, this.store);
      if(studentProjectObj === null || studentProjectObj.cards.length <= 0){ return null; }
      if(studentProjectObj.cards.length > 0){ submittedStudents.push(studentProjectObj.student); }
      let total = 0;
      let featured = 0;
      let alert = 0;
      const cardsId = studentProjectObj.cards;
      total = cardsId.length;
      for(let j=0;j<cardsId.length;j++){
        const card = this.func.getById.card(cardsId[j], this.store);
        if(card === null){ /*console.log('no card data');*/ continue; }
        if((!card.grade || card.grade === 'notGraded') && !card.hide){
          alert++;
        }
        if(card.grade && card.grade === 'featured' && !card.hide){
          featured++;
        }
      }
      studentProjectObj = {...studentProjectObj, total: total, featured: featured, alert: alert}
      return studentProjectObj;
    }).filter((studentProject, i) => {
      if(studentProject) return studentProject;
      return false;
    });

    for(var k=0; k<students.length; k++){
      if(submittedStudents.indexOf(students[k]) < 0){
        missingStudents.push({student: students[k], missing: true, total: 0, featured: 0, alert: 0})
      }
    }
    
    studentProjects = [...studentProjects, ...missingStudents];

    this.setState({
      studentProjects: studentProjects
    });
  }

  render() {
    this.init(this.props);
    return(
      <div style={this.subViewStyle()}>
        <div id={'studentProjectList'} onScroll={()=>{ this.onScroll('studentProjectList', ()=>{ this.addRowToShow(30);}) }} style={{...this.bs, ...this.ui.styles.list}}>
          {/*this.missingStudentsList()*/}
          {this.studentProjectsList()}
        </div>
      </div>
    )
  }

}

export default StudentProjects;
