const settingReducer = (
  state = {
  }, action)=>{
  switch (action.type) {
    case 'setSetting':
      return action.payload;
    default:
      return state;
  }
}

export default settingReducer;
