import React from 'react';
import Row from './Row';

import icon_cross from 'resources/images/buttons/buttonIcons/cross_grey.png';

class AllowedCommentEditRow extends Row {

  constructor(props){
    super(props);
    this.init(props);
    this.state = {
      fontSize: this.bs.height * 0.02
    }
  }

  content = (style)=>(
    <div key={this.props.editAllowedComment.key} style={{...this.bs, ...{
      width: this.bs.width * 0.9,
      height: style.height,
      opacity: style.opacity,
      backgroundColor: 'white',
      borderBottom: '5px solid ' + this.ui.colors.ultraLightGrey,
      flexShrink: 0
    }}}>
      {this.questBar(this.props.index, this.props.editAllowedComment)}
      {this.gap('1%')}
    </div>
  )

  onOptionsChange(newOptions){
    this.actions.allowedComment.setEditAllowedComment({index: this.props.index, editAllowedComment: {...this.props.editAllowedComment, ...{ optios: newOptions}} })
  }

  questBar(i, lang){
    const barStyle = {...this.ui.styles.area, ...{
      width: this.bs.width * 0.9,
      height: this.bs.height * 0.065,
      alignItems: 'center'
    }}
    const editAllowedComment = this.props.editAllowedComment;
    return(
      <div style={barStyle}>
        {this.textDisplay((this.props.index + 1) + '.', ['', ''], this.state.fontSize)}
        {this.filler()}
        {this.inputs.textArea('title',['Allowed Comment','允許的評論','允许的评论'],
        editAllowedComment.title? editAllowedComment.title:'',
        (e)=>{ this.onQuestionChange(i, e.target.value)}, [this.bs.width * 0.7, this.bs.height * 0.04])}
        {this.filler()}
        {this.buttons.langBar(icon_cross, 0.1, [this.bs.height* 0.04, this.bs.height * 0.04], ()=> {this.actions.allowedComment.killEditAllowedCommentsItem(i)})}
        {/*{this.buttons.langBar(icon_cross, 0.1, [this.bs.height * 0.04, this.bs.height * 0.04],()=>{this.actions.survey.killEditQuestionsItem(i)})} */}
        {/*{this.verGap('1%')} */}
      </div>
    )
  }

  onQuestionChange(index, value){
    var editAllowedComment = this.props.editAllowedComment;
    editAllowedComment.title = value;
    this.actions.allowedComment.setEditAllowedComment({index: index, editAllowedComment: editAllowedComment})
  }

  render() {
    this.init(this.props);
    return this.animatedRow(
      this.content.bind(this),
      this.bs.height * 0.185,
      this.props.editAllowedComment.killed? ()=>{this.actions.allowedComment.removeEditAllowedCommentsItem(this.props.index)}: null)
  }

}

export default AllowedCommentEditRow;
