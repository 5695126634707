import React from 'react';
import View from 'components/main/pages/home/views/View';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import badge_featured from 'resources/images/icons/badges/badge_corner_featured.png';
import NoImageIcon from 'resources/images/general/no_image.png';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';

class PrintPdf extends View {

  constructor(props){
    super(props);
    this.init(props);
    this.cardsToView = this.store.cards.viewingCards;
    this.state = {
      cardPerPage: '4',
      recommendedSetting: '',
    };
  }
  
  ptToPx(pt, cardsPerPage){
    if(cardsPerPage === 4)
      return pt * 1.19185670772;
    else if(cardsPerPage === 2)
      return pt * 2.03325251232;
    else if(cardsPerPage === 1)
      return pt * 2.86045609852;
    else
      return pt;
  }

  cmToPx(cm, cardsPerPage){
    if(cardsPerPage === 4)
      return cm * 40.5418719212;
    else if(cardsPerPage === 2)
      return cm * 57.6354679803;
    else if(cardsPerPage === 1)
      return cm * 81.0837438424;
    else
      return cm;
  }

  async print1CardPerPage(len, name, cards, download, fontSize, imageSize, lineSize) {
    if(download)
      this.actions.modal.loadingMessage(['Downloading...', '正在下載...', '正在下载...']);
    else
      this.actions.modal.loadingMessage(['Loading...', "加載中...", "加载中..."]);
    var doc = new jsPDF('p', 'mm', [289, 203.2]);
    const maxLine = parseInt(lineSize, 10);
    for(var i = 0; i < len; i++){
      const card = this.func.getById.card(cards[i], this.store);
      const url = await this.func.url(card.icon, "cardIcon");
      const input = document.getElementById('printPdf1_' + i);
      const imageSizePX = this.cmToPx(parseInt(imageSize, 10), 1);
      document.getElementById("pdf1Img_" + i).src = url ? url: NoImageIcon;
      document.getElementById("pdf1Text_" + i).style.fontSize = this.ptToPx(parseInt(fontSize, 10), 1) + "px";
      document.getElementById("pdf1Text_" + i).style.WebkitLineClamp = maxLine;
      document.getElementById("pdf13_" + i).style.marginLeft = (1646 - parseInt(imageSizePX, 10)) / 2 + "px";
      document.getElementById("pdf13_" + i).style.width = imageSizePX + "px";
      document.getElementById("pdf13_" + i).style.height = imageSizePX + "px";
      document.getElementById("pdf1Img_" + i).style.width = parseInt(imageSizePX - 30, 10) + "px";
      document.getElementById("pdf1Img_" + i).style.maxHeight = imageSizePX + "px";
      const curIndex = i;
      await html2canvas(input, {logging: true, letterRendering: 1, useCORS: true,
      onclone: function(clonedDoc){
        clonedDoc.getElementById('printPdf').style.display = "block";
      }, }).then(canvas => {
      var imgData = canvas.toDataURL('image/jpeg', 1.0);
      var imgWidth = 203.2; 
      var imgHeight = canvas.height * imgWidth / canvas.width;
      doc.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight, undefined,'FAST');
      if(curIndex !== len - 1 )
        doc.addPage();
      if(curIndex === len - 1)
      {
        if(download)
          doc.save(name)
        else{
          window.open(doc.output('bloburl'), '_blank');   
        }
      }

  })
  }
  this.actions.modal.hideModal();
  }

  async print2CardsPerPage(len, name, cards, download, fontSize, imageSize, lineSize) {
    if(download)
      this.actions.modal.loadingMessage(['Downloading...', '正在下載...', '正在下载...']);
    else
      this.actions.modal.loadingMessage(['Loading...', "加載中...", "加载中..."]);
    var doc = new jsPDF('l', 'mm', [289, 203.2]);
    const maxLine = parseInt(lineSize, 10);
    for(var i = 0; i < len; i++){
      const card = this.func.getById.card(cards[i], this.store);
      const url = await this.func.url(card.icon, "cardIcon");
      const input = document.getElementById('printPdf2_' + i);
      const imageSizePX = this.cmToPx(parseInt(imageSize, 10), 2);
      document.getElementById("pdf2Img_" + i).src = url ? url: NoImageIcon;
      document.getElementById("pdf2Text_" + i).style.fontSize = this.ptToPx(parseInt(fontSize, 10), 2) + "px";
      document.getElementById("pdf2Text_" + i).style.WebkitLineClamp = maxLine;
      document.getElementById("pdf23_" + i).style.marginLeft = (823 - parseInt(imageSizePX, 10)) / 2 + "px";
      document.getElementById("pdf23_" + i).style.width = imageSizePX + "px";
      document.getElementById("pdf23_" + i).style.height = imageSizePX + "px";
      document.getElementById("pdf2Img_" + i).style.width = parseInt(imageSizePX - 30, "10") + "px";
      document.getElementById("pdf2Img_" + i).style.maxHeight = imageSizePX + "px";
      const curIndex = i;
      await html2canvas(input, {logging: true, letterRendering: 1, useCORS: true,
      onclone: function(clonedDoc){
        clonedDoc.getElementById('printPdf').style.display = "block";
      }, }).then(canvas => {
      var imgData = canvas.toDataURL('image/jpeg', 1.0);
      var imgWidth = 203.2; 
      var imgHeight = canvas.height * imgWidth / canvas.width;
      if(curIndex % 2 === 0){
        doc.addImage(imgData, 'JPEG', 0, 0, imgHeight / 2, imgWidth, undefined, 'FAST');
      }
      else{
        doc.addImage(imgData, 'JPEG', imgHeight / 2, 0 , imgHeight / 2, imgWidth, undefined,'FAST');
        if(curIndex !== len - 1 ) doc.addPage(); 
      }
      if(curIndex === len - 1)
      {
        if(download)
          doc.save(name)
        else{
          window.open(doc.output('bloburl'), '_blank');
        }
     }  

  })
  }
  this.actions.modal.hideModal();
  }

  async print4CardsPerPage(len, name, cards, download, fontSize, imageSize, lineSize) {
    if(download)
      this.actions.modal.loadingMessage(['Downloading...', '正在下載...', '正在下载...']);
    else
      this.actions.modal.loadingMessage(['Loading...', "加載中...", "加载中..."]);
    var doc = new jsPDF('p', 'mm', [289, 203.2]);
    const maxLine = parseInt(lineSize, 10);
    for(var i = 0; i < len; i++){
      const card = this.func.getById.card(cards[i], this.store) ;
      console.log(card);
      const url = await this.func.url(card.icon, "cardIcon");
      const input = document.getElementById('printPdf4_' + i);
      const imageSizePX = this.cmToPx(parseInt(imageSize, 10), 4);
      console.log(this.ptToPx(fontSize, 1));
      document.getElementById("pdf4Img_" + i).src = url ? url: NoImageIcon;
      document.getElementById("pdf4Text_" + i).style.fontSize = this.ptToPx(fontSize, 4) + "px";
      document.getElementById("pdf4Text_" + i).style.WebkitLineClamp = maxLine;
      document.getElementById("pdf43_" + i).style.marginLeft = (411.5 - parseInt(imageSizePX , 10)) / 2 + "px";
      document.getElementById("pdf43_" + i).style.width = imageSizePX  + "px";
      document.getElementById("pdf43_" + i).style.height = imageSizePX  + "px";
      document.getElementById("pdf4Img_" + i).style.width = parseInt(imageSizePX  - 30, 10) + "px";
      document.getElementById("pdf4Img_" + i).style.maxHeight = imageSizePX  + "px";
      const curIndex = i;
      await html2canvas(input, {logging: true, letterRendering: 1, useCORS: true,
      onclone: function(clonedDoc){
        clonedDoc.getElementById('printPdf').style.display = "block";
      }, }).then(canvas => {
      var imgData = canvas.toDataURL('image/jpeg', 1.0);
      var imgWidth = 203.2; 
      var imgHeight = canvas.height * imgWidth / canvas.width;
      if(curIndex % 4 === 0){
        doc.addImage(imgData, 'JPEG', 0, 0, imgWidth / 2, imgHeight / 2, undefined, 'FAST');
      }
      else if(curIndex % 4 === 1){
        doc.addImage(imgData, 'JPEG', imgWidth / 2, 0, imgWidth / 2, imgHeight / 2, undefined, 'FAST');
      }
      else if(curIndex % 4 === 2){
        doc.addImage(imgData, 'JPEG', 0, imgHeight / 2, imgWidth / 2, imgHeight / 2, undefined, "FAST");
      }
      else{
        doc.addImage(imgData, 'JPEG', imgWidth / 2, imgHeight / 2, imgWidth / 2, imgHeight / 2, undefined, 'FAST');
        if(curIndex !== len - 1 ) doc.addPage(); 
      }
      if(curIndex === len - 1)
        if(download)
          doc.save(name)
        else{
          window.open(doc.output('bloburl'), '_blank')
        }
    })
  }
  this.actions.modal.hideModal();
  }

  handleChange = (event) => {
    this.setState({cardPerPage: event.target.value});
    if(this.state.recommendedSetting === 'word'){
      if(event.target.value === '4'){
        document.getElementById("fontSize").value = 60;
        document.getElementById("imageSize").value = 7;
        document.getElementById("lineLimit").value = 1;
      }
      else if (event.target.value === '2'){
        document.getElementById("fontSize").value = 80;
        document.getElementById("imageSize").value = 11;
        document.getElementById("lineLimit").value = 1;
      }
      else if (event.target.value === '1'){
        document.getElementById("fontSize").value = 120;
        document.getElementById("imageSize").value = 15;
        document.getElementById('lineLimit').value = 1;
      }
    }
    else if(this.state.recommendedSetting === 'sentence'){
      if(event.target.value === '4'){
        document.getElementById("fontSize").value = 35;
        document.getElementById("imageSize").value = 6;
        document.getElementById("lineLimit").value = 3;
      }
      else if (event.target.value === '2'){
        document.getElementById("fontSize").value = 40;
        document.getElementById("imageSize").value = 10;
        document.getElementById("lineLimit").value = 3;
      }
      else if (event.target.value === '1'){
        document.getElementById("fontSize").value = 60;
        document.getElementById("imageSize").value = 14;
        document.getElementById('lineLimit').value = 3;
      }
    }
    else if(this.state.recommendedSetting === 'paragraph'){
      if(event.target.value === '4'){
        document.getElementById("fontSize").value = 20;
        document.getElementById("imageSize").value = 5;
        document.getElementById("lineLimit").value = 8;
      }
      else if (event.target.value === '2'){
        document.getElementById("fontSize").value = 20;
        document.getElementById("imageSize").value = 6;
        document.getElementById("lineLimit").value = 11;
      }
      else if (event.target.value === '1'){
        document.getElementById("fontSize").value = 20;
        document.getElementById("imageSize").value = 8;
        document.getElementById('lineLimit').value = 16;
      }
    }
  };

  recommendedSettings = (event) =>{
    this.setState({recommendedSetting: event.target.value});
    if(event.target.value === 'word'){
      if(this.state.cardPerPage === '4'){
        document.getElementById("fontSize").value = 60;
        document.getElementById("imageSize").value = 7;
        document.getElementById("lineLimit").value = 1;
      }
      else if (this.state.cardPerPage === '2'){
        document.getElementById("fontSize").value = 80;
        document.getElementById("imageSize").value = 11;
        document.getElementById("lineLimit").value = 1;
      }
      else if (this.state.cardPerPage === '1'){
        document.getElementById("fontSize").value = 120;
        document.getElementById("imageSize").value = 15;
        document.getElementById('lineLimit').value = 1;
      }
    }
    else if(event.target.value === 'sentence'){
      if(this.state.cardPerPage === '4'){
        document.getElementById("fontSize").value = 35;
        document.getElementById("imageSize").value = 6;
        document.getElementById("lineLimit").value = 3;
      }
      else if (this.state.cardPerPage === '2'){
        document.getElementById("fontSize").value = 40;
        document.getElementById("imageSize").value = 10;
        document.getElementById("lineLimit").value = 3;
      }
      else if (this.state.cardPerPage === '1'){
        document.getElementById("fontSize").value = 100;
        document.getElementById("imageSize").value = 14;
        document.getElementById('lineLimit').value = 3;
      }
    }
    else if(event.target.value === 'paragraph'){
      if(this.state.cardPerPage === '4'){
        document.getElementById("fontSize").value = 20;
        document.getElementById("imageSize").value = 5;
        document.getElementById("lineLimit").value = 8;
      }
      else if (this.state.cardPerPage === '2'){
        document.getElementById("fontSize").value = 20;
        document.getElementById("imageSize").value = 6;
        document.getElementById("lineLimit").value = 11;
      }
      else if (this.state.cardPerPage === '1'){
        document.getElementById("fontSize").value = 20;
        document.getElementById("imageSize").value = 8;
        document.getElementById('lineLimit').value = 16;
      }
    }
  };

  render() {
    this.init(this.props);
    var defaultDate = new Date();
    defaultDate.setDate(defaultDate.getDate() + this.state.defaultPeriod);

    this.editAllowedComments = this.store.allowedComment.editAllowedComments;
    this.hideProject = this.store.projects.viewingProject.hide ? this.store.projects.viewingProject.hide : false;
    this.editLang = this.store.projects.editLang;
    return(
      <div style={this.viewStyle()}>
      <div style={{marginTop: "6%", fontSize: "1.75em"}}> {this.func.multiLang('Recommended Settings', '推薦設定', '推荐设定')}</div>
       {/* <input id="cardsPerPage" type="text" style={{marginTop: "1%"}}></input> */}
        <div style={{marginTop:"1%"}}>
          <FormControl>
            <Grid container spacing={4} direction="row">
                <Grid item xs={8}>
                  <Select
                  style={{fontSize:"1.35em", textAlign:"center"}}
                  variant='outlined'
                  value={this.state.recommendedSetting}
                  onChange={(e) => this.recommendedSettings(e)}
                  >
                    <MenuItem value={'word'}>{this.func.multiLang('Word', '詞語', '词语')}</MenuItem>
                    <MenuItem value={'sentence'}>{this.func.multiLang('Sentence', '句子', '句子')}</MenuItem>
                    <MenuItem value={'paragraph'}>{this.func.multiLang('Paragraph', '段落', '段落')}</MenuItem>
                    </Select>
                </Grid>
            </Grid>
          </FormControl>
      </div>
        <div style={{marginTop: "5%", fontSize: "1.75em"}}> {this.func.multiLang('Card(s) Per Page','每頁卡片數','每页卡片数')}</div>
       {/* <input id="cardsPerPage" type="text" style={{marginTop: "1%"}}></input> */}
        <div style={{marginTop:"1%"}}>
          <FormControl>
            <Grid container spacing={4} direction="row">
                <Grid item xs={8}>
                  <Select
                  style={{fontSize:"1.35em", textAlign:"center"}}
                  variant='outlined'
                  value={this.state.cardPerPage}
                  onChange={(e) => this.handleChange(e)}
                  >
                    <MenuItem value={'1'}>1</MenuItem>
                    <MenuItem value={'2'}>2</MenuItem>
                    <MenuItem value={'4'}>4</MenuItem>
                    </Select>
                </Grid>
            </Grid>
          </FormControl>
      </div>
        <div id="imageSizeText" style={{marginTop: "5%", fontSize: "1.75em"}}> {this.func.multiLang('Image Width','圖片闊度','图片阔度') + " (cm)"} </div>
        <input id="imageSize"  type="text" style={{marginTop: "1%", height: "5%", fontSize: "1.5em"}}></input>
        <div style={{marginTop: "10%", fontSize: "1.75em"}}> {this.func.multiLang('Font Size','字體大小','字体大小') + "  (pt)"}</div>
        <input id="fontSize" type="text"  style={{marginTop: "1%", height: "5%", fontSize: "1.5em"}}></input>
        <div style={{marginTop: "10%", fontSize: "1.75em"}}> {this.func.multiLang('Line Limit','行數限制','行数限制')}</div>
        <input id="lineLimit" type="text" style={{marginTop: "1%", height: "5%", fontSize: "1.5em"}}></input>
        <div style={{display: "flex", flexDirection: "row", marginTop: "10%"}}>
          <button  style={{height: "2em", width: "7em", cursor: "pointer", border: "0.5px solid black", borderRadius: "5px", marginRight: "20%", fontSize: "2em" }}
          onClick={() => {
            if(this.state.cardPerPage === "1")
              this.print1CardPerPage(this.cardsToView.length, this.store.projects.viewingProject.title + "_1card.pdf",  this.cardsToView, false,  document.getElementById("fontSize").value, document.getElementById("imageSize").value,  document.getElementById("lineLimit").value);
            else if(this.state.cardPerPage === "2"){
              this.print2CardsPerPage(this.cardsToView.length, this.store.projects.viewingProject.title + "_2cards.pdf",  this.cardsToView, false,  document.getElementById("fontSize").value, document.getElementById("imageSize").value,  document.getElementById("lineLimit").value)
            }
            else if(this.state.cardPerPage === "4")
              this.print4CardsPerPage(this.cardsToView.length, this.store.projects.viewingProject.title + "_4cards.pdf",  this.cardsToView, false, document.getElementById("fontSize").value, document.getElementById("imageSize").value, document.getElementById("lineLimit").value);
          }}
          > {this.func.multiLang('Preview','預覽','预览')}</button>
          <button id="btnPdf" onClick={() => {
            console.log(this.state.cardPerPage);
            if(this.state.cardPerPage  === "1")
              this.print1CardPerPage(this.cardsToView.length, this.store.projects.viewingProject.title + "_1card.pdf",  this.cardsToView, true, document.getElementById("fontSize").value, document.getElementById("imageSize").value,  document.getElementById("lineLimit").value)
            else if(this.state.cardPerPage  === "2"){
              this.print2CardsPerPage(this.cardsToView.length, this.store.projects.viewingProject.title + "_2cards.pdf",  this.cardsToView, true,  document.getElementById("fontSize").value, document.getElementById("imageSize").value,  document.getElementById("lineLimit").value)
            }
            else if(this.state.cardPerPage === "4")
              this.print4CardsPerPage(this.cardsToView.length, this.store.projects.viewingProject.title + "_4cards.pdf",  this.cardsToView, true,  document.getElementById("fontSize").value, document.getElementById("imageSize").value,  document.getElementById("lineLimit").value)
          }} 
            style={{height: "2em", width: "7em", cursor: "pointer", border: "0.5px solid black", borderRadius: "5px", marginLeft: "20%", fontSize: "2em" }}>{this.func.multiLang('Download','下載','下载')}</button>
        </div>
        {
        <div id="printPdf" style={{display: "none"}} >
      
        {this.cardsToView ? this.cardsToView.map( (cardID, index) =>
        {
          const card = this.func.getById.card(cardID, this.store);
          if(card === null) return null;
            var lang = "";
            const firstLang = this.func.getById.lang(card.langs[0], this.store);
            if(firstLang === null) return null;
            for(var i = 0; i < this.store.langs.langKeys.length ; i++)
            {
              if(firstLang && firstLang.key  === this.store.langs.langKeys[i].key){
                lang = this.func.multiLang(this.store.langs.langKeys[i].name[0], this.store.langs.langKeys[i].name[1], this.store.langs.langKeys[i].name[2]);
              }
            }
            let wordLen = 0;
            if(firstLang.text !== null){
            for(i = 0; i < firstLang.text.length; i++)
              if(firstLang.text[i].charCodeAt() <= 255) wordLen += 1;
              else wordLen += 2;
              return (
                    <div key={"pdf" + index}>
                    
                    <div key={"pdf4_" + index} style={{display:"inline-block", width: "411.5px", height: "585px", float: "left", borderTop:"0.5px solid black", borderBottom:"0.5px solid black", borderLeft:"0.25px solid black", borderRight:"0.25px solid black",position: "relative"}} id={"printPdf4_" + index}>
                      <div key={"pdf4Title_" + index} style={{width: "411.5px",  backgroundColor: "#6DB13E", textAlign: "center", height: "45px", color: "white",fontWeight: "bold", justifySelf: "center", borderTop: "0.5px solid black", borderBottom: "0.5px solid black", overflow: "hidden", textOverflow: "ellipsis"
                      , fontSize: (this.store.projects.viewingProject.title.length <= 50 )? (this.store.projects.viewingProject.title.length > 25)? "23px":"30px" : "16px"}}>{this.store.projects.viewingProject.title}</div>
                      <div key={"pdf42_" + index} style={{width: "411.5px", height:"539px", borderBottom: "0.5px solid black"}}>
                        <img key={"pdfBadge4_" + index} style={{height: "20%", top: "45px", right: 0, position: "absolute"}} alt="featuredBadge" src={badge_featured}/>
                        <div key={"pdf43_" + index} id={"pdf43_" + index} style={{display: "flex", marginLeft: (411.5 - 250) / 2, marginTop: "20px", width:"250px", height:"250px", border: "0.5px solid black"}}>
                          <img key={"pdf4Img_" + index} id={"pdf4Img_" + index} style={{marginLeft:"15px" ,width:"220px", alignSelf: "center", justifyContent:"center", maxHeight: "250px"}} alt={card.icon}/> 
                        </div>
                        <div key={"pdf4Lang_" + index} style={{marginLeft: (411.5 - 390) / 2,  width:"390px", height:"auto", marginTop: "5px", textAlign:  "left", fontSize: "10px"}}>{lang}</div>
                        <div key={"pdf4Text_" + index} id={"pdf4Text_" + index} style={{ display: "-webkit-box", WebkitLineClamp: 10, WebkitBoxOrient: "vertical", overflow: "hidden", textOverflow: "ellipsis",marginLeft: (411.5 - 390) / 2,  marginTop: "15px",width:"390px", height:"auto",fontSize: (wordLen > 12) ?"16px" : "50px", textAlign: "left"}} >{ firstLang.text }</div>
                        {/* wordLen > 480 && <div key={"pdf4..._" + index} style={{marginLeft: (411.5 - 390) / 2, fontWeight: "bold"}}>...</div> */}
                      <div key={"pdf4Author_" + index} style={{height:"`10px", position: "absolute", bottom: "10px", left: 5}}>{(this.func.getById.profileByUser(card.author, this.store) === null)? "" : this.func.getById.profileByUser(card.author, this.store).name}</div> 
                    </div>
                    </div>
                    <div key={"pdf2_" + index} style={{display:"inline-block", width: "823px", height: "1170px", float: "left", borderTop:"0.5px solid black", borderBottom:"0.5px solid black", borderLeft:"0.25px solid black", borderRight:"0.25px solid black", position: "relative"}} id={"printPdf2_" + index}>
                      <div key={"pdf2Title_" + index} style={{verticalAlign: "center", width: "823px",  backgroundColor: "#6DB13E", textAlign: "center", height: "90px", color: "white", fontWeight: "bold", justifySelf: "center", textOverflow: "ellipsis", overflow: "hidden", borderTop: "0.5px solid black", borderBottom: "0.5px solid black"
                      , fontSize: (this.store.projects.viewingProject.title.length <= 50 )? (this.store.projects.viewingProject.title.length > 25)? "46px":"60px" : "32px"}}>{this.store.projects.viewingProject.title}</div>
                      <div key={"pdf22_" + index} style={{width: "823px", height:"1080px", borderBottom: "0.5px solid black"}}>
                      <img key={"pdfBadge2_" + index} style={{height: "20%", top: "90px", right: 0, position: "absolute"}} alt="featuredBadge" src={badge_featured}/>
                        <div key={"pdf23_" + index} id={"pdf23_" + index} style={{display: "flex", marginLeft: (823 - 400) / 2, marginTop: "40px",width:"400px", height:"400px", border: "0.5px solid black"}}>
                          <img key={"pdf2Img_" + index} id={"pdf2Img_" + index} style={{marginLeft:"15px" ,width:"300px", alignSelf: "center", justifyContent:"center",  maxHeight: "400px"}} alt={card.icon}/> 
                        </div>
                        <div key={"pdf2Lang_" + index} style={{marginLeft: (823 - 780) / 2,  width:"780px", height:"auto", marginTop: "10px", textAlign:  "left", fontSize: "20px"}}>{lang}</div>
                        <div key={"pdf2Text_" + index} id={"pdf2Text_" + index} style={{display: "-webkit-box", WebkitLineClamp: 11, WebkitBoxOrient: "vertical", overflow: "hidden", textOverflow: "ellipsis",marginTop: "20px", width:"780px", marginLeft: (823 - 780) / 2,  fontSize: (wordLen > 12) ?"30px" : "100px", textAlign: "left" }} >{firstLang.text}</div>
                        {/*wordLen > 572 && <div key={"pdf2..." + index} style={{marginLeft: (823 - 780) / 2, fontSize: "30px", fontWeight: "bold"}}>...</div> */}
                      <div key={"pdf2Author_" + index} style={{height: "20px", fontSize: "20px", bottom: "10px", left: 10, position: "absolute"}}>{(this.func.getById.profileByUser(card.author, this.store) === null)? "" : this.func.getById.profileByUser(card.author, this.store).name}</div> 
                    </div>
                    </div>
                    <div key={"pdf1_" + index} style={{display:"inline-block", width: "1646px", height: "2340px", float: "left", border:"0.5px solid black", position: "relative"}} id={"printPdf1_" + index}>
                      <div key={"pdf1Title_" + index} style={{verticalAlign: "center", width: "1646px",  backgroundColor: "#6DB13E", textAlign: "center", height: "180px", color: "white", fontWeight: "bold", justifySelf: "center", textOverflow: "ellipsis", overflow: "hidden", borderTop: "0.5px solid black", borderBottom: "0.5px solid black"
                      , fontSize: (this.store.projects.viewingProject.title.length <= 50 )? (this.store.projects.viewingProject.title.length > 25)? "92px":"120px" : "64px"}}>{this.store.projects.viewingProject.title}</div>
                      <div key={"pdf12_" + index} style={{width: "1646px", height:"2150px", borderBottom: "0.5px solid black"}}>
                      <img key={"pdfBadge1_" + index} style={{height: "20%", top: "180px", right: 0, position: "absolute"}} alt="featuredBadge" src={badge_featured}/>
                        <div key={"pdf13_" + index} id={"pdf13_" + index} style={{display: "flex", marginLeft: (1646 - 800) / 2, width:"800px", marginTop: "80px", height:"800px", border: "0.5px solid black"}}>
                          <img key={"pdf1Img_" + index} id={"pdf1Img_" + index} style={{marginLeft:"15px" ,width:"600px", alignSelf: "center", justifyContent:"center", maxHeight: "800px"}} alt={card.icon}/> 
                        </div>
                        <div key={"pdf1Lang_" + index} style={{marginLeft: (1646 - 1560) / 2,  width:"1560px", height:"auto", marginTop: "20px", textAlign:  "left", fontSize: "30px"}}>{lang}</div>
                        <div key={"pdf1Text_" + index} id={"pdf1Text_" + index} style={{display: "-webkit-box", WebkitLineClamp: 15, WebkitBoxOrient: "vertical",  overflow: "hidden", textOverflow: "ellipsis", width:"1560px", marginLeft: (1646 - 1560) / 2,  fontSize: (wordLen > 12) ?"45px" : "200px", marginTop: "40px", textAlign: "left"}} >{firstLang.text}</div>
                        {/*wordLen > 1020 && <div key={"pdf1..." + index} style={{marginLeft: (1646 - 1560) / 2, fontSize: "45px", fontWeight: "bold"}}>...</div> */}
                      <div key={"pdf1Author" + index} style={{height: "40px", fontSize: "35px",  bottom: "20px", left: 20, position: "absolute"}}>{(this.func.getById.profileByUser(card.author, this.store) === null)? "" : this.func.getById.profileByUser(card.author, this.store).name}</div> 
                    </div>
                    </div>
                    </div>
                );
            }
            else
              return null;
        })
        : ""}
        
      </div> }
    </div>
    )
  }

  
}

export default PrintPdf;
