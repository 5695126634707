import React from 'react';
import View from 'components/main/pages/home/views/View';
import SettingRow from 'components/main/items/rows/SettingRow';

class ChangeSetting extends View {

    constructor(props){
        super(props);
        this.init(props);
        const editMode = this.props.editMode;
        this.viewingSchoolTag = this.store.schoolTags.viewingSchoolTag;
        this.state = {
            title: editMode? this.viewingSchoolTag.title: '',
            selectedSchools: editMode? this.getSelectedSchools(): [],
            courseTagSelection: editMode? this.viewingSchoolTag.courseTags: [],
            subjectTagSelection: editMode? this.viewingSchoolTag.subjectTags: [],
            projectTagSelection: editMode? this.viewingSchoolTag.projectTags: [],
        }
    }


    render() {
        this.init(this.props);
        const setting = this.store.setting;
        const keys = Object.keys(setting);

        const settingStyle = {
            width: '100%',
            height: '70%',
            overflowY: 'auto'
        }

        return(
        <div style={this.viewStyle()}>
            {this.gap('4%')}

            <div style={settingStyle}>
                {keys.map((key, i) => {
                    if(key === '_id' || key === 'schoolCode'){ return null; }
                    return (
                        <SettingRow key={i} app={this.app} data={{key: key, value: setting[key]}}/>
                    )
                })}
            </div>
            
            {this.buttons.rectGreen(['Confirm','確定','确定'], ()=>{this.updateSetting()})}
            {this.gap('3%')}
        </div>
        )
    }

    updateSetting(){
        const setting = this.store.setting;
        this.actions.setting.update(setting);
    }

}

export default ChangeSetting;
