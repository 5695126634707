import React from 'react';
import UI from 'components/UI';
//import Sound from 'react-sound';
//import WAVEInterface from 'components/audio/waveInterface';
//import AudioRecorder from 'audio-recorder-polyfill';
//import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import icon_recorder from 'resources/images/buttons/buttonIcons/recorder_black.png';
//import icon_play from 'resources/images/buttons/buttonIcons/play_black.png';
//import icon_stop from 'resources/images/buttons/buttonIcons/stop_black.png';
import icon_file from 'resources/images/buttons/buttonIcons/file_black.png';
import icon_cross from 'resources/images/buttons/buttonIcons/cross_black.png';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
//import lamejs from 'lamejs';
class RecorderBar extends UI {
  /*
  waveInterface = new WAVEInterface(this.props.app);

  UNSAFE_componentWillMount() { 
    this.waveInterface.reset(); 
  }
  componentWillUnmount() { 
    this.waveInterface.reset(); 
  }
  */

  constructor(props){
    super(props);
    this.state = {
      filename: props.defaultAudio,
      type: props.type,
      audioPlaying: false,
      defaultAudioPlaying: false,
    }
    this.page = props.page;
    this.recorder = null;
    this.stream = null;
    this.checkUrl();
  }

  UNSAFE_componentWillReceiveProps(newProps){
    this.init(newProps);
    const newFilename = newProps.defaultAudio;
    if(this.state.filename !== newFilename){
      this.setState({ filename: newFilename }, ()=>{ this.checkUrl(); })
    }
    //this.autoPlay(newProps);
  }

  /*
  autoPlay(newProps){
    if(!newProps.autoPlay || this.store.main.recording){ return; }
    if((newProps.defaultAudio && !this.state.defaultAudioPlaying && this.url.url) ||
      (newProps.audioBlob && !this.state.audioPlaying)){
      setTimeout(()=>{ if(this.props.autoPlay){ this.playback(); } }, 100);
    }
  }
  */


  record(){
    /*
    this.waveInterface.reset();
    this.waveInterface.startRecording()
    .then(() => {
      this.actions.main.setAudioRecorder({recording: true, recordingText: this.props.recordingText, onRecordStop: ()=>{this.stopRecord()}});
    })
    .catch((err) => {
      this.actions.modal.message([err.message, err.message, err.message]);
      throw err;
    })
    */
   
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    if(window.AudioContext) {
      navigator.getUserMedia =
      navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
      if(navigator.mediaDevices){
        /*
        AudioRecorder.encoder = mpegEncoder;
        AudioRecorder.prototype.mimeType = 'audio/mpeg';
        window.MediaRecorder = AudioRecorder;

        navigator.mediaDevices.getUserMedia({ audio: true}).then(stream => {
          this.recorder = new MediaRecorder(stream);
          this.recorder.addEventListener('dataavailable', e=> {
            this.props.onStopRecording(e.data);
          });
          this.recorder.start();
          this.actions.main.setAudioRecorder({recording: true, recordingText: this.props.recordingText, onRecordStop: ()=>{this.stopRecord()}});
        }).catch(e => {
          this.actions.modal.message(['Requested device not found', '找不到請求的設備', '找不到请求的设备']);
        });
        */
       navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true,
        echoCancellation:true
       }).then(stream => {
            this.recorder = RecordRTC(stream, {
            type: 'audio',
            recorderType: StereoAudioRecorder,
            numberOfAudioChannels: 1,
            bitrate: 128000,
            sampleRate: 48000,
            disableLogs: true,
          });
          this.stream = stream;
          this.recorder.startRecording();
          this.actions.main.setAudioRecorder({recording: true, recordingText: this.props.recordingText, onRecordStop: ()=>{this.stopRecord()}});
       })
      }
      else{
        this.actions.modal.audioHint();
      }
    }
    else{
      this.actions.modal.audioHint();
    }
    
    /*
    navigator.mediaDevices.getUserMedia({ audio: true },
      () => {
        this.recorder.start().then(() => {
          this.actions.main.setAudioRecorder({recording: true, recordingText: this.props.recordingText, onRecordStop: ()=>{this.stopRecord()}});
        }).catch((err) => {
          this.actions.modal.message([err, err, err]);
          throw err;
        });
      },
      () => {
        this.actions.modal.audioHint();
      },
    );
    */
  }

  stopRecord(){
    /*
    this.waveInterface.stopRecording()
    this.actions.main.setAudioRecorder({recording: false, onRecordStop: null});

    const blob = this.waveInterface.audioData;
    this.props.onStopRecording(blob);
    */
    /*
    this.actions.main.setAudioRecorder({recording: false, onRecordStop: null});
    this.recorder.stop().getMp3().then(([buffer, blob]) => {
      const file = new File(buffer, uuidv4() + '.mp3', {
        type: blob.type,
        lastModified: Date.now()
      });
      console.log(file);
      this.props.onStopRecording(file);

    }).catch((err) => {
      this.actions.modal.message([err, err, err]);
      throw err;
    });
    */
    let recorder = this.recorder;
    let actions = this.actions, stream = this.stream;
    let store = this.store;
    let page = this.page;
    let onStopRecording = this.props.onStopRecording;
    recorder.stopRecording(async function() {
      let blob = recorder.getBlob();
      actions.main.setAudioRecorder({recording: false, onRecordStop: null});
      /*
      const fr = new FileReader();
      fr.onload = async (event) => {
        try{
          const dataView = new DataView(fr.result);
          var wav = lamejs.WavHeader.readHeader(dataView);
          if(wav.dataLen === 0)
          {
            return ;
          }
          let channelNum = wav.channels, sampleRate = wav.sampleRate;
          var samples = new Int16Array(fr.result, wav.dataOffset, wav.dataLen / 2);
          var buffer = [];
          var mp3enc = new lamejs.Mp3Encoder(channelNum, sampleRate, 128);
          var remaining = samples.length;
          var maxSamples = 1152;
          for(var i=0; remaining >= maxSamples; i+= maxSamples){
              var mono = samples.subarray(i, i + maxSamples);
              var mp3buf =  mp3enc.encodeBuffer(mono); 
              if (mp3buf.length > 0) {
                  buffer.push(mp3buf);
              }
              remaining -= maxSamples; 
            }
      
            var flushData = mp3enc.flush();
            if(flushData.length > 0){
              buffer.push(flushData);
            }
      
          blob = new Blob(buffer, {type: 'audio/mpeg'});
          actions.langs.setLangAudio({index: 0, blob: blob});
          //actions.modal.hideModal();
          //actions.main.setAudioRecorder({recording: false, onRecordStop: null});
        }
        catch(error){
          console.log(error);
         // actions.modal.hideModal();
         // actions.langs.setLangAudio({index: 0, blob: blob});
          return ;
        }
      }
      fr.readAsArrayBuffer(blob);
      */

      if(page === "card")
        actions.langs.setLangAudio({index: store.langs.editLangs.length - 1, blob: blob});
      else if (page === "project")
        actions.projects.setProjectLangAudio({blob: blob});
      else 
        onStopRecording(blob);
      recorder.destroy();
      stream.getAudioTracks().forEach(track => track.stop());
    })
    //this.recorder.stream.getTracks().forEach(i => i.stop());
  }

  selectAudioFile(){
    var input = document.createElement('input');
    input.type = 'file';
    input.accept = '.mp3,.wav,audio/*';
    input.onchange = (inputEvent) =>{ this.props.onStopRecording(inputEvent.target.files[0]); }
    input.click();
  }

  /*
  playback(){
    if(this.state.audioPlaying || (!this.props.audioBlob && !this.props.defaultAudio)){ return; }
    //console.log(this.props.audioBlob)
    if(this.props.audioBlob){
      this.waveInterface.startPlayback(false, this.props.audioBlob, ()=>{this.onPlaybackEnd()})
      //.then(()=>{
        this.setState({ audioPlaying: true })
      //})
    }else if(this.props.defaultAudio && this.url.url){
      this.setState({ defaultAudioPlaying: true })
    }
  }

  onPlaybackEnd(){
    this.setState({ audioPlaying: false, defaultAudioPlaying: false })
  }

  stopPlayback(){
    if(this.state.audioPlaying){
      this.setState({ audioPlaying: false });
      this.waveInterface.stopPlayback();
    }
    if(this.state.defaultAudioPlaying){
      this.setState({ defaultAudioPlaying: false })
    }
  }
  */
 
  langBar(i){
    const barStyle = {...this.ui.styles.area, ...{
      width: this.props.scale? this.props.scale[0]: '100%',
      height: this.props.scale? this.props.scale[1]: '100%',
      minHeight: '80px',
    }}

    const audioPlayerStyle = {
      height: this.props.scale? this.props.scale[1]: '70%',
      minHeight: '80px',
    }

    const sizeSmall = [this.bs.height * 0.04,this.bs.height * 0.04];
    //const sizeBig = [this.bs.height * 0.05,this.bs.height * 0.05];

    const audioBlob = this.props.audioBlob;
    const hvAudio = (audioBlob || this.props.defaultAudio);
    var audioUrl = this.url.url;
    //const isPlaying = this.state.audioPlaying || this.state.defaultAudioPlaying;
    if(audioBlob){ audioUrl = URL.createObjectURL(audioBlob); }
    else if(!this.props.defaultAudio){ audioUrl = ''; }
    return (
      <div style={barStyle}>
        <AudioPlayer 
          style={audioPlayerStyle}
          atuoPlay={false}
          autoPlayAfterSrcChange={false}
          src={audioUrl} 
          customVolumeControls={[
            this.buttons.langBar(icon_recorder, hvAudio? 0.7: 0.15, sizeSmall, ()=>{this.record(i)}),
            this.verGap('5%'),
            this.buttons.langBar(icon_file , hvAudio? 0.7: 0.15, sizeSmall,()=>{this.selectAudioFile()}),
            this.props.canRemove && this.verGap('5%'),
            this.props.canRemove && this.buttons.langBar(icon_cross, hvAudio? 0.7:0.15, sizeSmall,()=>{this.props.onStopRecording(null)})
          ]}/>
      </div>
    )
    
    /*
    return(
      <div style={barStyle}>
        {this.verGap('10%')}
        {this.buttons.langBar(icon_recorder, hvAudio? 0.7: 0.15, sizeBig, ()=>{this.record(i)})}
        {this.verGap('10%')}
        {this.buttons.langBar(icon_file , hvAudio? 0.7: 0.15, sizeSmall,()=>{this.selectAudioFile()})}
        {this.verGap('10%')}
        {this.buttons.langBar(icon_play , (hvAudio && !isPlaying)? 0.7:0.15, sizeSmall,()=>{this.playback(i)})}
        {this.verGap('10%')}
        {this.buttons.langBar(icon_stop, (hvAudio && isPlaying)? 0.7:0.15, sizeBig,()=>{this.stopPlayback(i)})}
        {this.verGap('10%')}
        {this.props.canRemove && this.buttons.langBar(icon_cross, hvAudio? 0.7:0.15, sizeBig,()=>{this.props.onStopRecording(null)})}
        {this.state.defaultAudioPlaying && this.props.defaultAudio && this.url.url &&
          <Sound
          url={this.url.url}
          playStatus={Sound.status.PLAYING}
          onFinishedPlaying={this.onPlaybackEnd.bind(this)}/>}
      </div>
    )
    */
  }

  render() {
    this.init(this.props);
    return this.langBar(this.props.index);
  }

}

export default RecorderBar;
