import React from "react";
import ReactKonva from "react-konva";
import Konva from "konva";
import { v1 as uuidv1 } from 'uuid';

export const Line = ({ editMode, shapeProps, isSelected, onSelect, onChange }) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  React.useEffect(() => {
    if (isSelected && editMode) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
    // eslint-disable-next-line
  }, [isSelected]);

  return (
    <React.Fragment>
      <ReactKonva.Line
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        draggable={editMode}
        {...shapeProps}
        onDragStart={e => {
          onChange({
            ...shapeProps,
            isDragging: true
          })
        }}
        onDragEnd={e => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
            isDragging: false
          });
        }}
        onTransformEnd={e => {
          // transformer is changing scale
          const node = shapeRef.current;

          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            rotation: node.rotation(),
            scaleX: node.scaleX(),
            scaleY: node.scaleY(),
          });
        }}
      />
      {isSelected && editMode && <ReactKonva.Transformer ref={trRef} />}
    </React.Fragment>
  );
};


export const addLine = (stage, layer, status, color, mode = "brush") => {
  let isPaint = false;
  let lastLine;
  const id = uuidv1();

  if(status === 'on'){
    stage.on("mousedown touchstart", function(e) {
      isPaint = true;
      let pos = stage.getPointerPosition();
      lastLine = new Konva.Line({
        stroke: mode === "brush" ? color? color: "black" : "white",
        strokeWidth: mode === "brush" ? 8 : 25,
        globalCompositeOperation:
          mode === "brush" ? "source-over" : "destination-out",
        points: [pos.x, pos.y],
        draggable: true,
        id
      });
      layer.add(lastLine);
    });
    stage.on("mouseup touchend", function() {
      isPaint = false;
    });
    stage.on("mousemove touchmove", function() {
      if (!isPaint) {
        return;
      }
      const pos = stage.getPointerPosition();
      let newPoints = lastLine.points().concat([pos.x, pos.y]);
      lastLine.points(newPoints);
      layer.batchDraw();
    });
  }
  else if(status === 'off'){
    stage.off("mousedown touchstart");
    stage.off("mouseup touchend");
    stage.off("mousemove touchmove");
  }
  
};