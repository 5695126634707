import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
        margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));

function textToIcon(icon){
    switch(icon){
        case 'Add':
            return <AddIcon />;
        default:
            break;
    }
}

function MaterialButton(props) {
    const classes = useStyles();
    const name = props.name;
    const icon = props.icon;
    const id = "contained-button" + props.type;

    return (
        <div className={classes.root}>
            <input
                accept="image/*"
                className={classes.input}
                id={id}
                multiple
                type="text"
            />
            {icon ?
            <IconButton aria-label={icon} style={{color: '#91c33b'}} onClick={props.onClick}>
                {textToIcon(icon)}
            </IconButton> :
            <label htmlFor="contained-button">
                <Button variant="contained" color="primary" component="span" onClick={props.onClick}>
                    {name}
                </Button>
            </label>}
        </div>
    );
}

export default MaterialButton; 