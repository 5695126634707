import React from 'react';
import Content from './Content';
import GameCell from 'components/main/items/cells/GameCell';

import icon_match from 'resources/images/icons/match.png';
import icon_memory_match from 'resources/images/icons/memory_match.png';

class Games extends Content {

  content = style =>(
    <div style={{...this.ui.styles.area,
      ...{ height: style.height, opacity: style.opacity}}}>
      <GameCell app={this.app} icon={icon_match} data={{}} title={this.func.multiLang('Match', '配對','配对')}
      type={'matchGameList'} onClick={()=>{ this.actions.content.pushView('matchGameList'); }}/>
      <GameCell app={this.app} icon={icon_memory_match} data={{}} title={this.func.multiLang('Memory match', '記憶配對','记忆配对')}
      type={'memoryGameList'} onClick={()=>{ this.actions.content.pushView('memoryGameList'); }}/>
    </div>
  )

  render() {
    this.init(this.props);

    const hide = this.store.switches.hide.games;

    const title = ['Games','遊戲','游戏']

    const containerStyle = {
      width: '100%',
      height: '',
      background: this.ui.colors.gradientBasic
    }

    return(
      <div style={containerStyle}>
        {this.tabBar(title, hide, ()=>{this.actions.switches.toggleHide('games')})}
        {this.animatedContent('games', this.content.bind(this), !hide, this.bs.height * 0.27)}
      </div>
    )
  }
}

export default Games;
