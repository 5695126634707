import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';
import MatchGameRankingRow from 'components/main/items/rows/MatchGameRankingRow';

class MatchRanking extends SubView {

  constructor(props){
    super(props);
    this.init(props);
    this.getMatchGameRanking();
  }

  getMatchGameRanking(){
    this.matchGameId = this.store.matchGames.viewingMatchGamePublish._id;
    this.ranking = this.store.matchGames.rankings[this.matchGameId];
    this.actions.matchGames.getRanking(this.matchGameId);
    /*
    if(!this.ranking){
      this.actions.matchGames.getRanking(this.matchGameId);
    }
    */
  }

  rankingList(ranking){
    return ranking.map((data,i)=>{
      return <MatchGameRankingRow key={i} app={this.app} data={data} index={i}/>
    })
  }

  render(){
    this.matchGameId = this.store.matchGames.viewingMatchGamePublish._id;
    this.ranking = this.store.matchGames.rankings[this.matchGameId];

    return(
      <div style={this.subViewStyle()}>
        {this.ranking && this.rankingList(this.ranking)}
      </div>
    )
  }



}

export default MatchRanking;
