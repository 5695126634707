import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';
import StatisticsCard from 'components/main/items/ui/StatisticsCard';
import StatisticsCardWordCount from 'components/main/items/ui/StatisticsCardWordCount';
import ReactBarChart from 'components/main/items/graph/ReactBarChart';
import ReactLineChart from 'components/main/items/graph/ReactLineChart';
import { Grid } from '@material-ui/core';


class CourseStatistics extends SubView {

  constructor(props){
    super(props);
    this.init(props);
    this.state = {
      cardStudentGraphData: {},
      cardDateGraphData: {},
      cardMonthGraphData: {}
    }
  }
  UNSAFE_componentWillReceiveProps(newProps){
    if(!this.stat){
      this.stat = this.store.content.statistics[this.store.courses.viewingCourse._id];
    }
    /*
    if(this.stat){
      const cardStudentGraphData = this.groupByProfiles(this.stat.cardStudentGraphData, this.stat.profiles);
      const cardDateGraphData = this.groupByData(this.stat.cardDateGraphData, 'date');
      const cardMonthGraphData = this.groupByData(this.stat.cardMonthGraphData, 'month');
  
      this.setState({
        cardStudentGraphData: cardStudentGraphData,
        cardDateGraphData: cardDateGraphData,
        cardMonthGraphData: cardMonthGraphData
      })
    }
    */
  }

  componentDidMount(){
    this.getCourseStatistic();
  }

  getCourseStatistic(){
    if(!this.stat){ this.actions.courses.getStatistics(this.store.courses.viewingCourse._id); }
  }

  statTextDisplay(text){
    return this.textDisplay(text, ['100%',''], '100%', 'center');
  }

  dateToName(date){
    const day = date.getDate();
    const month = date.getMonth() + 1;
    return day + '/' + month;
  }

  monthToName(date){
    const month = date.getMonth();
    switch (month) {
      case 0:
        return 'Jan';
      case 1:
        return 'Feb';
      case 2:
        return 'Mar';
      case 3:
        return 'Api';
      case 4:
        return 'May';
      case 5:
        return 'Jun';
      case 6:
        return 'Jul';
      case 7:
        return 'Aug';
      case 8:
        return 'Sep';
      case 9:
        return 'Oct';
      case 10:
        return 'Nov';
      case 11:
        return 'Dec';
      default:
        return '';
    }
  }

  groupByData(data, property){
    return data.reduce((acc, obj) => {
        const objPropery = obj[property];
        const date = new Date(objPropery);
        const key = date.getFullYear();
        if(!acc[key]){
            acc[key] = {xAxis: [], yAxis: []};
        }
        if(property === 'date'){
          acc[key]['xAxis'].push(this.dateToName(date));
          acc[key]['yAxis'].push(obj['value']); 
        }
        else if(property === 'month'){
          acc[key]['xAxis'].push(this.monthToName(date));
          acc[key]['yAxis'].push(obj['value']); 
        }
        return acc;
    }, {});
  }

  groupByProfiles(data, profiles){
    var newData = {xAxis:[], yAxis:[]};
    for(var i=0; i<data.length;i++){
      for(var j=0;j<profiles.length;j++){
        if(data[i].student === profiles[j].belongTo){
          newData['xAxis'].push(profiles[j].name);
          newData['yAxis'].push(data[i].value);
        }
      }
    }
    return newData;
  }

  render() {
    this.init(this.props);
    if(!this.stat){
      return(
        <div style={this.subViewStyle()}>
          {this.subTitle(['Loading...','載入中...','载入中...'])}
        </div>
      );
    }

    const containerStyle = {
      marginTop: '1%',
    };

    return(
      <div style={{...this.subViewSafariStyle(), ...containerStyle}}>
        <Grid
        container
        spacing={2}
        style={{width: '95%'}}
        >
          <Grid item xs={12} sm={4} lg={4} xl={2}> 
            <StatisticsCard title={this.subTitle(['Students','學生','学生'])} value={this.stat.courseStudents.length}/>
          </Grid>
          <Grid item xs={12} sm={4} lg={4} xl={2}> 
            <StatisticsCard title={this.subTitle(['Unit','單元','单元'])} value={this.stat.course.subjects.length}/>
          </Grid>
          <Grid item xs={12} sm={4} lg={4} xl={2}> 
            <StatisticsCard title={this.subTitle(['Project','專題研習','专题研习'])} value={this.stat.courseProjects.length}/>
          </Grid>
          <Grid item xs={12} sm={4} lg={4} xl={2}> 
            <StatisticsCard title={this.subTitle(['Card (Featured)','卡片(精選)','卡片(精选)'])} value={this.stat.courseCards.length + ' (' + this.stat.featuredCount + ')'}/>
          </Grid>
          <Grid item xs={12} sm={4} lg={4} xl={2}> 
            <StatisticsCard title={this.subTitle(['Lang','語言欄','语言栏'])} value={this.stat.courseLangs.length}/>
          </Grid>
          {Object.keys(this.stat.langCharFreq).map((key, i) => {
            return (
              <Grid key={i} item xs={12} sm={4} lg={4} xl={2}>
                <StatisticsCardWordCount title={this.subTitle(['Mostly Used Characters (Count) ' + this.func.langKeyToLangName(key),'常用字(次數) ' + this.func.langKeyToLangName(key),'常用字(次数) ' + this.func.langKeyToLangName(key)])} data={this.stat.langCharFreq[key]}/>
              </Grid>
            )
          })}
          <Grid item xs={12} sm={12} lg={12} xl={12}> 
            <ReactBarChart app={this.app} data={this.groupByProfiles(this.stat.cardStudentGraphData, this.stat.profiles)} title={this.subTitle(['Card - Student Graph','卡片 - 學生圖表','卡片 - 学生图表'], '', 'left')}/>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <ReactLineChart app={this.app} data={this.groupByData(this.stat.cardDateGraphData, 'date')} title={this.subTitle(['Card - Date Graph','卡片 - 日期圖表','卡片 - 日期图表'], '', 'left')}/>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12}> 
            <ReactLineChart app={this.app} data={this.groupByData(this.stat.cardMonthGraphData, 'month')} title={this.subTitle(['Card - Month Graph','卡片 - 月份圖表','卡片 - 月份图表'], '', 'left')}/>
          </Grid>
        </Grid>
      </div>
    );
  }

}

export default CourseStatistics;
