import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';

import ProjectRow from 'components/main/items/rows/ProjectRow';
//import { setProjectLangAudio } from '../../../../../../../../redux/actions/data/projects';

class SubjectProjects extends SubView {

  constructor(props) {
    super(props);
    this.init(props);
    this.hidePassed = this.store.switches.hide.passedProjectsRows;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.init(newProps);
    this.hidePassed = this.store.switches.hide.passedProjectsRows;
    this.setListScroll('subjectProjectsList');
  }

  componentDidMount() {
    this.init(this.props);
    this.getSubjectProjects();
    this.getStudentProfiles();
  }

  getSubjectProjects() {
    const subject = this.store.subjects.viewingSubject;
    const projectsToGet = [];
    const projectsToShow = subject.projects;

    if (projectsToShow.length === 0 && this.store.user.type === 'teacher' && !this.inSchool) {
      this.actions.content.pushHint({ type: 'noProject' });
    }

    for (var i = 0; i < projectsToShow.length; i++) {
      if (this.func.getById.project(projectsToShow[i], this.store) === null) {
        projectsToGet.splice(0, 0, projectsToShow[i]);
      }
    }

    if (projectsToGet.length > 0) {
      this.actions.projects.getProjects(projectsToGet);
    }
  }

  routeLink(projectId) {
    const prevUrl = window.location.href;
    const currentUrl = prevUrl + '/project/' + projectId;
    this.actions.content.pushRouteLink(prevUrl);
    window.history.pushState({}, null, currentUrl);
  }

  async swapProjects(projects, index1, index2){
    var rowIndex1 = -1, rowIndex2 = -1;
    for(var i = 0; i < this.store.projects.projectRows.length; i++){
      if(projects[index1] === this.store.projects.projectRows[i].project._id)
        rowIndex1 = i;
      else if(projects[index2] === this.store.projects.projectRows[i].project._id)
        rowIndex2 = i;
    }
    
    var temp = this.store.projects.projectRows[rowIndex1].url;
    this.store.projects.projectRows[rowIndex1].url = this.store.projects.projectRows[rowIndex2].url;
    this.store.projects.projectRows[rowIndex2].url = temp;
    
    temp =  this.store.projects.projectRows[rowIndex1].project;
    this.store.projects.projectRows[rowIndex1].project =  this.store.projects.projectRows[rowIndex2].project;
    this.store.projects.projectRows[rowIndex2].project = temp;
    
    temp =  this.store.projects.projectRows[rowIndex1].state.alert;
    this.store.projects.projectRows[rowIndex1].state.alert =  this.store.projects.projectRows[rowIndex2].state.alert;
    this.store.projects.projectRows[rowIndex2].state.alert = temp;

    temp =  this.store.projects.projectRows[rowIndex1].outDated;
    this.store.projects.projectRows[rowIndex1].outDated =  this.store.projects.projectRows[rowIndex2].outDated;
    this.store.projects.projectRows[rowIndex2].outDated = temp;

  }

  getStudentProfiles(){
    const course = this.store.courses.viewingCourse;
    const profilesToGet = [];
    const profilesToShow = course.joinedStudents;
    for(var i=0;i<profilesToShow.length;i++){
      if(this.func.getById.profileByUser(profilesToShow[i], this.store) === null){
        console.log(profilesToShow[i]);
        profilesToGet.splice(0,0, profilesToShow[i]);
      }
    }
    if(profilesToGet.length > 0){
      this.actions.profiles.getProfiles(profilesToGet);
    }
  }

  projectsList() {
    var projectRowList = [];
    var projects = this.store.subjects.viewingSubject.projects;
    if (projects.length === 0) {
      return this.subTitle(['No projects', '此議題未有專題研習', '此议题未有专题研习'])
    }
    //console.log(projects);
   if(projects.length > 1)
    projects.sort((a, b) => {
      var aProject = this.func.getById.project(a, this.store);
      var bProject = this.func.getById.project(b, this.store);
      if(aProject !== null && bProject !== null)
        return aProject.createdAt.toString().substring(20, 23) - bProject.createdAt.toString().substring(20, 23);
      else
        return true;
    });
    //console.log(projects);
    return projects.slice(0).reverse().map((projectId, i) => {
      const project = this.func.getById.project(projectId, this.store);
      if (!project) { return null; }
      if (this.hidePassed && this.func.outDated(project.endDate) && !project.hide) { this.hasHided = true; return null; }
      if (this.store.user.type === 'student' && project.hide) { return null; }
      if (this.hidePassed){
        return <ProjectRow onClick={() => { this.actions.projects.viewProject(project); this.actions.content.pushView('project'); this.routeLink(project._id); }} app={this.app}
          
          handleUp={async (projectSelected, projectRow) => {
            var index = -1;
            let div = document.getElementById("div1" + projectSelected._id);
            div.style.backgroundColor = "white";
            if(this.hidePassed){
              const updatedProjects = projects.filter((p) => {
                var project = this.func.getById.project(p, this.store);
                var endDate = new Date(project.endDate); 
                return  endDate > Date.now();
              });
              for (var i = 0; i < updatedProjects.length; i++) {
                if (projectSelected._id === updatedProjects[i])
                  index = i;
              }

              if (index < updatedProjects.length - 1) { 
                this.actions.projects.swapProjects(updatedProjects[index], updatedProjects[index + 1]);
                this.swapProjects(updatedProjects, index, index + 1);
                setTimeout(() => {this.actions.projects.getProjects(updatedProjects);}, 200);
              }
            }
            else{
              for (i = 0; i < projects.length; i++) {
                if (projectSelected._id === projects[i])
                  index = i;
              }
  
              if (index < projects.length - 1) {
                this.swapProjects(projects, index, index + 1);
                this.actions.projects.swapProjects(projects[index], projects[index + 1]);
                setTimeout( () => {this.actions.projects.getProjects(projects);}, 200);
              }
            }
          }
        }

          handleDown={async (projectSelected) => {
            var index = -1;
            let div = document.getElementById("div1" + projectSelected._id);
            div.style.backgroundColor = "white";
            if(this.hidePassed){
              const updatedProjects = projects.filter((p) => {
                var project = this.func.getById.project(p, this.store);
                var endDate = new Date(project.endDate); 
                return  endDate > Date.now();
              });
              for (var i = 0; i < updatedProjects.length; i++) {
                if (projectSelected._id === updatedProjects[i])
                  index = i;
              }
              if (index > 0) {
                this.actions.projects.swapProjects(updatedProjects[index], updatedProjects[index - 1]);
                this.swapProjects(updatedProjects, index, index - 1);
                setTimeout(() => {this.actions.projects.getProjects(updatedProjects);}, 200);
              }
            }
            else{
              for (i = 0; i < projects.length; i++) {
                if (projectSelected._id === projects[i])
                  index = i;
              }
              if (index > 0) {
                this.swapProjects(projects, index, index - 1);
                this.actions.projects.swapProjects(projects[index], projects[index - 1]);
                setTimeout( () => {this.actions.projects.getProjects(projects);}, 200);
              }
            }
          }}

          project={project} key = {"ProjectRow" + i} index = {i} len = {projects.length}/>
      }
      else{
        return <ProjectRow onClick={() => { this.actions.projects.viewProject(project); this.actions.content.pushView('project'); this.routeLink(project._id); }} app={this.app}
          
          handleUp={async (projectSelected) => {
            var index = -1;
            let div = document.getElementById("div1" + projectSelected._id);
            div.style.backgroundColor = "white";
            for (var i = 0; i < projects.length; i++) {
              if (projectSelected._id === projects[i])
                index = i;
            }

            if (index < projects.length - 1) {
              this.swapProjects(projects, index, index + 1);
              this.actions.projects.swapProjects(projects[index], projects[index + 1]);
              setTimeout(() => {this.actions.projects.getProjects(projects);}, 200);
            }
          }}

          handleDown={async (projectSelected) => {
            var index = -1;
            let div = document.getElementById("div1" + projectSelected._id);
            div.style.backgroundColor = "white";
            for (var i = 0; i < projects.length; i++) {
              if (projectSelected._id === projects[i])
                index = i;
            }
            if (index > 0) {
              this.swapProjects(projects, index, index - 1);
              this.actions.projects.swapProjects(projects[index], projects[index - 1]);
              setTimeout( () => {this.actions.projects.getProjects(projects);}, 200);
            }
          }}

          project={project} projectRow = {projectRowList} key = {"ProjectRow" + i} index = {i} len = {projects.length}/>
      }
    }
    )

  }

  render() {
    this.init(this.props);
    return (
      <div style={this.subViewStyle()}>
          <div id={'subjectProjectsList'} onScroll={() => { this.onScroll('subjectProjectsList'); }} style={{ ...this.bs, ...this.ui.styles.list }}>
            {this.projectsList()}
            {this.hidePassed && this.hasHided && this.buttons.showHidden(() => {this.actions.switches.setAnimation('row', true); this.actions.switches.setHide('passedProjectsRows', false); })}
          </div>
      </div>
    )
  }
 
}
export default SubjectProjects