import React from 'react';
import View from 'components/main/pages/home/views/View';

class AddMatchGame extends View {

  constructor(props){
    super(props);
    this.init(props);
    const matchGame = this.store.matchGames.viewingMatchGamePublish;

    this.state = {
      modified: false,
      matchGameHide: this.props.editMode? matchGame.hide: false,
      courseToPost: this.props.editMode? matchGame.course: null,
      selectProjects: this.props.editMode? matchGame.projects: [],
      size: this.props.editMode? matchGame.size: 0,
      type: this.props.editMode? this.convertTypeToDisplay(matchGame.type): this.convertTypeToDisplay('text'),
    }
  }

  componentDidMount(){
    this.init(this.props);
  }

  classSelection(){
    const fontSize = 0.03;
    const checkBoxSize = 0.035;
    const containerStyle = {
      width: this.bs.width * 0.675,
      height: this.bs.height * 0.15,
      border: '1px solid rgb(220,220,220)',
      flexShrink: 0,
      overflow: 'auto'
    }
    const optionsStyle = {
      marginBottom: '2%',
    }

    const teachingCoursesId = this.store.courses.teachingCourses;

    return(
      <div style={containerStyle}>
        {teachingCoursesId.map((teachingCourseId, id) => {
          const teachingCourse = this.func.getById.course(teachingCourseId, this.store);
          const title = teachingCourse.title;
          return(
            <div style={optionsStyle}>
              {this.checkBox(this.func.multiLang(title,title,title), this.state.courseToPost === teachingCourseId, (e)=>{this.setState({ courseToPost: teachingCourseId, selectProjects: [], modified: true })}, 'black', fontSize, checkBoxSize, true)}
            </div>
          )
        })}
      </div>
    )
  }


  projectSelection(){
    const fontSize = 0.03;
    const checkBoxSize = 0.035;
    const containerStyle = {
      width: this.bs.width * 0.675,
      height: this.bs.height * 0.15,
      border: '1px solid rgb(220,220,220)',
      flexShrink: 0,
      overflow: 'auto'
    }
    const optionsStyle = {
      marginBottom: '2%',
    }

    const course = this.func.getById.course(this.state.courseToPost, this.store);
    const storeSubjects = this.store.subjects.subjects
    var courseSubjects = [], teachingSubjects = [], teachingProjectsId = [];
    if(!course){ return null; }
    courseSubjects = course.subjects;

    for(var i=0; i < courseSubjects.length; i++){
      for(var j=0; j < storeSubjects.length; j++){
        if(courseSubjects[i] === storeSubjects[j]._id){
          const subject = this.func.getById.subject(courseSubjects[i], this.store);
          teachingSubjects.push(subject);
          break;
        }
      }
    }

    teachingSubjects.map((subject, i) => { teachingProjectsId = [...teachingProjectsId, ...subject.projects]; return null; })

    return(
      <div style={containerStyle}>
        {teachingProjectsId.slice(0).reverse().map((teachingProjectId, id) => {
          const teachingProject = this.func.getById.project(teachingProjectId, this.store);
          const title = teachingProject.title;
          const index = this.state.selectProjects.indexOf(teachingProjectId);

          return(
            <div style={optionsStyle}>
              {this.checkBox(this.func.multiLang(title,title,title), index > -1? true: false, (e)=>{
                if(index > -1){
                  const newSelectProjects = this.state.selectProjects;
                  newSelectProjects.splice(index, 1);
                  this.setState({ selectProjects: newSelectProjects, modified: true });
                }
                else{
                  this.setState({ selectProjects: [...this.state.selectProjects, teachingProjectId], modified: true });
                }
              }, 'black', fontSize, checkBoxSize, true)}
            </div>
          )
        })}
      </div>
    )
  }

  questionSizeSelection(min){
    const teachingProjects = this.state.selectProjects;
    if(!teachingProjects){ return null; }
    var cards = [], teachingCards = [], options = [];

    for(var i=0; i < teachingProjects.length; i++){
      const project = this.func.getById.project(teachingProjects[i], this.store);
      if(project){
        for(var j=0; j < project.studentProjects.length; j++){
          const studentProject = this.func.getById.studentProject(project.studentProjects[j], this.store);
          if(studentProject){ cards = [...cards, ...studentProject.cards]; }
        }
      }
    }

    cards.map((cardId, i) => {
      const card = this.func.getById.card(cardId, this.store);
      if(card && card.grade === 'featured'){ teachingCards.push(cardId); }
      return null;
    });

    for(var k=1; k <= teachingCards.length; k++){ options.push(k); }

    if(options.length > min - 1){ options = options.slice(min - 1, options.length); }


    return(
      <div>
        {this.inputs.optionBar('questionSize', ['100%','100%'], options, this.props.editMode?this.state.size:options[0], (e)=>{this.setState({ /*size: e.target.value,*/ modified: true}) })}
      </div>
    )
  }

  questionTypeSelection(){
    const language = this.store.main.language;
    const engType = ['text', 'audio', 'both'];
    const chiType = ['文本', '音頻', '兩者皆可'];
    const simChiType = ['文本', '音频', '两者皆可'];
    const type = language === 'english' ? engType : language === 'chinese' ? chiType : simChiType;

    return(
      <div>
        {this.inputs.optionBar('questionType', ['100%','100%'], type, this.props.editMode?this.state.type:type[0], (e)=>{this.setState({ modified: true}) })}
      </div>
    )
  }

  setHideMatchGame(){
    const fontSize = 0.03;
    const checkBoxSize = 0.035;
    const optionsStyle = {
      width: this.bs.width * 0.65,
      height: '',
    }
    const hide = this.state.matchGameHide? 'true': 'false';

    return(
      <div style={optionsStyle}>
        {this.checkBox(this.func.multiLang('Yes','是','是'), hide === 'true', (e)=>{this.setState({ matchGameHide: true, modified: true })}, 'black', fontSize, checkBoxSize, true)}
        {this.gap(this.bs.height * 0.02)}
        {this.checkBox(this.func.multiLang('No','否','否'), hide === 'false', (e)=>{this.setState({ matchGameHide: false, modified: true })}, 'black', fontSize, checkBoxSize, true)}
      </div>
    )
  }

  convertTypeToDisplay(type){
    const language = this.store.main.language;
    const engType = ['text', 'audio', 'both'];
    const chiType = ['文本', '音頻', '兩者皆可'];
    const simChiType = ['文本', '音频', '两者皆可'];
    const index = engType.indexOf(type);
    return language === 'chinese' ? chiType[index] : language === 'simplified_chinese' ? simChiType[index] : type;
  }

  convertTypeToSubmit(type){
    const language = this.store.main.language;
    const engType = ['text', 'audio', 'both'];
    const chiType = ['文本', '音頻', '兩者皆可'];
    const simChiType = ['文本', '音频', '两者皆可'];
    var index = -1;
    if(language === 'chinese'){ index = chiType.indexOf(type); }
    else if(language === 'simplified_chinese'){ index = simChiType.indexOf(type); }
    return language === 'english' ? type : engType[index];
  }

  render() {
    this.init(this.props);
    var defaultDate = new Date();
    defaultDate.setDate(defaultDate.getDate() + 7);
    const matchGame = this.store.matchGames.viewingMatchGamePublish;


    return(

      <div style={this.viewStyle()}>
        {this.gap('4%')}


        {this.subTitle(['Title','標題','标题'])}
        {this.sep()}
        {this.inputs.inputField('matchGameTitle','text', '', this.props.editMode? matchGame.title:'' , ()=>{ this.setState({modified: true})})}
        {this.gap('2%')}

        {this.subTitle(['Description','描述','描述'])}
        {this.sep()}
        {this.gap('0.5%')}
        {this.inputs.textArea('matchGameDesc', '', this.props.editMode? matchGame.description:'', ()=>{ this.setState({modified: true})})}
        {this.gap('4%')}

        {this.subTitle(['Post to a class (single selection)','發佈給班別 (單選)','發佈给班别 (单选)'])}
        {this.sep()}
        {this.gap('0.5%')}
        {this.classSelection()}
        {this.gap('4%')}

        {this.subTitle(['Select projects for the game (multiple selection)','選擇遊戲的專題研習 (多選)','选择游戏的專題研習 (多选)'])}
        {this.sep()}
        {this.gap('0.5%')}
        {this.projectSelection()}
        {this.gap('4%')}

        {this.subTitle(['Select the number of questions','選擇題目數量','选择题目数量'])}
        {this.sep()}
        {this.gap('0.5%')}
        {this.questionSizeSelection(4)}
        {this.gap('4%')}

        {this.subTitle(['Select the type of questions (text or audio or both)','選擇題目的類型 (文本或音頻或兩者皆可)','选择题目的类型 (文本或音频或两者皆可)'])}
        {this.sep()}
        {this.gap('0.5%')}
        {this.questionTypeSelection()}
        {this.gap('4%')}

        {this.subTitle(['Hide','隱藏','隐藏'])}
        {this.sep()}
        {this.gap('1%')}
        {this.setHideMatchGame()}
        {this.gap('5%')}

        {this.subTitle(['End date','結束日期','结束日期'])}
        {this.sep()}
        {this.inputs.inputField('matchGameEndDate','date', ['',''], this.func.dateString(this.props.editMode? new Date(matchGame.endDate):defaultDate) , ()=>{ this.setState({modified: true})})}
        {this.gap('2%')}

        {this.buttons.rectGreen(['Confirm','確定','确定'], ()=>{this.addProject()})}
        {this.gap('8%')}
      </div>

    )
  }

  addProject(){

    const editMode = this.props.editMode;
    const user = this.store.user;
    const title = document.getElementById('matchGameTitle').value;
    const description = document.getElementById('matchGameDesc').value;
    const endDate = document.getElementById('matchGameEndDate').value;
    const size = document.getElementById('questionSize').value;
    const type = this.convertTypeToSubmit(document.getElementById('questionType').value);

    const hide = this.state.matchGameHide ? this.state.matchGameHide : false;
    const matchGame = this.store.matchGames.viewingMatchGamePublish;

    const today = new Date();
    const selectedEndDate = new Date(endDate);
    if(title.length === 0){
      return this.failedMessage(['Failed to add! Title is missing!', '創建失敗! 未填標題!','创建失败! 未填标题!'])
    }
    if(!this.state.courseToPost){
      return this.failedMessage(['Failed to add! Course is missing!', '創建失敗! 未選擇班別!','创建失败! 未选择班别!'])
    }
    if(this.state.selectProjects.length <= 0){
      return this.failedMessage(['Failed to add! Projects are missing!', '創建失敗! 未選擇專題研習!','创建失败! 未选择專題研習!'])
    }
    if(size < 4){
      return this.failedMessage(['Failed to add! Number of questions is at least 4! Please add more projects to increase the number of questions!', '創建失敗! 請添加更多專題研習以增加題目數量!','创建失败! 请添加更多專題研習以增加题目数量!'])
    }
    if(selectedEndDate < today){
      return this.failedMessage(['Failed to add! End date is in the past!', '創建失敗! 結束日期早於現在!','创建失败! 结束日期早于现在!'])
    }

    if(!editMode){
      this.actions.matchGames.addPublish({
        author: user._id,
        course: this.state.courseToPost,
        projects: this.state.selectProjects,
        title: title,
        description: description,
        size: size,
        type: type,
        endDate: endDate,
        hide: hide
      });
    }
    else if(this.state.modified){
      this.actions.matchGames.editPublish({
        matchGame: matchGame,
        course: this.state.courseToPost,
        projects: this.state.selectProjects,
        title: title,
        description: description,
        size: size,
        type: type,
        endDate: endDate,
        hide: hide
      });
    }else{
      return this.failedMessage(['Failed to add! Nothing is modified!', '提交失敗!未作出更改!', '提交失败!未作出更改!'])
    }
  }
}

export default AddMatchGame;
