//import * as reducer from '../reducer';
import reducer from '../reducer';


const subjectsReducer = (
  state = {
    subjects: [],
    schoolSubjects: [],
    viewingSubject: {},
    subjectRows: [],
    teachingSubjects: [],
    joinedSubjects: [],
    deleteIconAction: false,
    deletedIcons: [],
    subjectToCopy: null,
    tags: [],
    tagStatus: 'default',
  }, action)=>{
  switch (action.type) {
    case 'updateSubjects':
      return {...state, subjects: reducer.updateElements(state.subjects, action.payload)};
    case 'updateSchoolSubjects':
      return {...state, schoolSubjects: reducer.updateElements(state.schoolSubjects, action.payload)};
    case 'updateJoinedSubjects':
        return {...state, joinedSubjects: reducer.updateElements(state.joinedSubjects, action.payload, true)};
    case 'updateTeachingSubjects':
        return {...state, teachingSubjects: reducer.updateElements(state.teachingSubjects, action.payload, true)};
    case 'viewSubject':
      return {...state, viewingSubject: action.payload};
    case 'deleteSubjectIconAction':
      return {...state, deleteIconAction: action.payload};
    case 'pushSubjectDeletedIcon':
      return {...state, deletedIcons: [...state.deletedIcons, action.payload]};
    case 'removeSubjectDeletedIcon':
      const newDeletedIcons = state.deletedIcons;
      const index = newDeletedIcons.indexOf(action.payload);
      if(index > -1){
        newDeletedIcons.splice(index, 1);
        return {...state, deletedIcons: newDeletedIcons}
      }
      return state;
    case 'setSubjectDeletedIcon':
      return {...state, deletedIcons: action.payload};
    case 'setSubjectTags':
      return {...state, tags: action.payload.tags, tagStatus: action.payload.status};
    case 'setCopySubject':
      return {...state, subjectToCopy: action.payload};
    default:
      return state;
  }
}

export default subjectsReducer;
