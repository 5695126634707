import React from 'react';
import UI from 'components/UI';
import {Motion, spring}  from 'react-motion';

import PickerOption from './PickerOption';

import avatar1 from 'resources/images/default/profile/avatar1.png';
import avatar2 from 'resources/images/default/profile/avatar2.png';
import avatar3 from 'resources/images/default/profile/avatar3.png';
import avatar4 from 'resources/images/default/profile/avatar4.png';

import ycis from 'resources/images/default/school/ycis.jpg';
import nsm from 'resources/images/default/school/nsm.jpg';
import stcc from 'resources/images/default/school/stcc.jpg';
import yckss from 'resources/images/default/school/yckss.png';

import A from 'resources/images/default/course/A.png';
import B from 'resources/images/default/course/B.png';
import C from 'resources/images/default/course/C.png';
import D from 'resources/images/default/course/D.png';
import E from 'resources/images/default/course/E.png';
import F from 'resources/images/default/course/F.png';
import G from 'resources/images/default/course/G.png';
import H from 'resources/images/default/course/H.png';
import idol from 'resources/images/default/subject/idol.png';
import animal from 'resources/images/default/subject/animal.png';
import family from 'resources/images/default/subject/family.jpg';
import hongkong from 'resources/images/default/subject/hongkong.png';

class DefaultImagePicker extends UI {

  constructor(props){
    super(props);
    this.init(props);
    this.state = {
      defaultImages: {
        avatars: [[avatar1, avatar2, avatar3, avatar4]],
        schools: [[ycis, nsm, stcc, yckss]],
        alphabet: [[A, B, C, D], [E, F, G, H]],
        theme: [[idol, animal, family, hongkong]],
      },
      type: 'avatars',
      cellPointed: false
    }
  }

  getDefaultType(){
    switch (this.store.main.defaultImagePicker) {
      case 'school':
        return 'schools';
      case 'course':
        return 'alphabet';
      case 'project':
        return 'theme';
      case 'subject':
        return 'theme';
      case 'card':
        return 'theme';
      case 'profile':
        return 'avatars';
      case 'off':
        return null;
      default:
        return this.state.type;
    }
  }

  addDefaultIcons(originalIcons){
    var imageSet = [], eachSet = [], defaultIcons = null;
    const project = this.store.projects.viewingProject;
    const subject = this.func.getById.subject(project.subject, this.store);

    if(subject){
      defaultIcons = [...subject.defaultIcons];
    }
    if(project){
      defaultIcons = [...defaultIcons, ...project.defaultIcons];
    }

    imageSet.push(originalIcons);
    for(var i=0;i < defaultIcons.length; i++){
      eachSet.push(defaultIcons[i]);
      if(eachSet.length >= 4 || i === defaultIcons.length-1){
        imageSet.push(eachSet);
        eachSet = [];
      }
    }
    return imageSet;
  }

  UNSAFE_componentWillReceiveProps(newProps){
    this.init(newProps);
    const type = this.getDefaultType();
    this.setState({ type: type });
  }

  render() {
    this.init(this.props);
    const status = this.store.main.defaultImagePicker;
    //console.log(this.buttons.bs)
    const isOpen = status !== 'off';

    const pickerStyle = {...this.bs, ...this.ui.styles.container, ...{
      position: 'absolute',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,0.95)',
      pointerEvents: isOpen? 'auto': 'none',
    }}

    return(
      <Motion defaultStyle={{opacity: 0}}
      style={{opacity: this.atHome? 0: isOpen?spring(1.5):spring(0)}}>
        {style=>(
          <div style={{...pickerStyle, ...{opacity: style.opacity}}}>
            {this.buttons.absoluteClose(()=>{this.actions.main.setDefaultImagePicker('off')})}
            {this.defaultImages()}
          </div>
        )}
      </Motion>
    )
  }

  defaultImages(){
    const outerContainerStyle = {
      width: this.bs.width * 0.9,
      height: this.bs.height * 0.75,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }
    const innerContainerStyle = {
      marginTop: '20%',
      height: this.bs.height * 0.35,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      overflow: 'auto',
    }
    const containerStyle = {...this.ui.styles.area, ...this.ui.styles.container, ...{
      flexFlow: 'row nowrap',
    }}


    const pickerType = this.store.main.defaultImagePicker;
    var imageSet = this.state.defaultImages[this.state.type];
    if(!imageSet){ return null; }
    if(pickerType === 'card'){
      imageSet = this.addDefaultIcons([]);
    }

    return(
      <div style={outerContainerStyle}>
        <div style={innerContainerStyle}>
          {imageSet.map((images, i)=>{
            //console.log(images)
            return(
              <div key={i} style={containerStyle}>
                {images.map((img, i)=>{
                  return img.includes('subjectDefaultIcon') ? <PickerOption key={i} index={i} icon={img} type={'subjectDefaultIcon'} app={this.app} onPointed={()=>{this.setState({cellPointed: true})}} onUnPointed={()=>{this.setState({cellPointed: false})}}/>
                  : img.includes('projectDefaultIcon') ? <PickerOption key={i} index={i} icon={img} type={'projectDefaultIcon'} app={this.app} onPointed={()=>{this.setState({cellPointed: true})}} onUnPointed={()=>{this.setState({cellPointed: false})}}/>
                  : <PickerOption key={i} url={img} index={i} app={this.app} onPointed={()=>{this.setState({cellPointed: true})}} onUnPointed={()=>{this.setState({cellPointed: false})}}/>
                })}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}





export default DefaultImagePicker;
