import React from 'react';
import View from 'components/main/pages/home/views/View';
import ImagePicker from 'components/main/items/ImagePicker';
import LangEditor from './editLangs/LangEditor';
import lamejs from 'lamejs';
class AddCard extends View {

  constructor(props){
    super(props);
    this.init(props);
    this.state = {
      url: null
    }
    this.card = this.store.cards.viewingCard;
    this.getIconUrl(props);
  }

  UNSAFE_componentWillReceiveProps(newProps){
    this.init(newProps);

    if((this.store.user._id === "633e28a8f2eeeb6a51412497" || this.store.user._id === "6203327a5d679a7b7544f29e" || this.store.user_id === "63be16d899848c06bb68c37c" || this.store.user.id === "mlang.ch.jy" || this.store.user.id === "ylpkps.teacher" || this.store.user.id === "chpsp.teacher" || this.store.user.id === "chpss.teacher") &&   this.store.cards.audioBuffer !== null){
      const audioBuffer = new Uint8Array(this.store.cards.audioBuffer.data);
      const wav = new Blob([audioBuffer], { type: 'audio/mpeg' });
      this.actions.langs.setLangAudio({index: this.store.langs.editLangs.length - 1, blob: wav});
      this.store.cards.audioBuffer = null;
    }
    this.card = newProps.app.store.cards.viewingCard;
    this.getIconUrl(newProps);
  }

  componentWillUnmount(){
    this.actions.main.setPhotoAttr(0);
  }

  async getIconUrl(props){
    if(!this.props.editMode || this.state.url){ return; }
    const url = await this.func.url(this.card.icon, 'cardIcon');
    this.setState({
      url: url
    });
  }

  render() {
    this.init(this.props);
    const editMode = this.props.editMode;
    const icon = this.store.main.photoBlob;

    if(this.props.editMode && this.props.resubmit && !icon){
      this.setPhoto(this.state.url);
    }

    return(
      <div style={this.viewStyle()}>
        {this.gap('4%')}

        {this.subTitle(['Icon','照片','照片'])}
        {this.sep()}
        <ImagePicker type={'card'} defaultUrl={this.state.url} app={this.app}/>
        {this.sep()}
        {(this.store.user._id === "633e28a8f2eeeb6a51412497" || this.store.user._id === "6203327a5d679a7b7544f29e" || this.store.user_id === "63be16d899848c06bb68c37c" || this.store.user.id === "mlang.ch.jy" || this.store.user.id === "ylpkps.teacher" || this.store.user.id === "chpsp.teacher" || this.store.user.id === "chpss.teacher") &&   <button style={{alignSelf: "end", marginRight: "1%", marginTop: "1%", height: "8%", width: "8%"}} onClick={() => {this.handleTextToSpeech();}}>  TTS </button>}
        {this.gap('2%')}

        {this.subTitle(['Lang rows','語言欄','语言栏'])}
        {this.sep()}
        <LangEditor editMode={editMode} defaultLangs={editMode? this.card.langs:null} app={this.app}/>
        {this.sep()}

        {this.buttons.rectGreen(['Submit','提交','提交'], ()=>{this.addCard()})}
        {this.gap('8%')}
      </div>
    )
  }

  async handleTextToSpeech(){
    if(this.store.langs.editLangs[0].text !== null){
      this.actions.modal.loadingMessage(['Downloading...', '正在下載...', '正在下载...']);
      await this.actions.cards.textToSpeech(this.store.langs.editLangs[this.store.langs.editLangs.length - 1].text); 
    }
  }

  compressAudio(){
    return new Promise ((resolve, reject) =>
    {
      let editLangs = this.store.langs.editLangs;
      let lastAudioIndex = -1;
      for(let i = 0; i < editLangs.length; i++){
        console.log(i, " ", editLangs);
        if(editLangs[i].audioBlob && editLangs[i].audioBlob.type === 'audio/wav'){
          lastAudioIndex = i;
        }
      }
      console.log(lastAudioIndex);
      if(lastAudioIndex === -1) resolve();
      for(var i=0;i<editLangs.length;i++){
        let blob = editLangs[i].audioBlob;
        if(editLangs[i].audioBlob &&  editLangs[i].audioBlob.type === 'audio/wav'){
          const fr = new FileReader();
          let index = i;
          let actions = this.actions;
          fr.onload = async (event) => {
            try{
              const dataView = new DataView(fr.result);
              var wav = lamejs.WavHeader.readHeader(dataView);
              if(wav.dataLen === undefined || wav.dataLen === 0)
              {
                return ;
              }
              let channelNum = wav.channels, sampleRate = wav.sampleRate;
              var samples = new Int16Array(fr.result, wav.dataOffset, wav.dataLen / 2);
              var buffer = [];
              var mp3enc = new lamejs.Mp3Encoder(channelNum, sampleRate, 128);
              var remaining = samples.length;
              var maxSamples = 1152;
              for(var i=0; remaining >= maxSamples; i+= maxSamples){
                  var mono = samples.subarray(i, i + maxSamples);
                  var mp3buf =  mp3enc.encodeBuffer(mono); 
                  if (mp3buf.length > 0) {
                      buffer.push(mp3buf);
                  }
                  remaining -= maxSamples; 
                }
          
                var flushData = mp3enc.flush();
                if(flushData.length > 0){
                  buffer.push(flushData);
                }
          
              blob = new Blob(buffer, {type: 'audio/mpeg'});
              console.log(blob, " ", index);
              actions.langs.setLangAudio({index: index, blob: blob});
              if(index === lastAudioIndex)
                resolve();
              //actions.modal.hideModal();
              //actions.main.setAudioRecorder({recording: false, onRecordStop: null});
            }
            catch(error){
              console.log(error);
              resolve();
            // actions.modal.hideModal();
            // actions.langs.setLangAudio({index: 0, blob: blob});
            }
          }
          fr.readAsArrayBuffer(blob);
        }
      }
    }, Promise.resolve())
  }
  async addCard(){
    await this.compressAudio();
    const editMode = this.props.editMode;
    const icon = this.store.main.photoBlob;
    const iconAttr = this.store.main.photoAttr;

    if(!editMode && icon === null){
      return this.failedMessage(['Failed to submit! Icon is missing!', '提交失敗! 未有照片!','提交失败! 未有照片!'])
    }
    let editLangs = this.store.langs.editLangs;
    var usedKeys = [];
    console.log(editLangs);
    for(var i=0;i<editLangs.length;i++){
      console.log(i, " ", editLangs[i]);
      if(usedKeys.includes(editLangs[i].key)){
        return this.failedMessage([
          'Failed to submit! Lang key duplicated! Please make sure each language appeared once only!',
          '提交失敗! 語言列不能重複! 請確定每種語言只出現一次!',
          '提交失败! 语言列不能重复! 请确定每种语言只出现一次!'])
      }
      var noText = false, noAudio = false;
      if(editLangs[i].text === ''){
        noText = true;
        //return this.failedMessage(['Failed to submit! Lang text missing!', '提交失敗! 語言列缺少文字!','提交失败! 语言列缺少文字!']);
      }
      if(!editLangs[i].defaultAudio && !editLangs[i].audioBlob){
        noAudio = true;
        //return this.failedMessage(['Failed to submit! Lang audio missing!', '提交失敗! 語言列缺少錄音!','提交失败! 语言列缺少录音!']);
      }
      if(noText && noAudio){
        return this.failedMessage(['Failed to submit! Lang text or audio is missing!', '提交失敗! 語言列缺少文字或錄音!','提交失败! 语言列缺少文字或录音!']);
      }
      usedKeys.splice(0,0,editLangs[i].key)
    }
    var maxDate = this.store.cards.viewingCards.length;
    if(this.store.cards.viewingCards.length > 0){
      const card = this.func.getById.card(this.store.cards.viewingCards[0], this.store);
      const date = Number(card.createdAt.substring(20,23));
      if(date >= maxDate) 
        maxDate = date + 1;
    }
    if(!editMode){
      this.actions.cards.addCard({
        icon: icon,
        iconAttr: {rotation: iconAttr.rotation},
        editLangs: editLangs,
        project: this.store.projects.viewingProject,
        studentProject: this.store.studentProjects.viewingStudentProject,
        author: this.store.user._id,
        isTeacher: this.store.user.type === 'teacher' || this.store.user.type === 'developer'
      },  maxDate);
      this.clearStoredLangText();
    }else if(!this.props.resubmit){
      this.actions.cards.editCard({
        card: this.card,
        newIcon: icon,
        iconAttr: {rotation: iconAttr.rotation},
        editLangs: editLangs
      }, maxDate);
    }else{
      this.actions.cards.addCard({
        resubmitCard: this.card._id,
        icon: icon,
        iconAttr: {rotation: iconAttr.rotation},
        editLangs: editLangs,
        project: this.store.projects.viewingProject,
        studentProject: this.store.studentProjects.viewingStudentProject,
        author: this.store.user._id
      }, maxDate);
    }
  }

  clearStoredLangText(){ for(var i=0;i<13;i++){ this.db.set('langText_' + i, null); } }
}

export default AddCard;
