import React from 'react';
import Row from './Row';

import icon_event from 'resources/images/icons/event_grey.png';
import FlipMove from 'react-flip-move';

class ProjectRow extends Row {

  constructor(props) {
    super(props);
    this.init(props);
    this.state = {
      filename: this.props.project ? this.props.project.icon : null,
      type: 'projectIcon',
      alert: this.func.checkAlert.project(this.props.project, this.props.app)
    }
    this.store.projects.projectRows.push(this);
    this.outDated = this.func.outDated(this.props.project.endDate);
    this.project = this.props.project;
    this.handleUp = this.props.handleUp;
    this.handleDown = this.props.handleDown;
    this.index= this.props.index;
    this.len = this.props.len;
    this.checkUrl();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.init(newProps);
    if (newProps.project && !this.state.filename) {
      this.setState({
        filename: newProps.project.icon,
        type: 'projectIcon',
      }, () => { this.checkUrl();});
    }
  }

  rowInfo() {
    const project = this.props.project;

    const rowStyle = {
      ...this.ui.styles.area, ...{
        width: '100%',
        height: this.bs.height * 0.06,
        alignItems: 'flex-end'
      }
    }
    const iconSize = this.bs.height * 0.05;
    if (this.store.user.type === "teacher") {
      return (
        <div style={rowStyle}>
          {this.icon(icon_event, [iconSize, iconSize])}
          {this.verGap('1%')}
          {this.textDisplay(this.func.dateString(new Date(project.endDate)), ['', ''], '150%')}
        </div>
      )
    }
    else {
      return (
        <div style={rowStyle}>
          {this.icon(icon_event, [iconSize, iconSize])}
          {this.verGap('1%')}
          {this.textDisplay(this.func.dateString(new Date(project.endDate)), ['', ''], '150%')}
        </div>
      )
    }
  }

  changeUpBackground(e){
    e.target.style.background = 'rgba(0, 255, 0, 0.4)';
    let div = document.getElementById("div1" + this.project._id);
    div.style.backgroundColor = "grey";
  }

  removeUpBackground(e){
    e.target.style.background = "green";
    let div = document.getElementById("div1" + this.project._id);
    div.style.backgroundColor = "white";
  }

  changeDownBackground(e){
    e.target.style.background = 'rgba(255, 0, 0, 0.4)';
    let div = document.getElementById("div1" + this.project._id);
    div.style.backgroundColor = "grey";
  }

  removeDownBackground(e){
    e.target.style.background = "red";
    let div = document.getElementById("div1" + this.project._id);
    div.style.backgroundColor = "white";
  }


  render() {  
    if (this.props.project === null) { return null; }

    if(this.store.user.type === "teacher" && this.index === 0)
      return this.animatedRow(this.firstContentForTeachers.bind(this), this.bs.height * 0.15)

    if(this.store.user.type === "teacher" && this.index === this.len - 1)
      return this.animatedRow(this.lastContentForTeachers.bind(this), this.bs.height * 0.15)

    if(this.store.user.type === "teacher")
      return this.animatedRow(this.contentForTeachers.bind(this), this.bs.height * 0.15)

    return this.animatedRow(this.contentForStudents.bind(this), this.bs.height * 0.15)
  }
  firstContentForTeachers = (style) => (
    <div id={"div1" + this.project._id} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "100%", borderBottom:"1px solid rgb(219, 219, 219)", borderImage: "initial", position:'relative' }}>
      <FlipMove duration={750} ease="linear" 
      enterAnimation={{
        from: {
          transform: "translateX(-100%)",
        },
        to: {}
      }}
      leaveAnimation={{
        from: {

        },
        to: { transform: "translateX(-100%)",
      }
      }}
      onFinish={()=>
        {  const project1 = document.getElementById("btn" + this.project._id);  
            let index = -1; 
            for(var i = 0; i < this.store.projects.projectRows.length; i++){  
              if(this.store.projects.projectRows[i].project._id === this.project._id)
                index = i;
            } 
            if(project1 !== null)
              project1.style.opacity = this.store.projects.projectRows[index].outDated ? 0.5 : 1;
          }}
      onFinishAll={()=>
        {  const project1 = document.getElementById("btn" + this.project._id);  
            let index = -1; 
            for(var i = 0; i < this.store.projects.projectRows.length; i++){  
              if(this.store.projects.projectRows[i].project._id === this.project._id)
                index = i;
            } 
            if(project1 !== null)
              project1.style.opacity = this.store.projects.projectRows[index].outDated ? 0.5 : 1;

        }}

      typeName={null}>
          <button   key={"btn2" + this.project._id} id={"btn" + this.project._id} onClick={() => { this.props.onClick(); this.setPhoto(this.url.url); }} style={{
            ...this.rowStyle(), ...{
              height: style.height,
              width: (this.len === 1)? "97%": "88%",
              borderBottom: 0,
              opacity: style.opacity * this.outDated || this.project.hide ? 0.5 : 1
            }
          }}>
          {/*this.verGap('2%')*/}
          {this.project.hide ? this.hiddenRowIcon(this.project.hide) : this.rowIcon(this.outDated)}
          {this.rowContent(this.props.project.title, this.rowInfo.bind(this))}
          {this.state.alert && this.alertTag()}
          </button>
        </FlipMove>
      { this.len > 1 && 
        <div d={"div2" + this.project._id} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center"}}>
          <button type="button" onMouseOver={(e)=>this.changeDownBackground(e)} onMouseLeave={(e)=>this.removeDownBackground(e)} style={{ backgroundColor: "red", color:"white", borderRadius: style.height * 0.05, cursor: "pointer", height: style.height * 0.3, width: style.height * 0.4 , margin:  style.height * 0.05, fontSize: style.height * 0.2, textAlign: "center", padding: 0}} onClick={() => this.handleDown(this.props.project, this)} key={this.project._id + "down"}>⇩</button>
        </div>
      }
    </div>
  )

  lastContentForTeachers = (style) => (
    <div id={"div1" + this.project._id} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "100%", borderBottom:"1px solid rgb(219, 219, 219)", borderImage: "initial", position: 'relative' }}>
      <FlipMove duration={750} ease="linear" 
        enterAnimation={{
          from: {
            transform: "translateX(-100%)",
          },
          to: {}
        }}
        leaveAnimation={{
          from: {

          },
          to: { transform: "translateX(-100%)",
          }
        }}
        onFinish={()=>
          {  const project1 = document.getElementById("btn" + this.project._id);  
              let index = -1; 
              for(var i = 0; i < this.store.projects.projectRows.length; i++){  
                if(this.store.projects.projectRows[i].project._id === this.project._id)
                  index = i;
              } 
              if(project1 !== null)
                project1.style.opacity = this.store.projects.projectRows[index].outDated ? 0.5 : 1;
            }}
        onFinishAll={()=>
        {  const project1 = document.getElementById("btn" + this.project._id);  
            let index = -1; 
            for(var i = 0; i < this.store.projects.projectRows.length; i++){  
              if(this.store.projects.projectRows[i].project._id === this.project._id)
                index = i;
            } 
            if(project1 !== null)
              project1.style.opacity = this.store.projects.projectRows[index].outDated ? 0.5 : 1;
          }}
        typeName={null}>
          <button   key={"btn2" + this.project._id} id={"btn" + this.project._id} onClick={() => { this.props.onClick(); this.setPhoto(this.url.url); }} style={{
            ...this.rowStyle(), ...{
              height: style.height,
              width: "88%",
              borderBottom: 0,
              opacity: style.opacity * this.outDated || this.project.hide ? 0.5 : 1
            }
          }}>
          {/*this.verGap('2%')*/}
          {this.project.hide ? this.hiddenRowIcon(this.project.hide) : this.rowIcon(this.outDated)}
          {this.rowContent(this.props.project.title, this.rowInfo.bind(this))}
          {this.state.alert && this.alertTag()}
          </button>
      </FlipMove>
    <div d={"div2" + this.project._id} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center"}}>
      <button type="button" onMouseOver={(e)=>this.changeUpBackground(e)} onMouseLeave={(e)=>this.removeUpBackground(e)}style={{ backgroundColor: "green", color:"white", borderRadius: style.height * 0.05, cursor: "pointer", height: style.height * 0.3, width: style.height * 0.4 , margin:  style.height * 0.05, fontSize: style.height * 0.2, textAlign: "center", padding: 0}} onClick={() => this.handleUp(this.props.project, this)} key={this.project._id + "up"}>⇧</button>
   </div>
  </div>
  )

  contentForTeachers = (style) => (
    <div id={"div1" + this.project._id} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "100%", borderBottom:"1px solid rgb(219, 219, 219)", borderImage: "initial", position: 'relative'}}>
    <FlipMove duration={750} ease="linear" 
      enterAnimation={{
        from: {
          transform: "translateX(-100%)",
  //        opacity: 0.1
        },
        to: {}
      }}
      leaveAnimation={{
        from: {

        },
        to: { transform: "translateX(-100%)",}
//        opacity: 0.1}
      }}
      onFinish={()=>
        {  const project1 = document.getElementById("btn" + this.project._id);  
            let index = -1; 
            for(var i = 0; i < this.store.projects.projectRows.length; i++){  
              if(this.store.projects.projectRows[i].project._id === this.project._id)
                index = i;
            } 
            if(project1 !== null)
              project1.style.opacity = this.store.projects.projectRows[index].outDated ? 0.5 : 1;
          }}
      onFinishAll={()=>
        {  const project1 = document.getElementById("btn" + this.project._id);  
            let index = -1; 
            for(var i = 0; i < this.store.projects.projectRows.length; i++){  
              if(this.store.projects.projectRows[i].project._id === this.project._id)
                index = i;
            } 

          if(project1 !== null)
            project1.style.opacity = this.store.projects.projectRows[index].outDated ? 0.5 : 1;
        }}
       typeName={null} > 
        <button  key={"btn2" + this.project._id} id={"btn" + this.project._id} onClick={() => { this.props.onClick(); this.setPhoto(this.url.url); }} style={{
          ...this.rowStyle(), ...{
            height: style.height,
            width: "88%",
            borderBottom: 0,
            opacity: style.opacity * this.outDated || this.project.hide ? 0.5 : 1
          }
        }}>
          {/*this.verGap('2%')*/}
          {this.project.hide ? this.hiddenRowIcon(this.project.hide) : this.rowIcon(this.outDated)}
          {this.rowContent(this.props.project.title, this.rowInfo.bind(this))}
          {this.state.alert && this.alertTag()}
        </button>
      </FlipMove>
      <div id={"div2" + this.project._id} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center"}}>
      <button type="button" onMouseOver={(e)=>this.changeUpBackground(e)} onMouseLeave={(e)=>this.removeUpBackground(e)} style={{ backgroundColor: "green", color:"white", borderRadius: style.height * 0.05, cursor: "pointer", height: style.height * 0.3, width: style.height * 0.4 , margin:  style.height * 0.05, fontSize: style.height * 0.2, textAlign: "center", padding: 0}} onClick={() => this.handleUp(this.props.project, this)} key={this.project._id + "up"}>⇧</button>
      <button type="button" onMouseOver={(e)=>this.changeDownBackground(e)} onMouseLeave={(e)=>this.removeDownBackground(e)} style={{ backgroundColor: "red", color:"white",borderRadius: style.height * 0.05, cursor: "pointer", height: style.height * 0.3, width: style.height * 0.4 , margin:  style.height * 0.05, fontSize: style.height * 0.2, textAlign: "center", padding: 0}} onClick={() => this.handleDown(this.props.project, this)} key={this.project._id + "down"}>⇩</button>
    </div>

  </div>
  )

  contentForStudents = (style) => (
      <button key={"btn2" + this.project._id} onClick={() =>{this.props.onClick(); this.setPhoto(this.url.url)}} style={{...this.rowStyle(), ...{
        height: style.height,
        opacity: style.opacity *  this.func.outDated(this.props.project.endDate)||this.props.project.hide? 0.5:1
      }}} >
        {this.verGap('3%')}
        {this.project.hide ? this.hiddenRowIcon(this.props.project.hide, this.store.content.cachedUrl[this.props.project.icon])  : this.rowIcon( this.func.outDated(this.props.project.endDate), this.store.content.cachedUrl[this.props.project.icon])  }
        {this.rowContent(this.props.project.title , this.rowInfo.bind(this)) /*this.rowContent(this.props.project.title, this.rowInfo.bind(this))*/}
        {this.state.alert && this.alertTag()}
      </button>
  )
}

export default ProjectRow;