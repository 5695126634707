//import * as reducer from '../reducer';
import reducer from '../reducer';

const matchGameReducer = (
  state = {
    matchGamePublishes: [],
    viewingMatchGamePublish: {},
    matchGameSubmits: [],
    viewingMatchGameSubmit: {},
    viewAction: 'init',
    viewingCard: {},
    viewingCards: [],
    langChoices: [],
    answers: [],
    rankings: {},
    mode: 'practice',
  }, action)=>{
  var rankings = state.rankings;
  switch (action.type) {
    case 'setAction':
      return {...state, viewAction: action.payload};
    case 'updateMatchGamePublishes':
      return {...state, matchGamePublishes: reducer.updateElements(state.matchGamePublishes, action.payload)};
    case 'viewMatchGamePublish':
      return {...state, viewingMatchGamePublish: action.payload};
    case 'updateMatchGameSubmits':
      return {...state, matchGameSubmits: reducer.updateElements(state.matchGameSubmits, action.payload)};
    case 'viewingMatchGameSubmit':
      return {...state, viewingMatchGameSubmit: action.payload};
    case 'viewMatchGameCards':
      return {...state, viewingCards: action.payload};
    case 'viewMatchGameCard':
      return {...state, viewingCard: action.payload};
    case 'pushLangChoices':
      return {...state, langChoices: [...state.langChoices, action.payload]};
    case 'setLangChoices':
      return {...state, langChoices: action.payload};
    case 'initAnswers':
      return {...state, answers: action.payload};
    case 'updateAnswers':
      state.answers.splice(action.index, 1, action.payload);
      return state;
    case 'setMatchGameRanking':
      rankings[action.payload.matchGameId] = action.payload.ranking;
      return {...state, rankings: rankings}
    case 'setMatchGameMode':
      return {...state, mode: action.payload}
    default:
      return state;
  }
}

export default matchGameReducer;
