import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';
import Cards from 'components/main/pages/home/views/home/contents/Cards';
import Filter from 'components/main/items/Filter';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import RemoveIcon from '@material-ui/icons/Remove';
import NoImageIcon from 'resources/images/general/no_image.png';


class ProjectFeatured extends SubView {

  constructor(props){
    super(props);
    this.init(props);
    this.cards = this.func.getAllFeaturedCardsIdInViewingProject();
    this.setCardsToView(this.store.content.filterOption);
    this.clicked = false;
  }

  UNSAFE_componentWillReceiveProps(newProps){
    this.init(newProps);
    this.cards = this.func.getAllFeaturedCardsIdInViewingProject();
    this.setCardsToView(this.store.content.filterOption);
  }

 

  setCardsToView(filterOption){
    var cardsToView = [];
    if(filterOption === 'All'){ 
      this.cards.map(cardId=>{
        const card = this.func.getById.card(cardId, this.store);
        var profile = this.func.getById.profileByUser(card.author, this.store);
        if(!profile){ profile = this.store.profile; }
        if(!card.hide){
          cardsToView.push(cardId)
        }
        return null;
      })
    }
    else{
      this.cards.map(cardId=>{
        const card = this.func.getById.card(cardId, this.store);
        var profile = this.func.getById.profileByUser(card.author, this.store);
        if(!profile){ profile = this.store.profile; }
        if(profile.name === filterOption && !card.hide){
          cardsToView.push(cardId)
        }
        return null;
      })
    }
    cardsToView = cardsToView.sort((a, b) => {
      var aCard = this.func.getById.card(a, this.store);
      var bCard = this.func.getById.card(b, this.store);
      return bCard.createdAt.toString().substring(20, 23) - aCard.createdAt.toString().substring(20, 23);
    });
    this.cardsToView = cardsToView;
  }


  cardViewStyle(){
    const opacity = this.app.animatedStyle.opacity;
   // console.log(this.bs);
    return {...this.bs, ...{
      height: this.bs.height * 0.82,
      overflowY: 'auto',
      opacity: this.app.animatedStyle.opacity,
      pointerEvents: opacity === 1? '':'none',
    }}
  }

  
  changeBackground(index){
    document.getElementById("btnPdf" + index).style.background = 'rgba(0, 255, 0, 0.4)';
    document.getElementById("imgPdf" + index).style.background = 'rgba(0, 255, 0, 0.4)';
  }

  removeBackground(index){
    document.getElementById("btnPdf" + index).style.background = "#6DB13E";
    document.getElementById("imgPdf" + index).style.background = "#6DB13E";

  }



  async print1CardPerPage(len, name, cards) {
    this.actions.modal.loadingMessage(['Downloading...', '正在下載...', '正在下载...']);
    var doc = new jsPDF('p', 'mm', [289, 203.2]);
    for(var i = 0; i < len; i++){
      const card = this.func.getById.card(cards[i], this.store);
      const url = await this.func.url(card.icon, "cardIcon");
      const input = document.getElementById('printPdf1_' + i);
      document.getElementById("pdf1Img_" + i).src = url? url: NoImageIcon;
      const curIndex = i;
      await html2canvas(input, {logging: true, letterRendering: 1, useCORS: true,
      onclone: function(clonedDoc){
        clonedDoc.getElementById('printPdf').style.display = "block";
      }, }).then(canvas => {
      var imgData = canvas.toDataURL('image/jpeg', 1.0);
      var imgWidth = 203.2; 
      var imgHeight = canvas.height * imgWidth / canvas.width;
      doc.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight, undefined,'FAST');
      if(curIndex !== len - 1 )
        doc.addPage();
      if(curIndex === len - 1)
        doc.save(name)

  })
  }
  this.actions.modal.hideModal();
  }

  async print2CardsPerPage(len, name, cards) {
    this.actions.modal.loadingMessage(['Downloading...', '正在下載...', '正在下载...']);
    var doc = new jsPDF('l', 'mm', [289, 203.2]);
    for(var i = 0; i < len; i++){
      const card = this.func.getById.card(cards[i], this.store);
      const url = await this.func.url(card.icon, "cardIcon");
      const input = document.getElementById('printPdf2_' + i);
      document.getElementById("pdf2Img_" + i).src = url? url: NoImageIcon;
      const curIndex = i;
      await html2canvas(input, {logging: true, letterRendering: 1, useCORS: true,
      onclone: function(clonedDoc){
        clonedDoc.getElementById('printPdf').style.display = "block";
      }, }).then(canvas => {
      var imgData = canvas.toDataURL('image/jpeg', 1.0);
      var imgWidth = 203.2; 
      var imgHeight = canvas.height * imgWidth / canvas.width;
      if(curIndex % 2 === 0){
        doc.addImage(imgData, 'JPEG', 0, 0, imgHeight / 2, imgWidth, undefined, 'FAST');
      }
      else{
        doc.addImage(imgData, 'JPEG', imgHeight / 2, 0 , imgHeight / 2, imgWidth, undefined,'FAST');
        if(curIndex !== len - 1 ) doc.addPage(); 
      }
      if(curIndex === len - 1)
        doc.save(name)

  })
  }
  this.actions.modal.hideModal();
  }

  async print4CardsPerPage(len, name, cards) {
    this.actions.modal.loadingMessage(['Downloading...', '正在下載...', '正在下载...']);
    var doc = new jsPDF('p', 'mm', [289, 203.2]);
    for(var i = 0; i < len; i++){
      const card = this.func.getById.card(cards[i], this.store);
      const url = await this.func.url(card.icon, "cardIcon");
      const input = document.getElementById('printPdf4_' + i);
      document.getElementById("pdf4Img_" + i).src = url ? url: NoImageIcon;
      const curIndex = i;
      await html2canvas(input, {logging: true, letterRendering: 1, useCORS: true,
      onclone: function(clonedDoc){
        clonedDoc.getElementById('printPdf').style.display = "block";
      }, }).then(canvas => {
      var imgData = canvas.toDataURL('image/jpeg', 1.0);
      var imgWidth = 203.2; 
      var imgHeight = canvas.height * imgWidth / canvas.width;
      if(curIndex % 4 === 0){
        doc.addImage(imgData, 'JPEG', 0, 0, imgWidth / 2, imgHeight / 2, undefined, 'FAST');
      }
      else if(curIndex % 4 === 1){
        doc.addImage(imgData, 'JPEG', imgWidth / 2, 0, imgWidth / 2, imgHeight / 2, undefined, 'FAST');
      }
      else if(curIndex % 4 === 2){
        doc.addImage(imgData, 'JPEG', 0, imgHeight / 2, imgWidth / 2, imgHeight / 2, undefined, "FAST");
      }
      else{
        doc.addImage(imgData, 'JPEG', imgWidth / 2, imgHeight / 2, imgWidth / 2, imgHeight / 2, undefined, 'FAST');
        if(curIndex !== len - 1 ) doc.addPage(); 
      }
      if(curIndex === len - 1)
            doc.save(name)

  })
  }
  this.actions.modal.hideModal();
  }
  toggleBtn(){
    this.actions.content.pushView('printPdf')
    /*
    if(document.getElementById("btnPdf1").style.display === "none"){
      document.getElementById("btnPdf1").style.display = "block";
      document.getElementById("btnPdf2").style.display = "block";
      document.getElementById("btnPdf4").style.display = "block";
      document.getElementById("pdfIcon").style.display = "none"
      document.getElementById("removeIcon").style.display = "block";
    }
    else
    {
      document.getElementById("btnPdf1").style.display = "none";
      document.getElementById("btnPdf2").style.display = "none";
      document.getElementById("btnPdf4").style.display = "none";
      document.getElementById("pdfIcon").style.display = "block"
      document.getElementById("removeIcon").style.display = "none";
    }
    */
  }
  render() {
    this.init(this.props);
    const outDated = this.func.outDated(this.store.projects.viewingProject.endDate);
    const isTeacher =
    (this.store.user.type === 'teacher' &&
    this.store.content.traces[1] !== 'school') || (this.store.user.type === 'developer');
    return(
      <div key={this.cardsToView.length} style={{...this.subViewStyle(), ...{alignItems: "flex-start"}}} >
        <Filter app={this.app} options={this.filterOptions()} defaultValue={this.store.content.filterOption} onChange={()=>{this.onFilterChange()}}/>
        {this.sep()}
          <Cards key="featureCards" app={this.app} cardsId={this.cardsToView} onAdd={(outDated || !isTeacher)? null:this.onAdd.bind(this)}  

            handleRight = {async (card) => {
              var index = -1;
              for(var i = 0; i < this.cardsToView.length; i++)
                if(card._id === this.cardsToView[i])
                  index = i;
              if(index < this.cardsToView.length - 1){
                this.actions.cards.swapCards(this.cardsToView[index], this.cardsToView[index + 1]);
                setTimeout(() => {this.actions.cards.getCards(this.cardsToView);}, 200);
              }
            }}

            handleLeft = {async (card) => {
              var index = -1;
              for(var i = 0; i < this.cardsToView.length; i++)
                if(card._id === this.cardsToView[i])
                  index = i;
              if(index > 0){
                  this.actions.cards.swapCards(this.cardsToView[index], this.cardsToView[index - 1]);
                  setTimeout(() => {this.actions.cards.getCards(this.cardsToView);}, 200);

              // this.actions.cards.swapCards(this.cardsToView[index], this.cardsToView[index - 1]);
              // this.actions.cards.getCards(this.cardsToView);
              }
              }}
          />
          {/*
            <button id="btnPdf1" onMouseOver={()=>this.changeBackground(1)} onMouseOut={()=>this.removeBackground(1)} onClick={() => {this.print1CardPerPage(this.cardsToView.length, this.store.projects.viewingProject.title + "_" + this.store.content.filterOption + "_1card.pdf",  this.cardsToView)}} style={{height: "50px", width: "60px", backgroundColor: "#6DB13E", cursor: "pointer", border: "0.5px solid black", borderRadius: "5px", display: "none", position: "absolute", left: 3, bottom: 210 }}> <img  id="imgPdf1" src={printPdf1} style={{height: "40px", width:"40px", marginTop: "4px", backgroundColor: "#6DB13E", }} alt="printPdf1"/> </button>
            <button id="btnPdf2"  onMouseOver={()=>this.changeBackground(2)} onMouseOut={()=>this.removeBackground(2)} onClick={() => {this.print2CardsPerPage(this.cardsToView.length, this.store.projects.viewingProject.title + "_" + this.store.content.filterOption + "_2cards.pdf", this.cardsToView)}} style={{height: "50px", width: "60px", backgroundColor: "#6DB13E", cursor: "pointer", border: "0.5px solid black",  borderRadius: "5px", display: "none", position: "absolute",  left: 3, bottom: 140 }}> <img  id="imgPdf2" src={printPdf2} style={{height: "40px", width:"40px", marginTop: "4px", backgroundColor: "#6DB13E", }} alt="printPdf2"/></button>
            <button id="btnPdf4" onMouseOver={()=>this.changeBackground(4)} onMouseOut={()=>this.removeBackground(4)} onClick={() => {this.print4CardsPerPage(this.cardsToView.length, this.store.projects.viewingProject.title + "_" + this.store.content.filterOption + "_4cards.pdf", this.cardsToView)}} style={{height: "50px", width: "60px", backgroundColor: "#6DB13E", cursor: "pointer", border: "0.5px solid black",  borderRadius: "5px", display: "none",  position: "absolute",   left: 3, bottom: 70 }}> <img id="imgPdf4" src={printPdf4} style={{height: "40px", width:"40px", marginTop: "4px", backgroundColor: "#6DB13E",}} alt="printPdf4"/> </button>
            */}
          <div style={{display: "flex", flexDirection: "row", }}>
            <button id="fab" style={{height: "50px", width: "50px", backgroundColor: "lightgrey", cursor: "pointer", border: "0.5px solid black", marginBottom: "1px", marginRight: "30px", borderRadius: "100%", fontSize: "30px", marginLeft: "7px", padding: 0}} onClick={() => this.toggleBtn()}><PdfIcon id="pdfIcon" style={{display: "block", position: "absolute", left: 19.5, bottom: 13 }}/>  <RemoveIcon id="removeIcon" style={{display: "none", position: "absolute", left: 19.5, bottom: 13}}/>   </button>
          </div>
          {/*
          <div id="printPdf" style={{display: "none"}} >
    
              {this.cardsToView ? this.cardsToView.map( (cardID, index) =>
               {
                const card = this.func.getById.card(cardID, this.store);
                if(card === null) return null;
                  var lang = "";
                  const firstLang = this.func.getById.lang(card.langs[0], this.store);
                  if(firstLang === null) return null;
                  for(var i = 0; i < this.store.langs.langKeys.length ; i++)
                  {
                    if(firstLang && firstLang.key  === this.store.langs.langKeys[i].key){
                      lang = this.func.multiLang(this.store.langs.langKeys[i].name[0], this.store.langs.langKeys[i].name[1], this.store.langs.langKeys[i].name[2]);
                    }
                  }
                  let wordLen = 0;
                  if(firstLang.text !== null){
                  for(i = 0; i < firstLang.text.length; i++)
                    if(firstLang.text[i].charCodeAt() <= 255) wordLen += 1;
                    else wordLen += 2;
                    return (
                          <div key={"pdf" + index}>
                          <div key={"pdf4_" + index} style={{display:"inline-block", width: "411.5px", height: "585px", float: "left", borderTop:"0.5px solid black", borderBottom:"0.5px solid black", borderLeft:"0.25px solid black", borderRight:"0.25px solid black",position: "relative"}} id={"printPdf4_" + index}>
                            <div key={"pdf4Title_" + index} style={{width: "411.5px",  backgroundColor: "#6DB13E", textAlign: "center", height: "45px", color: "white",fontWeight: "bold", justifySelf: "center", borderTop: "0.5px solid black", borderBottom: "0.5px solid black", overflow: "hidden", textOverflow: "ellipsis"
                            , fontSize: (this.store.projects.viewingProject.title.length <= 50 )? (this.store.projects.viewingProject.title.length > 25)? "23px":"30px" : "16px"}}>{this.store.projects.viewingProject.title}</div>
                            <div key={"pdf42_" + index} style={{width: "411.5px", height:"539px", borderBottom: "0.5px solid black"}}>
                              <img key={"pdfBadge4_" + index} style={{height: "20%", top: "45px", right: 0, position: "absolute"}} alt="featuredBadge" src={badge_featured}/>
                              <div key={"pdf43_" + index} style={{display: "flex", marginLeft: (411.5 - 200) / 2, marginTop: "20px", width:"200px", height:"200px", border: "0.5px solid black"}}>
                                <img key={"pdf4Img_" + index} id={"pdf4Img_" + index} style={{marginLeft:"25px" ,width:"150px", alignSelf: "center", justifyContent:"center", maxHeight: "200px"}} alt={card.icon}/> 
                              </div>
                              <div key={"pdf4Lang_" + index} style={{marginLeft: (411.5 - 390) / 2,  width:"390px", height:"auto", marginTop: "5px", textAlign:  "left", fontSize: "10px"}}>{lang}</div>
                              <div key={"pdf4Text_" + index} style={{ display: "-webkit-box", WebkitLineClamp: 10, WebkitBoxOrient: "vertical", overflow: "hidden", textOverflow: "ellipsis",marginLeft: (411.5 - 390) / 2,  marginTop: "15px",width:"390px", height:"auto",fontSize: (wordLen > 12) ?"16px" : "50px", textAlign: (wordLen <= 12)?"center" : "left"}} >{ firstLang.text }</div>
                              {wordLen > 480 && <div key={"pdf4..._" + index} style={{marginLeft: (411.5 - 390) / 2, fontWeight: "bold"}}>...</div>}
                            <div key={"pdf4Author_" + index} style={{height:"`10px", position: "absolute", bottom: "10px", left: 5}}>{(this.func.getById.profileByUser(card.author, this.store) === null)? "" : this.func.getById.profileByUser(card.author, this.store).name}</div> 
                          </div>
                          </div>
                          <div key={"pdf2_" + index} style={{display:"inline-block", width: "823px", height: "1170px", float: "left", borderTop:"0.5px solid black", borderBottom:"0.5px solid black", borderLeft:"0.25px solid black", borderRight:"0.25px solid black", position: "relative"}} id={"printPdf2_" + index}>
                            <div key={"pdf2Title_" + index} style={{verticalAlign: "center", width: "823px",  backgroundColor: "#6DB13E", textAlign: "center", height: "90px", color: "white", fontWeight: "bold", justifySelf: "center", textOverflow: "ellipsis", overflow: "hidden", borderTop: "0.5px solid black", borderBottom: "0.5px solid black"
                            , fontSize: (this.store.projects.viewingProject.title.length <= 50 )? (this.store.projects.viewingProject.title.length > 25)? "46px":"60px" : "32px"}}>{this.store.projects.viewingProject.title}</div>
                            <div key={"pdf22_" + index} style={{width: "823px", height:"1080px", borderBottom: "0.5px solid black"}}>
                            <img key={"pdfBadge2_" + index} style={{height: "20%", top: "90px", right: 0, position: "absolute"}} alt="featuredBadge" src={badge_featured}/>
                              <div key={"pdf23_" + index} style={{display: "flex", marginLeft: (823 - 400) / 2, marginTop: "40px",width:"400px", height:"400px", border: "0.5px solid black"}}>
                                <img key={"pdf2Img_" + index} id={"pdf2Img_" + index} style={{marginLeft:"50px" ,width:"300px", alignSelf: "center", justifyContent:"center",  maxHeight: "400px"}} alt={card.icon}/> 
                              </div>
                              <div key={"pdf4Lang_" + index} style={{marginLeft: (823 - 780) / 2,  width:"780px", height:"auto", marginTop: "10px", textAlign:  "left", fontSize: "20px"}}>{lang}</div>
                              <div key={"pdf2Text_" + index} style={{display: "-webkit-box", WebkitLineClamp: 11, WebkitBoxOrient: "vertical", overflow: "hidden", textOverflow: "ellipsis",marginTop: "20px", width:"780px", marginLeft: (823 - 780) / 2,  fontSize: (wordLen > 12) ?"30px" : "100px", textAlign: (wordLen <= 12)?"center" : "left" }} >{firstLang.text}</div>
                              {wordLen > 572 && <div key={"pdf2..." + index} style={{marginLeft: (823 - 780) / 2, fontSize: "30px", fontWeight: "bold"}}>...</div>}
                            <div key={"pdf2Author_" + index} style={{height: "20px", fontSize: "20px", bottom: "10px", left: 10, position: "absolute"}}>{(this.func.getById.profileByUser(card.author, this.store) === null)? "" : this.func.getById.profileByUser(card.author, this.store).name}</div> 
                          </div>
                          </div>
                          <div key={"pdf1_" + index} style={{display:"inline-block", width: "1646px", height: "2340px", float: "left", border:"0.5px solid black", position: "relative"}} id={"printPdf1_" + index}>
                            <div key={"pdf1Title_" + index} style={{verticalAlign: "center", width: "1646px",  backgroundColor: "#6DB13E", textAlign: "center", height: "180px", color: "white", fontWeight: "bold", justifySelf: "center", textOverflow: "ellipsis", overflow: "hidden", borderTop: "0.5px solid black", borderBottom: "0.5px solid black"
                            , fontSize: (this.store.projects.viewingProject.title.length <= 50 )? (this.store.projects.viewingProject.title.length > 25)? "92px":"120px" : "64px"}}>{this.store.projects.viewingProject.title}</div>
                            <div key={"pdf12_" + index} style={{width: "1646px", height:"2150px", borderBottom: "0.5px solid black"}}>
                            <img key={"pdfBadge1_" + index} style={{height: "20%", top: "180px", right: 0, position: "absolute"}} alt="featuredBadge" src={badge_featured}/>
                              <div key={"pdf13_" + index} style={{display: "flex", marginLeft: (1646 - 800) / 2, width:"800px", marginTop: "80px", height:"800px", border: "0.5px solid black"}}>
                                <img key={"pdf1Img_" + index} id={"pdf1Img_" + index} style={{marginLeft:"100px" ,width:"600px", alignSelf: "center", justifyContent:"center", maxHeight: "800px"}} alt={card.icon}/> 
                              </div>
                              <div key={"pdf4Lang_" + index} style={{marginLeft: (1646 - 1560) / 2,  width:"1560px", height:"auto", marginTop: "20px", textAlign:  "left", fontSize: "30px"}}>{lang}</div>
                              <div key={"pdf1Text_" + index} style={{display: "-webkit-box", WebkitLineClamp: 15, WebkitBoxOrient: "vertical",  overflow: "hidden", textOverflow: "ellipsis", width:"1560px", marginLeft: (1646 - 1560) / 2,  fontSize: (wordLen > 12) ?"45px" : "200px", marginTop: "40px", textAlign: (wordLen <= 12)?"center" : "left"}} >{firstLang.text}</div>
                              {wordLen > 1020 && <div key={"pdf1..." + index} style={{marginLeft: (1646 - 1560) / 2, fontSize: "45px", fontWeight: "bold"}}>...</div>}
                            <div key={"pdf1Author" + index} style={{height: "40px", fontSize: "35px",  bottom: "20px", left: 20, position: "absolute"}}>{(this.func.getById.profileByUser(card.author, this.store) === null)? "" : this.func.getById.profileByUser(card.author, this.store).name}</div> 
                          </div>
                          </div>
                          </div>
                      );
                  }
                  else
                    return null;
              })
              : ""}
              
            </div> */}
      </div>
    )
  }

  onAdd(){
    return this.buttons.cellAdd(()=>{ this.actions.content.pushView('addCard')})
  }

  filterOptions(){
    var options = ['All'];
    this.cards.map(cardId=>{
      const card = this.func.getById.card(cardId, this.store);
      const profile = this.func.getById.profileByUser(card.author, this.store);
      if(!profile){ return null; }
      if(!options.includes(profile.name)){
        options.push(profile.name);
      }
      return null;
    })
    return options;
  }

  onFilterChange(){
    const selected = document.getElementById('filter').value;
    this.setCardsToView(selected);
    this.actions.content.setFilter(selected);
    this.actions.cards.viewCards([]);
  }

}

export default ProjectFeatured;