import React from 'react';
import UI from 'components/UI';
import {Motion, spring}  from 'react-motion';
import { SketchPicker } from 'react-color'


class ColorPicker extends UI {

  constructor(props){
    super(props);
    this.init(props);
    this.state={
      fontSizes: [this.bs.height * 0.05, this.bs.height * 0.1, this.bs.height * 0.15, this.bs.height * 0.3],
      sizeIndex: 0,
    }
  }


  render() {
    this.init(this.props);
    const main = this.store.main;
    
    const status = main.colorPicker;
    const isOpen = status !== 'off';
    if(!isOpen){ return null; }

    const colorPickerStyle = {...this.bs, ...{
      position: 'absolute',
      minHeight: this.bs.minHeight,
      justifyContent: 'center',
      backgroundColor: 'rgba(255,255,255, 0.5)',
      pointerEvents: isOpen? 'auto': 'none',
    }}

    const defaultPopover = {
      position: 'absolute',
      zIndex: '2',
    }

    const defaultCover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

    const defaultColor = { r: '241', g: '112', b: '19', a: '1'}

    return(
      <Motion defaultStyle={{opacity: isOpen?0:1.5}}
      style={{opacity: isOpen?spring(1.5):spring(0)}}>
        {style=>(
          <div style={{...colorPickerStyle, ...{opacity: style.opacity}}}>
            <div style={defaultPopover}>
              <div style={defaultCover} onClick={(e)=>this.actions.main.closeColorPicker()}/>
              <SketchPicker color={this.store.main.color?this.store.main.color:defaultColor} onChange={(e)=> {this.actions.main.setColorPicker(e.rgb)}}/>
            </div>
          </div>
        )}
      </Motion>
    )
  }

}

export default ColorPicker;

