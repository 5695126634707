import React from 'react';
import View from 'components/main/pages/home/views/View';

class ShareCard extends View {

  constructor(props){
    super(props);
    this.init(props);
    this.card = this.store.cards.viewingCard;
    
    this.state = {
      modified: false,
      courseToPost: this.card? this.card.shareToCourses: [],
      selectProjects: this.card? this.card.shareToProjects: [],
    }
  }

  componentDidMount(){
    this.init(this.props);
    this.actions.courses.getAllCoursesOfSchool(this.store.schools.schools[0]);
    if(this.card && this.card.shareToCourses.length > 0){ this.actions.projects.getAllProjectsOfCourse(this.card.shareToCourses); }
  }

  classSelection(){
    const fontSize = 0.03;
    const checkBoxSize = 0.035;
    const containerStyle = {
      width: this.bs.width * 0.675,
      height: this.bs.height * 0.15,
      border: '1px solid rgb(220,220,220)',
      flexShrink: 0,
      overflow: 'auto'
    }
    const optionsStyle = {
      marginBottom: '2%',
    }

    const courses = this.store.courses.schoolCourses;
    if(!courses || courses.length <= 0){ return null; }

    return(
      <div style={containerStyle}>
        {courses.map((course, id) => {
          const text = course.title + ' - ' + course.code;
          const index = this.state.courseToPost.indexOf(course._id);

          return(
            <div key={id} style={optionsStyle}>
              {this.checkBox(this.func.multiLang(text,text,text), index > -1? true: false, (e)=>{
                if(index > -1){
                  const newCourseToPost = this.state.courseToPost;
                  newCourseToPost.splice(index, 1);
                  this.setState({ courseToPost: newCourseToPost, selectProjects: [], modified: true });
                  this.actions.projects.getAllProjectsOfCourse(newCourseToPost);
                }
                else{
                  this.setState({ courseToPost: [...this.state.courseToPost, course._id], modified: true });
                  this.actions.projects.getAllProjectsOfCourse([...this.state.courseToPost, course._id]);
                }
              }, 'black', fontSize, checkBoxSize, true)}
            </div>
          )
        })}
      </div>
    )
  }


  projectSelection(){
    const fontSize = 0.03;
    const checkBoxSize = 0.035;
    const containerStyle = {
      width: this.bs.width * 0.675,
      height: this.bs.height * 0.15,
      border: '1px solid rgb(220,220,220)',
      flexShrink: 0,
      overflow: 'auto'
    }
    const optionsStyle = {
      marginBottom: '2%',
    }

    const projects = this.store.projects.courseProjects;
    if(!projects || projects.length <= 0){ return null; }

    return(
      <div style={containerStyle}>
        {projects.map((project, id) => {
          if(project._id === this.store.projects.viewingProject._id){ return null; }
          const title = project.title;
          const index = this.state.selectProjects.indexOf(project._id);

          return(
            <div key={id} style={optionsStyle}>
              {this.checkBox(this.func.multiLang(title,title,title), index > -1? true: false, (e)=>{
                if(index > -1){
                  const newSelectProjects = this.state.selectProjects;
                  newSelectProjects.splice(index, 1);
                  this.setState({ selectProjects: newSelectProjects, modified: true });
                }
                else{
                  this.setState({ selectProjects: [...this.state.selectProjects, project._id], modified: true });
                }
              }, 'black', fontSize, checkBoxSize, true)}
            </div>
          )
        })}
      </div>
    )
  }




  render() {
    this.init(this.props);

    return(

      <div style={this.viewStyle()}>
        {this.gap('4%')}

        {this.subTitle(['Select classess for choosing projects to share the card (multiple selection)','選擇班別以選擇要分享卡片的專題研習 (多選)','选择班别以选择要分享卡片的专题研習 (多选)'])}
        {this.sep()}
        {this.gap('0.5%')}
        {this.classSelection()}
        {this.gap('4%')}

        {this.subTitle(['Select projects (multiple selection)','選擇專題研習 (多選)','选择專題研習 (多选)'])}
        {this.sep()}
        {this.gap('0.5%')}
        {this.projectSelection()}
        {this.gap('4%')}


        {this.buttons.rectGreen(['Confirm','確定','确定'], ()=>{this.shareCard()})}
        {this.gap('8%')}
      </div>

    )
  }

  shareCard(){

    if(!this.store.cards.viewingCard){
      return this.failedMessage(['Failed to share the card! Card is missing!', '分享卡片失敗! 沒有卡片!','分享卡片失败! 没有卡片!'])
    }
    if(!this.store.projects.viewingProject){
      return this.failedMessage(['Failed to share the card! Project is missing!', '分享卡片失敗! 沒有專題項目!','分享卡片失败! 没有专题项目!'])
    }
    if(this.state.courseToPost.length > 0 && this.state.selectProjects.length <= 0){
      return this.failedMessage(['Failed to share the card! Projects are missing!', '分享卡片失敗! 未選擇專題項目!','分享卡片失败! 未选择专题项目!'])
    }

    this.actions.cards.shareToProjects({
      card: this.store.cards.viewingCard,
      cardProject: this.store.projects.viewingProject,
      courses: this.state.courseToPost,
      projects: this.state.selectProjects,
    });

  }
}

export default ShareCard;
