import React from "react";
import Konva from "react-konva";

const Tick = ({ editMode, shapeProps, isSelected, onSelect, onChange }) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  React.useEffect(() => {
    if (isSelected && editMode) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
    
    shapeRef.current.getSelfRect = (e) => {
      const ret = { x: 20, y: 15, width: 87 - 20, height: 70 - 15 };
      return ret;
    }
    // eslint-disable-next-line
  }, [isSelected]);
  return (
    <React.Fragment>
      <Konva.Shape
        sceneFunc={(context, shape) => {
            context.beginPath();
            context.moveTo(20, 50);
            context.lineTo(40, 70);
            context.moveTo(35, 70);
            context.lineTo(87, 15);
            context.closePath();
            context.fillStrokeShape(shape);
        }}
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        draggable={editMode}
        {...shapeProps}
        onDragEnd={e => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={e =>{
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            width: node.width() * scaleX,
            height: node.height() * scaleY,
            rotation: node.rotation()
          });

        }}
      />
      {isSelected && editMode && <Konva.Transformer ref={trRef} />}
    </React.Fragment>
  );
};
export default Tick;