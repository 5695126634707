import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Cursor from 'resources/images/buttons/buttonIcons/drawer/cursor.png';
import Eraser from 'resources/images/buttons/buttonIcons/drawer/eraser.png';
import Font from 'resources/images/buttons/buttonIcons/drawer/font.png';
import Ellipse from 'resources/images/buttons/buttonIcons/drawer/ellipse.png';
import Line from 'resources/images/buttons/buttonIcons/drawer/line.png';
import Star from 'resources/images/buttons/buttonIcons/drawer/star.png';
import Tick from 'resources/images/buttons/buttonIcons/drawer/tick.png';
import Cross from 'resources/images/buttons/buttonIcons/drawer/cross.png';
import SaveIcon from '@material-ui/icons/Save';
import ColorLensIcon from '@material-ui/icons/ColorLens';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: props => props.width * 0.07,
    height: props => props.height,
    minWidth: props => props.minWidth * 0.15,
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 1,
    overflowX: 'hidden',
  },
}));


function MaterialDrawer(props) {
  const classes = useStyles(props);
  const [count, setCount] = useState(0);
  const colorPickerStatus = props.app.store.main.colorPicker;
  const color = props.app.store.main.color;

  const items = [
      {text: 'cursor', icon: Cursor},
      {text: 'eraser', icon: Eraser},
      {text: 'text', icon: Font},
      {text: 'line', icon: Line},
      {text: 'ellipse', icon: Ellipse},
      {text: 'star', icon: Star}, 
      {text: 'tick', icon: Tick},
      {text: 'cross', icon: Cross},
  ];

  return (
    <Drawer variant="permanent" classes={{paper: classes.paper}}>
        <Divider />
        <ListItem button key={'back'} onClick={() => {props.onBack()}}>
            <ListItemIcon><ArrowBackIcon/></ListItemIcon>
        </ListItem>
        <Divider />
          <List>
            {items.map((item, index) => (
                <ListItem button key={item.text} onClick={(e) => {setCount(count+1); props.onItemClick(e, item.text + '-' + (count + 1));}}>
                    <img src={item.icon} alt={item.text + '-' + count} />
                </ListItem>
            ))}
          </List>
        <Divider />
        <ListItem button key={'colorPicker'} onClick={(e) => {colorPickerStatus === 'off'? props.app.actions.main.openColorPicker(): props.app.actions.main.closeColorPicker()}}>
            <ListItemIcon><ColorLensIcon 
            style={{fill: color?`rgba(${color.r},${color.g},${color.b},${color.a})`:'#707070'}}/></ListItemIcon>
        </ListItem>
        <Divider />
        <Divider />
        <ListItem button key={'save'} onClick={async() => {await props.onSave(); await props.onBack();}}>
            <ListItemIcon><SaveIcon/></ListItemIcon>
        </ListItem>
        <Divider />
    </Drawer>
  )
}

export default MaterialDrawer;
