export function splitAnnotations(newWidth, newHeight, annotations){
    var texts = [], lines = [], ellipses = [], stars = [], ticks = [], crosses = [];
    if(!annotations){ return [texts, lines, ellipses, stars, ticks, crosses]; }
    for(var i=0;i<annotations.length;i++){
      const width = annotations[i].width;
      const height = annotations[i].height;
      texts = [...texts, ...reposition(newWidth, newHeight, width, height, 'text', annotations[i].texts)];
      lines = [...lines, ...reposition(newWidth, newHeight, width, height, 'line', annotations[i].lines)];
      ellipses = [...ellipses, ...reposition(newWidth, newHeight, width, height, 'ellipse', annotations[i].ellipses)];
      stars = [...stars, ...reposition(newWidth, newHeight, width, height, 'star', annotations[i].stars)];
      ticks = [...ticks, ...reposition(newWidth, newHeight, width, height, 'tick', annotations[i].ticks)];
      crosses = [...crosses, ...reposition(newWidth, newHeight, width, height, 'cross', annotations[i].crosses)];
    }

    return [texts, lines, ellipses, stars, ticks, crosses];
}

function reposition(newWidth, newHeight, oldWidth, oldHeight, type, items){
    const newItems = [];
    const scaleX = newWidth / oldWidth;
    const scaleY = newHeight / oldHeight;

    for(var i=0;i<items.length;i++){
      var newItem = '';
      const newX = items[i].x * scaleX;
      const newY = items[i].y * scaleY;

      if(type === 'text'){
        const fontSize = items[i].fontSize * scaleX;
        newItem = {...items[i], x: newX, y: newY, fontSize: fontSize};
      }
      else if(type === 'line'){
        var points = items[i].points;
        points = points.map((point, i) => { if(i % 2 === 0){ return point * scaleX; } else{ return point * scaleY; } });
        newItem = {...items[i], points: points};
      }
      else if(type === 'ellipse'){
        const newRadiusX = items[i].radiusX * scaleX;
        const newRadiusY = items[i].radiusY * scaleY;
        newItem = {...items[i], x: newX, y: newY, radiusX: newRadiusX, radiusY: newRadiusY};
      }
      else{
        const itemScaleX = items[i].scaleX * scaleX;
        const itemScaleY = items[i].scaleY * scaleY;
        newItem = {...items[i], x: newX, y: newY, scaleX: itemScaleX, scaleY: itemScaleY};
      }
      //console.log(newItem);
      newItems.push(newItem);
    }
    return newItems;
}
