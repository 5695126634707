import React from 'react';
import UI from 'components/UI';
//import LangRow from './LangRow';
import CommentRow from 'components/main/items/rows/CommentRow';

class Comments extends UI {

  constructor(props){
    super(props);
    this.init(this.props);
  }

  componentDidMount(){
    this.init(this.props);
    this.getComments(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProp){
    this.init(this.props);
  }

  getComments(props){
    this.actions.comment.getComment(props.card);
  }


  commentRows(comments, commentsByUser){
    return(
      comments.map((comment, i) =>{
        //commentsByUser.reverse();
        return <CommentRow key={i} index={i} app={this.props.app} comment={comments[i]} commentBy={commentsByUser[i]}/>
      })
    )
  }


  render() {
    this.init(this.props);
    var comments = null;
    var commentsByUser = null;
    var commentsSize = this.store.comment.editComments? this.store.comment.editComments.length - 1: 0;

    if(this.store.comment.editComments[commentsSize]){
      comments = this.store.comment.editComments[commentsSize][0];
      commentsByUser = this.store.comment.editComments[commentsSize][1];
    }


    const commentsStyle = {...this.bs, ...this.ui.styles.list, ...{
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
    }}

    return(
      <div style={commentsStyle}>
      {comments ? this.commentRows(comments, commentsByUser) : ''}
      </div>
    )
  }

}

export default Comments;
