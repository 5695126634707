import React from 'react';
import View from 'components/main/pages/home/views/View';
import ImagePicker from 'components/main/items/ImagePicker';
import AllowedCommentEditRow from 'components/main/items/rows/AllowedCommentEditRow';
import RecorderBar from 'components/audio/RecorderBar';
import MultipleSelect from 'components/main/items/ui/MultipleSelect';
import * as SchoolTagHelper from 'components/main/pages/home/helpers/SchoolTagHelper.js';

class AddProject extends View {

  constructor(props){
    super(props);
    this.init(props);
    this.project = this.store.projects.viewingProject;
    this.state = {
      modified: true,
      filename: this.project.icon,
      type: 'projectIcon',
      defaultPeriod: 7,
      projectHide: this.project.hide,
    }
    this.checkUrl();
    this.setDefaultPeriod();
  }

  UNSAFE_componentWillReceiveProps (newProps){
    this.init(newProps);
    if((this.store.user._id === "633e28a8f2eeeb6a51412497" || this.store.user._id === "6203327a5d679a7b7544f29e" || this.store.user.id === "lsths.tts" || this.store.user.id === "mlang.ch.jy" || this.store.user.id === "ylpkps.teacher" || this.store.user.id === "chpsp.teacher" || this.store.user.id === "chpss.teacher")&& this.store.cards.audioBuffer !== null){
      const audioBuffer = new Uint8Array(this.store.cards.audioBuffer.data);
      const wav = new Blob([audioBuffer], { type: 'audio/mpeg' })
      this.store.projects.editLang.audioBlob = wav;
      this.store.cards.audioBuffer = null;
    }
    if(this.store.projects.projectToCopy != null && !this.props.editMode)
    {
      console.log(this.store.projects.projectToCopy);
      document.getElementById('title').value = this.store.projects.projectToCopy.title;
      document.getElementById('desc').value = this.store.projects.projectToCopy.description;
      console.log(this.store);
    }
  }

  componentDidMount(){
    this.init(this.props);
    //console.log(this.store);
    this.editAllowedComments = this.store.allowedComment.editAllowedComments;
    if(this.props.editMode)
      this.removeAllowedComment(this.editAllowedComments);
    this.initRow();
    if(this.props.editMode)
      this.initLangRow();
    console.log(this.store);
  }

  componentWillUnmount(){
    this.actions.main.setPhotoAttr(0);
    this.actions.projects.setTags([]);
  }


  async setDefaultPeriod(){
    const autoLogin = await this.db.get('autoLogin');
    if(!autoLogin){ return; }
    const period = await this.db.get('projectPeriod');
    //console.log(period);
    if(period){
      this.setState({ defaultPeriod: period });
      var defaultDate = new Date();
      defaultDate.setDate(defaultDate.getDate() + period);
      document.getElementById('endDate').value = this.func.dateString(defaultDate);
    }
  }

  saveDefaultPeriod(endDateString){
    this.db.set('projectPeriod', this.func.deltaDay(new Date(), new Date(endDateString)));
  }

  removeAllowedComment(editAllowedComments){
    editAllowedComments.map((item, index) =>{
      this.actions.allowedComment.removeEditAllowedCommentsItem(0);
      return null;
    });
  }

  initRow(){
    const defaultAllowedComments = this.project.allowedComments && this.props.editMode ? this.project.allowedComments : '';

    if(defaultAllowedComments){
      for(var i=0;i<defaultAllowedComments.length;i++){
        const defaultAllowedComment = defaultAllowedComments[i];
        const options = [{ key: this.key(), name: '' }, { key: this.key(), name: '' }];
        var newEditAllowedComment = {
          //_id: defaultQuestion._id,
          _id: this.key(),
          key: this.key(),
          title: defaultAllowedComment,
          type: "option",
          options: this.setOptions(options)
        }
        this.actions.allowedComment.pushEditAllowedComments(newEditAllowedComment);
      }
    }
  }

  initLangRow(){
    /*
    var newLangRow = {
      _id: null,
      key: null,
      text: null,
      defaultAudio: null,
      audioBlob: null
    }*/
    this.actions.projects.setProjectLangAudio({blob: null});
  }

  projectDesc(scale){
    const container = {
      width: scale? scale[0]: this.bs.width * 0.70,
      display: 'flex',
      justifyContent: this.store.ui.windowWidth <= this.store.ui.minWidth + 100 ? 'flex-start' : 'center',
      flexShrink: 0,
    }
    const project = this.store.projects.viewingProject;
    return (
      <div style={container}>
        {<RecorderBar app={this.app} type={'projectAudioDesc'} scale={['100%', '100%']}
        defaultAudio={project.audioDesc && this.props.editMode ?project.audioDesc:null} audioBlob={this.editLang?this.editLang.audioBlob:null}
        onStopRecording={this.onStopRecording.bind(this)} canRemove={false} page={"project"}
        recordingText={null}/>}
      </div>
    )
  }


  setOptions(options){
    var returnOptions = [];
    for(var i=0;i<options.length;i++){ returnOptions.push({name: options[i], key: this.key()}) }
    return returnOptions;
  }

  allowedCommentEditRows(){
    return this.editAllowedComments.map((editAllowedComment,i)=>{
      return(
        <AllowedCommentEditRow app={this.app}
        editMode={this.props.editMode}
        key={editAllowedComment.key}
        index={i}
        editAllowedComment={editAllowedComment}/>
      )
    })
  }

  pushNewRow(type){
    var newEditAllowedComment = {
      key: this.key(),
      type: type,
      //ptions: [{ key: this.key(), name: '' }, { key: this.key(), name: '' }]
    }
    this.actions.allowedComment.pushEditAllowedComments(newEditAllowedComment);
    this.actions.switches.setAnimation('row', true);
  }

  addButtons(){
    const style = {...this.ui.styles.area, ...{
      width: this.bs.width * 0.95,
      height: this.bs.height * 0.075,
      justifyContent: 'space-evenly'
    }}
    const scale = [this.bs.width * 0.44, this.bs.height * 0.075];
    return(
      <div style={style}>
        {this.buttons.listAdd(scale, ['ADD COMMENT','增加評論','增加评论'], this.bs.height * 0.035,
        ()=>{this.pushNewRow('option')})}
      </div>
    )
  }

  getCommentTitle(editAllowedComments){
    var comments = [];
    editAllowedComments.map((item, key) =>
      comments.push(item.title)
    );
    return comments;
  }

  onStopRecording(blob){
    this.actions.projects.setProjectLangAudio({blob: blob});
    this.setState({modified: true});
  }


  setHideProject(){
    const fontSize = 0.03;
    const checkBoxSize = 0.035;
    const optionsStyle = {
      width: this.bs.width * 0.65,
      height: '',
    }
    const hide = this.state.projectHide? 'true': 'false';

    return(
      <div style={optionsStyle}>
        {this.checkBox(this.func.multiLang('Yes','是','是'), hide === 'true', (e)=>{this.setState({ projectHide: true })}, 'black', fontSize, checkBoxSize, true)}
        {this.gap(this.bs.height * 0.02)}
        {this.checkBox(this.func.multiLang('No','否','否'), hide === 'false', (e)=>{this.setState({ projectHide: false })}, 'black', fontSize, checkBoxSize, true)}
      </div>
    )
  }


  multipleCheckBox(onChange){
    const choices = SchoolTagHelper.filter(this.store.schoolTags.schoolTags, this.store.courses.viewingCourse.tags, 'project');
    const defaultTag = this.project.tags?this.project.tags:[];
    return(
      <MultipleSelect app={this.app} choices={choices} defaultTag={defaultTag} openStatus={null} onChange={onChange} type={'project'}/>
    )
  }


  render() {
    this.init(this.props);
    var defaultDate = new Date();
    defaultDate.setDate(defaultDate.getDate() + this.state.defaultPeriod);

    this.editAllowedComments = this.store.allowedComment.editAllowedComments;
    this.hideProject = this.store.projects.viewingProject.hide ? this.store.projects.viewingProject.hide : false;
    this.editLang = this.store.projects.editLang;


    return(

      <div style={this.viewStyle()}>
        {this.gap('4%')}

        {this.subTitle(['Icon','照片','照片'])}
        {this.sep()}
        <ImagePicker type={'project'} defaultUrl={this.url.url} app={this.app} />
        {this.sep()}
        {this.gap('2%')}

        {this.subTitle(['Title','標題','标题'])}
        {this.sep()}
        {this.inputs.inputField('title','text', '', this.props.editMode? this.project.title:'' , ()=>{ this.setState({modified: true})})}
        {this.gap('2%')}

        {this.subTitle(['Description','描述','描述'])}
        {this.sep()}
        {this.gap('0.5%')}
        {(this.store.user._id === "633e28a8f2eeeb6a51412497" || this.store.user._id === "6203327a5d679a7b7544f29e" || this.store.user._id === "63be16d899848c06bb68c37c" || this.store.user.id === "lsths.tts" || this.store.user.id === "mlang.ch.jy" || this.store.user.id === "ylpkps.teacher" || this.store.user.id === "chpsp.teacher" || this.store.user.id === "chpss.teacher") &&  <button style={{alignSelf: "end", marginRight: "1%", marginTop: "1%", height: "20%", width: "12%"}} onClick={() => {this.handleTextToSpeech();}}> TTS </button>}
        {this.projectDesc(['69%', '100%'])}
        {this.gap(this.bs.height * 0.025)}
        {this.inputs.textArea('desc', '', this.props.editMode? this.project.description:'', ()=>{ this.setState({modified: true})})}
        {this.gap('4%')}

        {this.subTitle(['Allowed Comment','允許評論','允许的评论'])}
        {this.sep()}
        {this.gap('1%')}
        {this.allowedCommentEditRows()}
        {this.gap('1%')}
        {this.addButtons()}
        {this.gap('9%')}

        {this.subTitle(['Hide','隱藏','隐藏'])}
        {this.sep()}
        {this.gap('1%')}
        {this.setHideProject()}
        {this.gap('5%')}

        {this.subTitle(['Tag', '標籤', '标籤'])}
        {this.sep()}
        {this.gap('0.5%')}
        {this.multipleCheckBox(()=>{ this.setState({modifiedTag: true}) })}
        {this.gap('4%')}

        {this.subTitle(['End date','結束日期','结束日期'])}
        {this.sep()}
        {this.inputs.inputField('endDate','date', ['',''], this.func.dateString(this.props.editMode? new Date(this.project.endDate):defaultDate) , ()=>{ this.setState({modified: true})})}
        {this.gap('2%')}

        {this.buttons.rectGreen(['Confirm','確定','确定'], ()=>{this.addProject()})}
        {this.gap('8%')}
      </div>

    )
  }

  
  async handleTextToSpeech(){
    if(document.getElementById('desc').value !== ""){
      this.actions.modal.loadingMessage(['Downloading...', '正在下載...', '正在下载...']);
      await this.actions.cards.textToSpeech(document.getElementById('desc').value); 
    }
  }

  addProject(){
    const editMode = this.props.editMode;
    const newIconBlob = this.store.main.photoBlob;
    const title = document.getElementById('title').value;
    const description = document.getElementById('desc').value;
    const audioDesc = this.store.projects.editLang.audioBlob;
    const endDate = document.getElementById('endDate').value;
    const allowedComments = this.getCommentTitle(this.editAllowedComments);
    const hide = this.state.projectHide ? this.state.projectHide : false;
    const tags = this.state.modifiedTag ? this.store.projects.tags : this.project.tags;

    //const today = new Date();
    //const selectedEndDate = new Date(endDate)
    if(!editMode && newIconBlob === null){
      return this.failedMessage(['Failed to add! Icon is missing!', '創建失敗! 未有照片!','创建失败! 未有照片!'])
    }
    if(title.length === 0){
      return this.failedMessage(['Failed to add! Title is missing!', '創建失敗! 未填班名!','创建失败! 未填班名!'])
    }
    /*if(selectedEndDate < today){
      return this.failedMessage(['Failed to add! End date is in the past!', '創建失敗! 結束日期早於現在!','创建失败! 结束日期早于现在!'])
    }*/


    if(!editMode){
      this.actions.projects.addProject({
        subject: this.store.subjects.viewingSubject._id,
        icon: newIconBlob,
        title: title,
        description: description,
        audioDesc: audioDesc,
        endDate: endDate,
        allowedComments: allowedComments,
        hide: hide,
        tags: tags,
        projectToCopy: this.store.projects.projectToCopy
      });
      this.saveDefaultPeriod(endDate);

    }else if(newIconBlob || this.state.modified){
      this.actions.projects.editProject({...this.project, ...{
        newIcon: newIconBlob,
        title: title,
        description: description,
        audioDesc: audioDesc,
        endDate: endDate,
        allowedComments: allowedComments,
        hide: hide,
        tags: tags
      }});
    }else{
      return this.failedMessage(['Failed to add! Nothing is modified!', '提交失敗!未作出更改!', '提交失败!未作出更改!'])
    }
  }

}

export default AddProject;
