import React from 'react';
import View from 'components/main/pages/home/views/View';
import MultipleSelect from 'components/main/items/ui/MultipleSelect';
import MultipleList from 'components/main/items/ui/MultipleList';

class ExportReport extends View {

    constructor(props){
        super(props);
        this.init(props);
        this.state = {
          subjects: [],
        }
        this.course = this.store.courses.viewingCourse;
        this.courseSubjects = this.getCourseSubjects();
    }

    getCourseSubjects(){
        const subjectsId = this.course? this.course.subjects: [];
        var subjects = [];
        for(var i=0; i<subjectsId.length;i++){
            const subject = this.func.getById.subject(subjectsId[i], this.store);
            subjects.push(subject);
        }
        return subjects;
    }

    getSubjectProjects(){
      const subjectsId = this.state.subjects;
      var projects = [];
      for(var i=0;i<subjectsId.length;i++){
        const subject = this.func.getById.subject(subjectsId[i], this.store);
        for(var j=0;j<subject.projects.length;j++){
          const project = this.func.getById.project(subject.projects[j], this.store);
          if(project){ projects.push(project); }
        }
      }
      return projects;
    }

    handleSelectChange(event, child){
      const index = this.state.subjects.indexOf(child.props.id);
      if(index > -1){ 
        var newSubjects = this.state.subjects;
        newSubjects.splice(index, 1);
        this.setState({ modified: true, subjects: newSubjects });
      }
      else{
        this.setState({ modified: true, subjects: [...this.state.subjects, child.props.id] });
      }
    }

    handleListChange(values){
      this.projects = values;
    }

    multipleCheckBox(onChange){
      const choices = this.courseSubjects;
      const defaultTag = [];
      return(
        <MultipleSelect app={this.app} choices={choices} defaultTag={defaultTag} openStatus={null} onChange={onChange} type={'courseSubject'} />
      )
    }

    projectSelection(){
      const projects = this.getSubjectProjects();
      return(
        <MultipleList app={this.app} choices={projects} onClick={(values)=>this.handleListChange(values)}/>
      )
    }

    downloadReport(){
      if(this.projects.length <= 0){
        return this.failedMessage(['Failed to download! Have not chosen projects!', '下載失敗! 未有選擇專題研習!','下载失败! 未有选择专题研习!'])
      }
      const now = new Date();
      const dateString = now.getDate()  + '-' + (now.getMonth()+1) + '-' + now.getFullYear();
      const id = now.getTime();
      const filename = 'report_' + dateString + '_' + id + '.xlsx';
      const data = {course: this.store.courses.viewingCourse._id, projects: this.projects, filename: filename};
      this.actions.courses.downloadReport(data, this.app.functions);
    }

    render() {
        this.init(this.props);

        return(
          <div style={this.viewStyle()}>
            {this.gap('4%')}
    
            {this.subTitle(['Unit', '單元', '单元'])}
            {this.sep()}
            {this.gap('0.5%')}
            {this.multipleCheckBox((e, c)=> this.handleSelectChange(e, c))}
            {this.gap('4%')}

            {this.subTitle(['Project', '專題研習', '专题研习'])}
            {this.sep()}
            {this.gap('0.5%')}
            {this.projectSelection()}
            {this.gap('4%')}
    
    
            {this.buttons.rectGreen(['Download','下載','下载'], ()=>{this.downloadReport()})}
            {this.gap('2%')}
          </div>
        )
    }

}

export default ExportReport;