import React from 'react';
import UI from 'components/UI';
import {Motion, spring}  from 'react-motion';
import CanvasGraphic from 'components/main/items/ui/CanvasGraphic';
import * as AnnotationHelper from 'components/main/pages/home/helpers/AnnotationHelper.js';


class Enlarger extends UI {

  constructor(props){
    super(props);
    this.init(props);
    this.state={
      fontSizes: [this.bs.height * 0.05, this.bs.height * 0.1, this.bs.height * 0.15, this.bs.height * 0.3],
      sizeIndex: 0,
      image: null
    }
  }

  UNSAFE_componentWillReceiveProps(newProps){
    this.init(newProps);
    const main = this.store.main;
    if(!main.enlargeImage){ return null; }
    this.loadImage(main.enlargeImage);
  }

  componentWillUnmount() {
    if(this.image){
      this.image.removeEventListener('load', this.handleLoad);
    }
  }

  handleLoad = (e) => {
    const width = this.image.width > this.bs.width * 0.85? this.bs.width * 0.85: this.image.width;
    const height = this.image.height> this.bs.height? this.bs.height: this.image.height;
    this.image.width = width;
    this.image.height = height;

    this.setState({
      image: this.image
    });
  }

  loadImage(url){
    this.image = new window.Image();
    this.image.src = url;
    this.image.addEventListener('load', (e) => this.handleLoad(e));
  }

  render() {
    this.init(this.props);
    const main = this.store.main;
    const status = main.enlarger;
    //console.log(this.buttons.bs)
    const isOpen = status !== 'off';
    var newWidth, newHeight, texts, lines, ellipses, stars, ticks, crosses;

    const enlargerStyle = {...this.bs, ...{
      position: 'absolute',
      minHeight: this.bs.minHeight,
      justifyContent: 'center',
      backgroundColor: 'black',
      pointerEvents: isOpen? 'auto': 'none'
    }}

    const textStyle = {
      color: 'white',
      fontWeight: 'bold',
      width: '100%',
      height: '',
      fontSize: this.state.fontSizes[this.state.sizeIndex],
      overflow: 'auto',
      overflowWrap: 'break-word',
      textAlign: 'left',
      cursor: 'pointer',
      whiteSpace: 'pre-wrap'
    }

    if(main.enlargeImage && this.state.image){
      newWidth = this.state.image.width;
      newHeight = this.state.image.height;
      [texts, lines, ellipses, stars, ticks, crosses] = AnnotationHelper.splitAnnotations(newWidth, newHeight, main.enlargeImageCard? main.enlargeImageCard.annotations: []);
    }

 
    return(
      <Motion defaultStyle={{opacity: isOpen?0:1.5}}
      style={{opacity: isOpen?spring(1.5):spring(0)}}>
        {style=>(
          <div style={{...enlargerStyle, ...{opacity: style.opacity}}}>
            {this.buttons.absoluteClose(()=>{this.actions.main.closeEnlarger()})}
            {status === 'image' &&
            <CanvasGraphic app={this.app} defaultImage={{image: this.state.image, rotation: this.store.main.photoAttr.rotation}} maxWidth={this.bs.width * 0.85} maxHeight={this.bs.height} texts={texts} lines={lines} ellipses={ellipses} stars={stars} ticks={ticks} crosses={crosses}/>}
            {status === 'text' &&
            <div style={textStyle} onClick={()=>{ this.setState({ sizeIndex: (this.state.sizeIndex + 1) % this.state.fontSizes.length }); }}>{main.enlargeText}</div>}
          </div>
        )}
      </Motion>
    )
  }

}

export default Enlarger;
