import React, {useState} from 'react';
import {Line as LineChart} from 'react-chartjs-2';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(0.5)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));


const ReactLineChar = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();

  const groupedData = props.data;
  const dataYears = Object.keys(groupedData);

  const [year, setYear] = useState(dataYears.length > 0? dataYears[dataYears.length - 1]: 0);
  const [anchorEl, setAnchorEl] = useState(null);

  const data = {
    datasets: [
      {
        data: dataYears.length > 0? groupedData[year].yAxis: [],
        borderWidth: 2,
        borderColor: theme.palette.info.main,
        hoverBorderColor: '#000',
        pointColor: 'rgba(220,220,220,1)',
      }
    ],
    labels: dataYears.length > 0? groupedData[year].xAxis: []
  };

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    },
    scales: {
      yAxes: [{
          ticks: {
            precision: 0
          }
      }],
    }
  };


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (event) => {
    setAnchorEl(null);
    setYear(event.currentTarget.value);
  };

  if(dataYears.length <= 0){ return null; }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={props.title}
        action={
          <div key={'year-'+ props.title}>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            {year}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {dataYears.map((dataYear, i) => {
              return (<MenuItem onClick={handleItemClick} value={dataYear} key={props.title +'-'+i}>{dataYear}</MenuItem>)
            })}
          </Menu>
          </div>
        }
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer} key={'cardContent-'+ props.title}>
          <LineChart
            data={data}
            options={options}
            redraw 
          />
        </div>
      </CardContent>
    </Card>
  );
};


export default ReactLineChar;
