import React, {useState, useEffect} from 'react';
import ReactKonva from 'react-konva';
import Konva from "konva";

import Ellipse from 'components/main/items/shapes/Ellipse';
import Star from 'components/main/items/shapes/Star';
import Tick from 'components/main/items/shapes/Tick';
import Cross from 'components/main/items/shapes/Cross';
import {Line, addLine} from 'components/main/items/shapes/Line';
import {Text, addText} from 'components/main/items/shapes/Text';

import icon_eraser from 'resources/images/buttons/buttonIcons/drawer/eraser.png';
import icon_line from 'resources/images/buttons/buttonIcons/drawer/line.png';


function findAnnotationByUser(card, user){
    var index = -1;
    for(var i=0; i<card.annotations.length;i++){
        if(card.annotations[i].user === user){ index = i; break; }
    }
    return index;
}

function Canvas(props){  
    const backgroundImage = props.defaultImage? props.defaultImage.image: null;

    const width = backgroundImage? backgroundImage.width > props.maxWidth? props.maxWidth: backgroundImage.width: props.maxWidth;
    const height = backgroundImage? backgroundImage.height > props.maxHeight? props.maxHeight: backgroundImage.height: props.maxHeight;
    const scale = backgroundImage? props.maxWidth < backgroundImage.width? props.maxWidth / backgroundImage.width: 1: 1;
    const color = props.app.store.main.color?`rgba(${props.app.store.main.color.r},${props.app.store.main.color.g},${props.app.store.main.color.b},${props.app.store.main.color.a})`:null;

    const [texts, setTexts] = useState(props.defaultAnnotions? props.defaultAnnotions.texts: []);
    const [lines, setLines] = useState(props.defaultAnnotions? props.defaultAnnotions.lines: []);
    const [ellipses, setEllipses] = useState(props.defaultAnnotions? props.defaultAnnotions.ellipses: []);
    const [stars, setStars] = useState(props.defaultAnnotions? props.defaultAnnotions.stars: []);
    const [ticks, setTicks] = useState(props.defaultAnnotions? props.defaultAnnotions.ticks: []);
    const [crosses, setCrosses] = useState(props.defaultAnnotions? props.defaultAnnotions.crosses: []);

    const [selectedId, selectShape] = useState(null);
    const [shapes, setShapes] = useState([]);
    const stageEl = React.createRef();
    const layerEl = React.createRef();
    const [cursor, setCursor] = useState('auto');

    const degToRad = Math.PI / 180
    const rotatePoint = ({x, y}, deg) => {
        const rcos = Math.cos(deg * degToRad), rsin = Math.sin(deg * degToRad)
        return {x: x*rcos - y*rsin, y: y*rcos + x*rsin}
    }

    const select = () => {
        addLine(stageEl.current.getStage(), layerEl.current, 'off');
        erase('off');
        setCursor('auto');
    };

    const erase = (status) => {
        addLine(stageEl.current.getStage(), layerEl.current, 'off');
        selectShape(null);
        setCursor(`url(${icon_eraser}) 10 15, auto`);
        const layer = layerEl.current;
        const nodes = layerEl.current.getChildren();

        if(status === 'on'){
            stageEl.current.getStage().on("mousedown touchstart", function(e) {
                const target = e.target;

                if(layer && layer.children[0]){
                    const imageId = layer.children[0]._id;
                    if(imageId === target._id){ return; }
                    const index = nodes.findIndex(node => node._id === target._id);
                    if(index > -1){ layer.children.splice(index, 1); }
                }

            });
        }
        else if(status === 'off'){
            stageEl.current.getStage().off("mousedown touchstart");
        }
    };

    const drawText = () => {
        const id = addText(stageEl.current.getStage(), layerEl.current, color);
        const shs = shapes.concat([id]);
        setShapes(shs);
        addLine(stageEl.current.getStage(), layerEl.current, 'off');
        erase('off');
        setCursor('auto');
    };

    const drawLine = () => {
        erase('off');
        addLine(stageEl.current.getStage(), layerEl.current, 'on', color);
        setCursor(`url(${icon_line}) 10 15, auto`);
    };

    const addEllipse = (scale) => {
        const ellipse = {
            id: `ellipse-${ellipses.length + 1}`,  
            x: scale[0] / 2, 
            y: scale[1] / 2, 
            width: 200,
            height: 100,
            rotation: 0,
            isDragging: false,
            stroke: color?color:'black', 
            strokeWidth: 5
        }
        const ellis = ellipses.concat([ellipse]);
        setEllipses(ellis);
        const shs = shapes.concat([`ellipse${ellipses.length + 1}`]);
        setShapes(shs);
        addLine(stageEl.current.getStage(), layerEl.current, 'off');
        erase('off');
        setCursor('auto');
    }

    const addStar = (scale) => {
        const st = {
            id: `star-${stars.length + 1}`, 
            x: scale[0] / 2, 
            y: scale[1] / 2, 
            scaleX: 1,
            scaleY: 1,
            innerRadius: 20, 
            outerRadius: 40,
            rotation: 0,
            isDragging: false,
            numPoints: 5, 
            fill: color?color:"#ffff00",
            opacity: 0.8, shadowColor: "black", shadowBlur: 10, shadowOpacity: 0.6
        }
        const sts = stars.concat([st]);
        setStars(sts);
        const shs = shapes.concat([`star${stars.length + 1}`]);
        setShapes(shs);
        addLine(stageEl.current.getStage(), layerEl.current, 'off');
        erase('off');
        setCursor('auto');
    }

    const addTick = (scale) => {
        const ti = {
            id: `tick-${ticks.length + 1}`,  
            x: scale[0] / 2, 
            y: scale[1] / 2, 
            scaleX: 1,
            scaleY: 1,
            width: 87 - 20,
            height: 70 - 15,
            rotation: 0,
            isDragging: false,
            stroke: color?color:"#00ff00",
            strokeWidth: 8
        }
        const tis = ticks.concat([ti]);
        setTicks(tis);
        const shs = shapes.concat([`tick${ticks.length + 1}`]);
        setShapes(shs);
        addLine(stageEl.current.getStage(), layerEl.current, 'off');
        erase('off');
        setCursor('auto');
    }

    const addCross = (scale) => {
        const cr = {
            id: `cross-${crosses.length + 1}`,  
            x: scale[0] / 2, 
            y: scale[1] / 2, 
            scaleX: 1,
            scaleY: 1,
            width: 40,
            height: 40,
            rotation: 0,
            isDragging: false,
            stroke: color?color:"#ff0000", 
            strokeWidth: 8
        }
        const crs = crosses.concat([cr]);
        setCrosses(crs);
        const shs = shapes.concat([`cross${crosses.length + 1}`]);
        setShapes(shs);
        addLine(stageEl.current.getStage(), layerEl.current, 'off');
        erase('off');
        setCursor('auto');
    }

    const save = () =>{
        const nodes = layerEl.current.getChildren();
        var tes = [], lis = [], ellis = [], sts = [], tis = [], crs = [];
        for(var i=0; i< nodes.length; i++){
            const node = nodes[i];
 
            const {draggable, isDragging, ...mainAttrs} = node.attrs;
            if(node.getClassName() === 'Text'){ 
                tes.push(mainAttrs); 
            }
            else if(node.getClassName() === 'Line'){ 
                lis.push(mainAttrs); 
            }
            else if(node.getClassName() === 'Ellipse'){ 
                ellis.push(mainAttrs); 
            }
            else if(node.getClassName() === 'Star'){ 
                sts.push(mainAttrs); 
            }
            else if(node.getClassName() === 'Shape'){
                const {sceneFunc, ...otherAttrs} = mainAttrs;
                if(node.attrs.id.includes('tick')){ tis.push(otherAttrs); }
                else if(node.attrs.id.includes('cross')){ crs.push(otherAttrs); }
            }
        }
        const data = {
            user: props.user,
            width: width,
            height: height,
            texts: tes,
            lines: lis,
            ellipses: ellis,
            stars: sts,
            ticks: tis,
            crosses: crs,
        }

        const index = findAnnotationByUser(props.card, props.user);
        if(index < 0 ){
            props.card['annotations'].push(data);
            props.card['edited'] = true;
        }
        else{
            props.card['annotations'].splice(index, 1, data);
            props.card['edited'] = true;
        }        
    }

    useEffect(() => {
        const itemClicked = props.selectedItem;
        if(!itemClicked){ return; }

        if(itemClicked.includes('cursor')){
            select();
        }
        else if(itemClicked.includes('eraser')){
            erase('on');
        }
        if(itemClicked.includes('text')){
            drawText();
        }
        else if(itemClicked.includes('line')){
            drawLine();
        }
        else if(itemClicked.includes('ellipse')){
            addEllipse([width, height]);
        }
        else if(itemClicked.includes('star')){
            addStar([width, height]);
        }
        else if(itemClicked.includes('tick')){
            addTick([width, height]);
        }
        else if(itemClicked.includes('cross')){
            addCross([width, height]);
        }
        else if(itemClicked.includes('save')){
            save();
        }
        // eslint-disable-next-line
    }, [props.selectedItem]);

    useEffect(() => {
        if(props.texts){
            setTexts(props.texts);
        }
        if(props.lines){
            setLines(props.lines);
        }
        if(props.ellipses){
            setEllipses(props.ellipses);
        }
        if(props.stars){
            setStars(props.stars);
        }
        if(props.ticks){
            setTicks(props.ticks);
        }
        if(props.crosses){
            setCrosses(props.crosses);
        }
        // eslint-disable-next-line
    },[props.texts, props.lines, props.ellipses, props.stars, props.ticks, props.crosses]);

    const checkDeselect = (e) => {
        if(layerEl.current && layerEl.current.children[0]){
            const clickedOnEmpty = e.target._id === layerEl.current.children[0]._id;
            if(clickedOnEmpty){
                selectShape(null);
            }
        }
    };

    const setImage = () => {
        var image = new Konva.Image({
            image: backgroundImage? backgroundImage: null,
            rotation: 0
        });
        
        const topLeft = {x:-image.width()/2, y:-image.height()/2};
        const current = rotatePoint(topLeft, image.rotation());
        const rotated = rotatePoint(topLeft, props.defaultImage?props.defaultImage.rotation: 0);
        const dx = rotated.x - current.x, dy = rotated.y - current.y

        return (
            <ReactKonva.Image
                image={backgroundImage}
                rotation={props.defaultImage?props.defaultImage.rotation: 0}
                x={image.x() + dx}
                y={image.y() + dy}
            />
        )
    }

    if(!backgroundImage){ return null; }

    return (
        <div style={{cursor: cursor, opacity: 1}}>
        <ReactKonva.Stage width={width} height={height} scaleX={scale} opacity={1} ref={stageEl} onMouseDown={checkDeselect} onTouchStart={checkDeselect}>
        <ReactKonva.Layer ref={layerEl}> 
            {setImage()}
            {texts.map((text, i) => {
                return (
                    <Text key={i} editMode={props.editMode} shapeProps={text} isSelected={text.id === selectedId}
                    onSelect={() => { selectShape(text.id) }} 
                    onChange={(newAttr) => {
                        const tes = texts.slice();
                        tes[i] = newAttr;
                        setTexts(tes);
                    }}/>
                )
            })}
            {lines.map((line, i) => {
                return (
                    <Line key={i} editMode={props.editMode} shapeProps={line} isSelected={line.id === selectedId}
                    onSelect={() => { selectShape(line.id) }} 
                    onChange={(newAttr) => {
                        const lis = lines.slice();
                        lis[i] = newAttr;
                        setLines(lis);
                    }}/>
                )
            })}
            {ellipses.map((ellipse, i) => {
                return (
                    <Ellipse key={i} editMode={props.editMode} shapeProps={ellipse} isSelected={ellipse.id === selectedId}
                    onSelect={() => { selectShape(ellipse.id) }} 
                    onChange={(newAttr) => {
                        const ellis = ellipses.slice();
                        ellis[i] = newAttr;
                        setEllipses(ellis);
                    }}/>
                )
            })}
            {stars.map((star, i) => {
                const extendProps = {
                    shadowOffsetX: star.isDragging ? 10 : 5, shadowOffsetY: star.isDragging ? 10 : 5,
                    //scaleX: star.isDragging ? star.scaleX + 1.2 : star.scaleX, scaleY: star.isDragging ? star.scaleY + 1.2 : star.scaleY
                };
                return (
                    <Star key={i} editMode={props.editMode} shapeProps={{...star, ...extendProps}} isSelected={star.id === selectedId}
                    onSelect={() => { selectShape(star.id) }} 
                    onChange={(newAttr) => {
                        const sts = stars.slice();
                        sts[i] = newAttr;
                        setStars(sts);
                    }}/>       
                )
            })}
            {ticks.map((tick, i) => {
                return (
                    <Tick key={i} editMode={props.editMode} shapeProps={tick} isSelected={tick.id === selectedId}
                    onSelect={() => { selectShape(tick.id) }} 
                    onChange={(newAttr) => {
                        const tis = ticks.slice();
                        tis[i] = newAttr;
                        setTicks(tis);
                    }}/>   
                )
            })}
            {crosses.map((corss, i) => {
                return (
                    <Cross key={i} editMode={props.editMode} shapeProps={corss} isSelected={corss.id === selectedId}
                    onSelect={() => { selectShape(corss.id) }} 
                    onChange={(newAttr) => {
                        const crs = crosses.slice();
                        crs[i] = newAttr;
                        setCrosses(crs);
                    }}/>       
                )
            })}
        </ReactKonva.Layer>
        </ReactKonva.Stage>
        </div>
    );
};

export default Canvas;