import React from 'react';
import UI from 'components/UI';

import Pages from './pages/Pages';
import Modal from './Modal';
import Recording from './Recording';

import background from 'resources/images/general/background.png';

class Main extends UI {

  componentDidMount(){
    this.init(this.props);
    //localStorage.clear();
    //this.db.clear();
    this.setState({ status: this.store.main.status, userType: this.store.user.type });
    if(this.props.qrCode){ 
      const origin = window.location.origin + '/#/';
      window.history.replaceState({}, null, origin);
      this.actions.user.loginById(this.props.match.params.id); 
    }
    else{ this.actions.main.setStatus('waitForLogin'); }
    //this.actions.main.setStatus('ready');
  }

  fetchaAppData(){
    const appId = this.store.user.mlanghkuId;
    const appPw = this.store.user.mlanghkuPw;
    //console.log(this.store.user);
    if(appId && appPw){ this.actions.mlanghku.fetchUser(appId, appPw); }
  }


  UNSAFE_componentWillReceiveProps(newProps){
    this.init(this.props);
    const previous = this.app.store.main.status;
    const next = newProps.app.store.main.status;
    //const lastView = this.app.store.content.view;
    //const newView = newProps.app.store.content.view;
    //const oldUser = this.props.app.store.user;
    //const newUser = newProps.app.store.user;
    //console.log(previous);
    //console.log(next);
    if((previous === 'waitForLogin' || previous === 'getNewAccount' || previous === 'getTrialAccount' ||  previous === 'init') && next === 'ready'){
      this.fetchaAppData();
      this.initView(this.app.store.user.type);
      this.switchView(newProps);
    }

    const oldType = this.app.store.user.type;
    const newType = newProps.app.store.user.type;

    if(oldType !== newType){
      this.app.actions.content.clearView();
      this.initView(newType);
      this.switchView(newProps);
    }

  }


  rememberLoginInfo(id, pw){
    //localStorage.setItem('loginInfo', JSON.stringify({id: id, pw: pw}));
    this.db.set('loginInfo',{id: id, pw: pw});
    this.db.set('language',this.store.main.language);
  }

  initView(type){
    const length = this.store.content.traces.length;
    for(var i=0;i<length;i++){
      this.actions.content.pullView();
    }

    const initView =
    type === 'student'? 'studentHome':
    type === 'teacher'? 'teacherHome':
    type === 'admin'? 'adminHome':
    type === 'developer'? 'devHome':
    '';
    this.actions.content.pushView(initView);

    const agreement = this.store.agreement;
    const name = this.store.profile.name;
    const id = this.store.user.id;

    if(!agreement || agreement.term === ''){ this.actions.content.pushView('forceAgreement');}
    else if(!name || name === ''){ this.actions.content.pushView('forceProfile'); }
    else if(!id || id === 'DefaultId'){ this.actions.content.pushView('forceAccount'); }
  }

  switchView(props){
    const page = props.page;
    const origin = window.location.origin;
    const path_1 = origin + '/#/';
    const user = this.store.user;
    switch(page){
      case 'course':
        const joinedCoursesId = this.store.courses.joinedCourses;
        const teachingCoursesId = this.store.courses.teachingCourses;
        const viewCourseId = props.match.params.courseId;
        if(user.type ==='student' && joinedCoursesId.length <= 0){ break; }
        if(user.type ==='teacher' && teachingCoursesId.length <= 0){ break; }

        if((user.type ==='student' && joinedCoursesId.indexOf(viewCourseId) >= 0)
        || (user.type === 'teacher' && teachingCoursesId.indexOf(viewCourseId) >= 0)){
        const course = this.func.getById.course(viewCourseId, this.store);
        this.actions.courses.viewCourse(course);
        this.actions.content.pushView('course');
        this.actions.content.pushRouteLink(path_1);
        }
        else{
          window.history.replaceState({}, null, origin);
        }
        break;
      case 'subject':
        const joinedSubjectsId = this.store.subjects.joinedSubjects;
        const teachingSubjectsId = this.store.subjects.teachingSubjects;
        const viewSubjectId = props.match.params.subjectId;
        if(user.type ==='student' && joinedSubjectsId.length <= 0){ break; }
        if(user.type ==='teacher' && teachingSubjectsId.length <= 0){ break; }

        if((user.type ==='student' && joinedSubjectsId.indexOf(viewSubjectId) >= 0)
        || (user.type === 'teacher' && teachingSubjectsId.indexOf(viewSubjectId) >= 0)){
          const subject = this.func.getById.subject(viewSubjectId, this.store);
          const course = this.func.getById.course(subject.course, this.store);
          const path_2 = path_1 + 'course/' + props.match.params.courseId;
          this.actions.courses.viewCourse(course);
          this.actions.content.pushView('course');
          this.actions.subjects.viewSubject(subject);
          this.actions.content.pushView('subject');
          this.actions.content.pushRouteLink(path_1);
          this.actions.content.pushRouteLink(path_2);
        }
        else{
          window.history.replaceState({}, null, origin);
        }
        break;
      case 'project':
        const joinedProjectsId = this.store.projects.joinedProjects;
        const teachingProjectsId = this.store.projects.teachingProjects;
        const viewProjectId = props.match.params.projectId;
        if(user.type ==='student' && joinedProjectsId.length <= 0){ break; }
        if(user.type ==='teacher' && teachingProjectsId.length <= 0){ break; }

        if((user.type ==='student' && joinedProjectsId.indexOf(viewProjectId) >= 0)
        || (user.type === 'teacher' && teachingProjectsId.indexOf(viewProjectId) >= 0)){
          const project = this.func.getById.project(viewProjectId, this.store);
          const subject = this.func.getById.subject(project.subject, this.store);
          const course = this.func.getById.course(subject.course, this.store);
          const path_2 = path_1 + 'course/' + props.match.params.courseId;
          const path_3 = path_2 + '/subject/' + props.match.params.subjectId;
          this.actions.courses.viewCourse(course);
          this.actions.content.pushView('course');
          this.actions.subjects.viewSubject(subject);
          this.actions.content.pushView('subject');
          this.actions.projects.viewProject(project);
          this.actions.content.pushView('project');
          this.actions.content.pushRouteLink(path_1);
          this.actions.content.pushRouteLink(path_2);
          this.actions.content.pushRouteLink(path_3);
        }
        else{
          window.history.replaceState({}, null, origin);
        }
        break;
      default:
        break;
    }
  }

  render() {
    this.init(this.props);

    const mainStyle = {
      width: this.ui.windowWidth * 0.9999,
      height: this.ui.windowHeight * 0.9999,
      minHeight: this.bs.height,
      backgroundImage: 'url(' + background + ')',
      backgroundSize: '10% 10%',
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center',

      boxSizing: 'border-box',
      overflow: 'hidden'
    }
    return (
      <div style={mainStyle}>
        <Pages app={this.app} router={this.props}/>
        <Recording app={this.app}/>
        <Modal app={this.app}/>
      </div>
    )
  }

}

export default Main;
