import React from 'react';
import Row from './Row';

class MemoryGameWaitingRow extends Row {

  constructor(props){
    super(props);
    this.init(props);
    this.state = {
      filename: this.props.profile? this.props.profile.icon: null,
      type: 'profileIcon',
      fontSize: this.bs.height * 0.025
    }
    this.checkUrl();
  }

  UNSAFE_componentWillReceiveProps(newProps){
    this.init(newProps);
    if(newProps.profile && !this.url.url){
      this.setState({
        filename: newProps.profile.icon,
        type: 'profileIcon'
      })
    }
    this.checkUrl();
  }

  rowInfo(){
    const rowStyle = {...this.ui.styles.area, ...{
      width: '100%',
      height: this.bs.height * 0.06,
      alignItems: 'center'
    }}

    return(
      <div style={rowStyle}>
      </div>
    )
  }


  render(){
    const profile = this.props.profile;
    if(profile === null){ return null; }
    if(profile.name === ''){ return null; }
    return this.animatedRow(this.content.bind(this), this.bs.height * 0.15)
  }

  content = (style)=>{
    const language = this.store.main.language;
    const name = this.props.profile? 
    language === 'english' && this.props.profile.lastNameEng && this.props.profile.firstNameEng? this.props.profile.lastNameEng + ' ' + this.props.profile.firstNameEng + ' (' + this.props.profile.name + ') ':
    (language === 'chinese' || language === 'simplified_chinese') && this.props.profile.lastNameChi && this.props.profile.firstNameChi? this.props.profile.lastNameChi + ' ' + this.props.profile.firstNameChi + ' (' + this.props.profile.name + ') ':
    this.props.profile.name:
    '';

    return(
      <button key={this.url.url} onClick={this.props.onClick} style={{...this.rowStyle(), ...{
        height: style.height,
        opacity: style.opacity
      }}}>
        {this.verGap('3%')}
        {this.rowIcon()}
        {this.rowContent(name, this.rowInfo.bind(this) )}
      </button>
    )
  }
}

export default MemoryGameWaitingRow;
