import React from 'react';
import View from 'components/main/pages/home/views/View';

import SubNav from 'components/main/items/SubNav';
import CourseDetail from './subviews/CourseDetail';
import CourseStudents from './subviews/CourseStudents';
//import CourseProjects from './subviews/CourseProjects';
import CourseSubjects from './subviews/CourseSubjects';
import CourseStatistics from './subviews/CourseStatistics';
import CourseManagement from './subviews/CourseManagement';

class Course extends View {

  componentDidMount(){
    if(this.store.content.subView.includes('student')){
      this.actions.content.setSubView('courseStudents');
    }else if(this.store.content.subView.includes('subject')){
      this.actions.content.setSubView('courseSubjects');
    }else if(!this.store.content.subView.includes('course')){
      this.actions.content.setSubView('courseSubjects');
    }
  }

  sortStudents(){
    var students = this.store.courses.viewingCourse.joinedStudents;
    var data = [];
    if(students.length === 0){ return null; }
    for(var i=0; i<students.length; i++){
      const profile = this.func.getById.profileByUser(students[i], this.store);
      if(profile){ data.push(profile); }
    }
    
    return data.slice(0).sort((a, b) => {
      if(a.lastNameEng && b.lastNameEng){
        return a.lastNameEng.localeCompare(b.lastNameEng);
      }
      if(a.lastNameEng && !b.lastNameEng){
        return a.lastNameEng.localeCompare(b.name);
      } 
      if(!a.lastNameEng && b.lastNameEng){
        return a.name.localeCompare(b.lastNameEng);
      }
      if(a.name && b.name)
        return a.name.localeCompare(b.name);
      else
        return true;
    }); 
  }

  subView(subView, animatedStyle){
    const app = {...this.app, ...{ animatedStyle: animatedStyle}}
    //console.log(this.store.content.statistics[this.store.courses.viewingCourse._id]);
    const sortedStudentList = this.sortStudents();
    switch (subView) {
      case 'courseDetail':
        return <CourseDetail app={app}/>
      case 'courseStudents':
        return <CourseStudents app={app} sortedStudentList={sortedStudentList}/>
      case 'courseSubjects':
        return <CourseSubjects app={app}/>
      case 'courseStatistics':
        return <CourseStatistics app={app}/>
      case 'courseManagement':
        return <CourseManagement app={app}/>
      default:
        return null;
    }
  }

  courseSubNav(){
    var options = [
      {
        tag:['Unit','單元','单元'],
        subView: 'courseSubjects'
      },
      {
        tag:['Student','學生','学生'],
        subView: 'courseStudents'
      },
      {
        tag:['Detail','詳細資訊','详细资讯'],
        subView: 'courseDetail'
      },
      {
        tag:['Statistic','統計','统计'],
        subView: 'courseStatistics'
      },
    ]
    if(this.store.courses.viewingCourse.mlanghku){
      options.splice(1,1);
    }
    if(!this.inSchool && !this.store.projects.viewingProject.mlanghku && this.store.user.type === 'teacher'){
      options.splice(options.length,0,
      {
        tag:['Management','管理','管理'],
        subView: 'courseManagement',
      });
    }
    return <SubNav app={this.app} options={options} />
  }

  render(){
    this.init(this.props);
    const course = this.store.courses.viewingCourse;

    const deadView = this.state.deadView;
    const view = this.state.view;

    return(
      <div style={this.viewStyle()}>
        {this.tabBar([course.title,course.title,course.title])}
        {this.courseSubNav()}
        {this.sep()}
        {this.animatedSubView(this.subView.bind(this), deadView? deadView: view, deadView? false: true)}
      </div>
    )
  }
}

export default Course;
